var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox", staticStyle: { "padding-top": "0" } },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _vm.checkPermi(["admin:statistics:product:ranking"])
            ? _c("div", { staticClass: "flex justify-between mb20" }, [
                _c("div", { staticClass: "header_title" }, [
                  _vm._v("商品排行"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "acea-row" },
                  [
                    _c(
                      "div",
                      { staticClass: "mr-20" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "mr-20",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          },
                          _vm._l(_vm.options, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          staticStyle: { width: "220px" },
                          attrs: {
                            size: "small",
                            type: "daterange",
                            placement: "bottom-end",
                            "picker-options": _vm.pickerOptions,
                            "value-format": "yyyy-MM-dd",
                            format: "yyyy-MM-dd",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            align: "right",
                          },
                          on: { change: _vm.onchangeTime },
                          model: {
                            value: _vm.timeVal,
                            callback: function ($$v) {
                              _vm.timeVal = $$v
                            },
                            expression: "timeVal",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.search },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.LogLoading,
                  expression: "LogLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.list,
                stripe: "",
                "default-sort": { prop: "pageviews", order: "descending" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "商品图", width: "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c("el-image", {
                              staticStyle: { width: "40px", height: "40px" },
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "商品名称",
                  prop: "proName",
                  "min-width": "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "text_overflow" }, [
                          _vm._v(_vm._s(scope.row.proName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm._l(_vm.columns1, function (item, key) {
                return _c("el-table-column", {
                  key: key,
                  attrs: {
                    label: item.title,
                    prop: item.key,
                    sortable: item.sortable,
                    align: "center",
                    width: "150",
                  },
                })
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-center" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  "current-page": _vm.currentPage,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeLog,
                  "current-change": _vm.pageChangeLog,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }