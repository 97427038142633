"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _statistic = require("@/api/statistic");
var _index = _interopRequireDefault(require("@/components/cards/index"));
var _ZBParser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/ZBParser"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'AccountsBill',
  components: {
    cardsData: _index.default,
    zbParser: _ZBParser.default
  },
  data: function data() {
    return {
      editData: {},
      isCreate: 1,
      cardLists: [],
      timeVal: [],
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        dateLimit: '',
        phone: '',
        page: 1,
        limit: 20
      },
      fromList: this.$constants.fromList,
      dialogVisible: false
    };
  },
  mounted: function mounted() {
    // this.getList()
    // this.getStatistics()
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.dateLimit = tab;
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      // this.listLoading = true
      // this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _statistic.getTasklistApi)(this.tableFrom).then(function (res) {
        var stat = res;
        _this.tableData.data = stat.page.records;
        _this.tableData.total = stat.page.total;
        _this.listLoading = false;
        _this.cardLists = [{
          name: '直推实名任务',
          count: stat.smCount,
          color: '#1890FF',
          class: 'one',
          icon: 'iconchongzhijine'
        }, {
          name: '完成任务人数',
          count: stat.wcrwCount,
          color: '#A277FF',
          class: 'two',
          icon: 'iconweixinzhifujine'
        }, {
          name: '任务完成率',
          count: stat.ratio,
          color: '#EF9C20',
          class: 'three',
          icon: 'iconyuezhifujine1'
        }];
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    } // 统计
    // getStatistics() {
    //   getTasklistApi().then(res => {
    //     const stat = res
    //     this.cardLists = [
    //       { name: '直推实名任务', count: stat.total, color:'#1890FF',class:'one',icon:'iconchongzhijine' },
    //       { name: '完成任务人数', count: stat.routine, color:'#A277FF',class:'two',icon:'iconweixinzhifujine' },
    //       { name: '任务完成率', count: stat.weChat, color:'#EF9C20',class:'three',icon:'iconyuezhifujine1' }
    //     ]
    //   })
    // }
  }
};