var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "任务包管理",
            visible: _vm.dialogVisible,
            width: "660px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "taskPackage",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.taskPackage,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入标题名称" },
                    model: {
                      value: _vm.taskPackage.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.taskPackage, "title", $$v)
                      },
                      expression: "taskPackage.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { align: "middle" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "出售金额", prop: "price" } },
                        [
                          _c("el-input-number", {
                            staticClass: "priceBox",
                            attrs: {
                              size: "small",
                              min: 0,
                              precision: 2,
                              step: 0.1,
                            },
                            model: {
                              value: _vm.taskPackage.price,
                              callback: function ($$v) {
                                _vm.$set(_vm.taskPackage, "price", $$v)
                              },
                              expression: "taskPackage.price",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "日产量", prop: "dayYield" } },
                        [
                          _c("el-input-number", {
                            staticClass: "priceBox",
                            attrs: {
                              size: "small",
                              min: 0,
                              precision: 2,
                              step: 0.1,
                            },
                            model: {
                              value: _vm.taskPackage.dayYield,
                              callback: function ($$v) {
                                _vm.$set(_vm.taskPackage, "dayYield", $$v)
                              },
                              expression: "taskPackage.dayYield",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "有效周期", prop: "activeDays" } },
                [
                  _c("el-input", {
                    attrs: {
                      minlength: "1",
                      maxlength: "5",
                      placeholder: "请输入有效周期",
                    },
                    model: {
                      value: _vm.taskPackage.activeDays,
                      callback: function ($$v) {
                        _vm.$set(_vm.taskPackage, "activeDays", _vm._n($$v))
                      },
                      expression: "taskPackage.activeDays",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { align: "middle" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "总收益", prop: "totalYield" } },
                        [
                          _c("el-input-number", {
                            staticClass: "priceBox",
                            attrs: {
                              size: "small",
                              min: 0,
                              precision: 2,
                              step: 0.1,
                            },
                            model: {
                              value: _vm.taskPackage.totalYield,
                              callback: function ($$v) {
                                _vm.$set(_vm.taskPackage, "totalYield", $$v)
                              },
                              expression: "taskPackage.totalYield",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "活跃度", prop: "activeness" } },
                        [
                          _c("el-input-number", {
                            staticClass: "priceBox",
                            attrs: {
                              size: "small",
                              min: 0,
                              precision: 2,
                              step: 0.1,
                            },
                            model: {
                              value: _vm.taskPackage.activeness,
                              callback: function ($$v) {
                                _vm.$set(_vm.taskPackage, "activeness", $$v)
                              },
                              expression: "taskPackage.activeness",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "最大持有量", prop: "maxCount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入最大持有量" },
                    model: {
                      value: _vm.taskPackage.maxCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.taskPackage, "maxCount", _vm._n($$v))
                      },
                      expression: "taskPackage.maxCount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { align: "middle" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "递增次数", prop: "incrementCount" },
                        },
                        [
                          _c("el-input-number", {
                            staticClass: "priceBox",
                            attrs: {
                              size: "small",
                              min: 0,
                              precision: 0,
                              step: 1,
                            },
                            model: {
                              value: _vm.taskPackage.incrementCount,
                              callback: function ($$v) {
                                _vm.$set(_vm.taskPackage, "incrementCount", $$v)
                              },
                              expression: "taskPackage.incrementCount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "递增步长", prop: "incrementStep" } },
                        [
                          _c("el-input-number", {
                            staticClass: "priceBox",
                            attrs: {
                              size: "small",
                              min: 0,
                              precision: 0,
                              step: 1,
                            },
                            model: {
                              value: _vm.taskPackage.incrementStep,
                              callback: function ($$v) {
                                _vm.$set(_vm.taskPackage, "incrementStep", $$v)
                              },
                              expression: "taskPackage.incrementStep",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { align: "middle" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "收益次数", prop: "yieldQuantity" } },
                        [
                          _c("el-input-number", {
                            staticClass: "priceBox",
                            attrs: {
                              size: "small",
                              min: 0,
                              precision: 2,
                              step: 0.1,
                            },
                            model: {
                              value: _vm.taskPackage.yieldQuantity,
                              callback: function ($$v) {
                                _vm.$set(_vm.taskPackage, "yieldQuantity", $$v)
                              },
                              expression: "taskPackage.yieldQuantity",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "priceBox",
                              attrs: { placeholder: "状态" },
                              model: {
                                value: _vm.taskPackage.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.taskPackage, "status", $$v)
                                },
                                expression: "taskPackage.status",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "正常", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "禁用", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "删除", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { align: "middle" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "封面图片", prop: "image" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1", "image")
                                },
                              },
                            },
                            [
                              _vm.taskPackage.image
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: { src: _vm.taskPackage.image },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否计库存" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "selWidth",
                              attrs: { type: "button" },
                              model: {
                                value: _vm.taskPackage.manageStock,
                                callback: function ($$v) {
                                  _vm.$set(_vm.taskPackage, "manageStock", $$v)
                                },
                                expression: "taskPackage.manageStock",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: false } },
                                [_c("span", [_vm._v("否")])]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: true } },
                                [_c("span", [_vm._v("是")])]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.taskPackage.manageStock
                ? _c(
                    "el-form-item",
                    { attrs: { label: "库存数量", prop: "stock" } },
                    [
                      _c("el-input", {
                        attrs: {
                          minlength: "1",
                          maxlength: "5",
                          placeholder: "请输入库存数量",
                        },
                        model: {
                          value: _vm.taskPackage.stock,
                          callback: function ($$v) {
                            _vm.$set(_vm.taskPackage, "stock", _vm._n($$v))
                          },
                          expression: "taskPackage.stock",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("taskPackage")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [
                        "admin:task:pakageManager:update",
                        "admin:task:pakageManager:save",
                      ],
                      expression:
                        "['admin:task:pakageManager:update','admin:task:pakageManager:save']",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formValidate")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }