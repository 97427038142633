var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["admin:user:task:pakageManager:save"],
                      expression: "['admin:user:task:pakageManager:save']",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onAdd(null)
                    },
                  },
                },
                [_vm._v("添加任务包")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { inline: "", size: "small" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "关键词：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: {
                        placeholder: "请输入关键词",
                        size: "small",
                        clearable: "",
                      },
                      model: {
                        value: _vm.tableFrom.keywords,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "keywords", $$v)
                        },
                        expression: "tableFrom.keywords",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: { click: _vm.handerSearch },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "任务包编号", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "title", label: "标题", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "price", label: "出售金额", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "dayYield", label: "日产量", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "activeDays",
                  label: "有效周期",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "totalYield",
                  label: "总收益",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "activeness",
                  label: "活跃度",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "maxCount",
                  label: "最大持有量",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "yieldQuantity",
                  label: "收益次数",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("taskPackageManagerTypeFilter")(row.status)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "120",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["admin:task:pakageManager:upgrade"],
                                expression:
                                  "['admin:task:pakageManager:upgrade']",
                              },
                            ],
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.upgrade(scope.row)
                              },
                            },
                          },
                          [_vm._v("设置升级条件")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["admin:task:pakageManager:update"],
                                expression:
                                  "['admin:task:pakageManager:update']",
                              },
                            ],
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["admin:task:pakageManager:delete"],
                                expression:
                                  "['admin:task:pakageManager:delete']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("create-task", { ref: "task", attrs: { taskPackage: _vm.taskInfo } }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置升级条件",
            visible: _vm.upgradeCondVisible,
            width: "600px",
            "before-close": _vm.handleCloseUpgradeCond,
          },
          on: {
            "update:visible": function ($event) {
              _vm.upgradeCondVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "upgradeCondForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.upgradeCondForm, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机号" },
                    model: {
                      value: _vm.upgradeCondForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.upgradeCondForm, "phone", $$v)
                      },
                      expression: "upgradeCondForm.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "团队活跃度",
                    rules: [
                      {
                        required: true,
                        message: "请输入团队活跃度",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: { placeholder: "请输入团队活跃度", clearable: "" },
                    model: {
                      value: _vm.upgradeCondForm.teamActiveness,
                      callback: function ($$v) {
                        _vm.$set(_vm.upgradeCondForm, "teamActiveness", $$v)
                      },
                      expression: "upgradeCondForm.teamActiveness",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "小区活跃度",
                    rules: [
                      {
                        required: true,
                        message: "请输入小区活跃度",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: { placeholder: "请输入小区活跃度", clearable: "" },
                    model: {
                      value: _vm.upgradeCondForm.littleTeamActiveness,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.upgradeCondForm,
                          "littleTeamActiveness",
                          $$v
                        )
                      },
                      expression: "upgradeCondForm.littleTeamActiveness",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "直推认证人数",
                    rules: [
                      {
                        required: true,
                        message: "请输入直推认证人数",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: { placeholder: "请输入直推认证人数", clearable: "" },
                    model: {
                      value: _vm.upgradeCondForm.certifyMembers,
                      callback: function ($$v) {
                        _vm.$set(_vm.upgradeCondForm, "certifyMembers", $$v)
                      },
                      expression: "upgradeCondForm.certifyMembers",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCloseUpgradeCond } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitUpgradeCondForm("upgradeCondForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }