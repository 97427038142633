var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "divBox" }, [
    _vm.item && _vm.item.uid
      ? _c(
          "div",
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData.data,
                  "max-height": "400",
                  size: "small",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    "min-width": "50",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "uid",
                    label: "用户ID",
                    "min-width": "80",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "realName",
                    label: "用户姓名",
                    "min-width": "120",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "inviterNickname",
                    label: "推荐人",
                    "min-width": "120",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "inviterPhone",
                    label: "手机号",
                    "min-width": "120",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "integral",
                    label: "获得收益",
                    "min-width": "100",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "createTime",
                    label: "交易时间",
                    "min-width": "120",
                    align: "center",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }