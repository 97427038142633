var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-style" }, [
    _c(
      "div",
      { staticClass: "center-bg-style" },
      [
        _c("div", { staticClass: "title-style" }, [
          _vm._v("\n      7月服务器状态\n    "),
        ]),
        _vm._v(" "),
        _vm._l(_vm.list, function (item, index) {
          return _c("div", { staticClass: "content-style" }, [
            _c(
              "div",
              { staticStyle: { height: "100%", width: "100%", float: "left" } },
              [
                _c("div", { staticClass: "content-left-style" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "content-right-style" }, [
                  _vm._v(_vm._s(item.value)),
                ]),
              ]
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }