"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _hotelment = require("@/api/hotelment");
var _auth = require("@/utils/auth");
var _permission = require("@/utils/permission");
var _querystring = require("querystring");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: "ProductList",
  data: function data() {
    return {
      props: {
        children: "child",
        label: "name",
        value: "id",
        emitPath: false
      },
      // roterPre: roterPre,
      headeNum: [],
      tasklist: [],
      listLoading: true,
      title: false,
      tableData: {
        data: [],
        //列表
        total: 0
      },
      // 新增编辑参数
      user: {
        id: "",
        phone: "",
        teamActiveness: "",
        littleTeamActiveness: "",
        certifyMembers: "",
        status: "",
        startLevelId: ""
      },
      // 搜索
      tableFrom: {
        page: 1,
        limit: 20,
        // uid: Cookies.get('isLogin'),
        name: "",
        phone: ""
      },
      // 效验
      rules: {
        startLevelId: [{
          required: true,
          message: "请輸入星级名称",
          trigger: "change"
        }],
        phone: [{
          required: true,
          message: "请输入会员手机",
          trigger: "change"
        }],
        teamActiveness: [{
          required: true,
          message: "请输入团队活跃度",
          trigger: "change"
        }],
        littleTeamActiveness: [{
          required: true,
          message: "请输入小区活跃度",
          trigger: "change"
        }],
        certifyMembers: [{
          required: true,
          message: "请输入直推认证人",
          trigger: "change"
        }],
        status: [{
          required: true,
          message: "请选择状态",
          trigger: "change"
        }]
      },
      loading: false,
      categoryList: [],
      merCateList: [],
      objectUrl: process.env.VUE_APP_BASE_API,
      dialogVisible: false,
      card_select_show: false,
      checkAll: false,
      isIndeterminate: true
    };
  },
  mounted: function mounted() {
    this.getList();
    this.tasklistadm();
  },
  methods: {
    // 搜索
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    // 星级列表
    tasklistadm: function tasklistadm() {
      var _this = this;
      (0, _hotelment.productLstApi)({
        page: 1,
        limit: 9999
      }).then(function (res) {
        console.log(res);
        _this.tasklist = [];
        var tasklist = res.list.map(function (item) {
          return {
            label: item.name,
            id: item.id
          };
        });
        _this.tasklist = tasklist;
      });
    },
    // 新增
    add: function add() {
      this.dialogVisible = true;
      this.title = true;
      this.user.teamActiveness = 0;
      this.user.littleTeamActiveness = 0;
      this.user.certifyMembers = 0;
      this.user.status = "0";
      this.user.id = "";
      this.user.phone = "";
      this.user.startLevelId = "";
    },
    // 编辑
    edit: function edit(row) {
      this.user.teamActiveness = row.teamActiveness;
      this.user.littleTeamActiveness = row.littleTeamActiveness;
      this.user.certifyMembers = row.certifyMembers;
      this.user.id = row.id;
      this.user.phone = row.phone;
      this.user.startLevelId = row.starLevelId;
      this.user.status = row.status == true ? "0" : "1";
      this.dialogVisible = true;
      this.title = false;
    },
    //新增编辑确认
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          if (!_this2.user.id) {
            (0, _hotelment.userspecialaddApi)(_this2.user).then(function (res) {
              _this2.$message.success("新增成功");
              _this2.loading = false;
              _this2.dialogVisible = false;
              _this2.getList();
            }).catch(function () {
              _this2.loading = false;
            });
          } else {
            (0, _hotelment.userspecialeditApi)(_this2.user).then(function (res) {
              _this2.$message.success("编辑成功");
              _this2.loading = false;
              _this2.dialogVisible = false;
              _this2.getList();
            }).catch(function () {
              _this2.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    // 列表
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _hotelment.userspecialApi)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.listLoading = false;
      }).catch(function (res) {
        _this3.listLoading = false;
        _this3.$message.error(res.message);
      });
    },
    // 分页
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    // 分页
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(row, type) {
      var _this4 = this;
      var h = this.$createElement;
      this.$modalSure("要删除 手机号为：" + row.phone + "，星级等级为：" + row.starLevelTitle + " 的数据吗?").then(function () {
        // const deleteFlag = type == 5 ? "delete" : "recycle";
        (0, _hotelment.userspecialDeleteApi)({
          id: row.id
        }).then(function () {
          _this4.$message.success("删除成功");
          _this4.getList();
        });
      });
    },
    // 取消
    resetForm: function resetForm(user) {
      this.dialogVisible = false;
      // this[user] = {};
      this.getList();
    }
  }
};