var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "用户等级",
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "user",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.user,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "等级名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入等级名称" },
                    model: {
                      value: _vm.user.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "name", $$v)
                      },
                      expression: "user.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "等级", prop: "grade" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入等级" },
                    model: {
                      value: _vm.user.grade,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "grade", _vm._n($$v))
                      },
                      expression: "user.grade",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "享受折扣(%)", prop: "discount" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      min: 0,
                      max: 100,
                      "step-strictly": "",
                      placeholder: "请输入享受折扣",
                    },
                    model: {
                      value: _vm.user.discount,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "discount", $$v)
                      },
                      expression: "user.discount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "升级顾客数", prop: "experience" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      placeholder: "请输入升级顾客数",
                      min: 0,
                      "step-strictly": "",
                    },
                    model: {
                      value: _vm.user.experience,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "experience", _vm._n($$v))
                      },
                      expression: "user.experience",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "图标", prop: "icon" } }, [
                _c(
                  "div",
                  {
                    staticClass: "upLoadPicBox",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("1", "icon")
                      },
                    },
                  },
                  [
                    _vm.user.icon
                      ? _c("div", { staticClass: "pictrue" }, [
                          _c("img", { attrs: { src: _vm.user.icon } }),
                        ])
                      : _vm.formValidate.icon
                      ? _c("div", { staticClass: "pictrue" }, [
                          _c("img", { attrs: { src: _vm.formValidate.icon } }),
                        ])
                      : _c("div", { staticClass: "upLoad" }, [
                          _c("i", {
                            staticClass: "el-icon-camera cameraIconfont",
                          }),
                        ]),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("user")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [
                        "admin:system:user:level:update",
                        "admin:system:user:level:save",
                      ],
                      expression:
                        "['admin:system:user:level:update','admin:system:user:level:save']",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formValidate")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }