"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _index = _interopRequireDefault(require("@/components/cards/index"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: "groupList",
  components: {
    cardsData: _index.default
  },
  data: function data() {
    return {
      listLoadingPink: false,
      dialogVisible: false,
      tableDataPink: {
        data: []
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        dateLimit: '',
        status: '',
        page: 1,
        limit: 20
      },
      fromList: this.$constants.fromList,
      timeVal: [],
      cardLists: []
    };
  },
  mounted: function mounted() {
    this.getStatistics();
    this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    handleLook: function handleLook(id) {
      this.dialogVisible = true;
      this.getPink(id);
    },
    getPink: function getPink(id) {
      var _this = this;
      this.listLoadingPink = true;
      (0, _marketing.combineOrderPinkApi)(id).then(function (res) {
        _this.tableDataPink.data = res;
        _this.listLoadingPink = false;
      }).catch(function () {
        _this.listLoadingPink = false;
      });
    },
    selectChange: function selectChange(tab) {
      this.tableFrom.dateLimit = tab;
      this.tableFrom.page = 1;
      this.timeVal = [];
      this.getList();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _marketing.combineListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 统计
    getStatistics: function getStatistics() {
      var _this3 = this;
      (0, _marketing.combineStatisticsApi)().then(function (res) {
        _this3.cardLists = [{
          name: '参与人数(人)',
          count: res.countPeople,
          color: '#1890FF',
          class: 'one',
          icon: 'iconleijiyonghushu'
        }, {
          name: '成团数量(个)',
          count: res.countTeam,
          color: '#A277FF',
          class: 'two',
          icon: 'iconxinzengyonghu'
        }];
      }).catch(function () {
        _this3.listLoading = false;
      });
    }
  }
};