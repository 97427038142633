var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _vm.userInfo && _vm.userInfo.uid
        ? _c("el-card", { staticClass: "box-card" }, [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("div", { staticClass: "user-info" }, [
                  _c("img", {
                    staticClass: "img",
                    attrs: { src: _vm.userInfo.avatar, onerror: _vm.imgError },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _c("p", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.userInfo.nickname)),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm._f("userBusTypeTypeFilter")(_vm.userInfo.busType)
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "container" },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      inline: "",
                      size: "small",
                      "label-width": "150px",
                      "label-position": "left",
                    },
                  },
                  [
                    _c("el-form-item", { attrs: { label: "昵称" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.userInfo.nickname) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "用户类型" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("userBusTypeTypeFilter")(
                              _vm.userInfo.busType
                            )
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "用户等级" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.userInfo.levelName || "-") +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "推荐人" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.userInfo.inviterPhone) +
                          " " +
                          _vm._s(_vm.userInfo.inviterName) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "手机号" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.userInfo.phone) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "余额" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.userInfo.integral) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-divider"),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }