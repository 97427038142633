var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _vm.checkPermi(["admin:task:vipConfig:validVip"])
                ? _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.onClickTab },
                      model: {
                        value: _vm.vipStatusConfig,
                        callback: function ($$v) {
                          _vm.vipStatusConfig = $$v
                        },
                        expression: "vipStatusConfig",
                      },
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: { label: "VIP 用户", name: "0" },
                      }),
                      _vm._v(" "),
                      _c("el-tab-pane", {
                        attrs: { label: "已过期 VIP", name: "1" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: "", size: "small" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名/手机号：" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "请输入姓名,手机号",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.tableForm.keywords,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableForm, "keywords", $$v)
                              },
                              expression: "tableForm.keywords",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户类型：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "用户类型" },
                              model: {
                                value: _vm.tableForm.busType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "busType", $$v)
                                },
                                expression: "tableForm.busType",
                              },
                            },
                            _vm._l(
                              _vm.commonEnums.userBusTypeEnums,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "到期时间：" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "到期时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.getList },
                            },
                            [_vm._v("查询")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.reset()
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "clearfix" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["admin:task:vipConfig:list"],
                          expression: "['admin:task:vipConfig:list']",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onAdd(null)
                        },
                      },
                    },
                    [_vm._v("VIP 配置\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "realName", label: "姓名", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户类型", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("userBusTypeTypeFilter")(row.busType))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "level", label: "用户等级", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterEmpty")(
                                _vm._f("levelFilter")(scope.row.level)
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "phone",
                  label: "用户手机号",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "expireAt",
                  label: "到期时间",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "lastPayTime",
                  label: "最近开通时间",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "50",
                  fixed: "right",
                  align: "center",
                  "render-header": _vm.renderHeader,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-dropdown",
                          { attrs: { trigger: "click" } },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v("\n              更多"),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _vm.checkPermi([
                                  "admin:task:vipConfig:changeExpireDate",
                                ])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.editData(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("修改到期日")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi([
                                  "admin:task:vipConfig:openDetails",
                                ])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onDetails(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("开通记录")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableForm.limit,
                  "current-page": _vm.tableForm.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "taskEditDataModal",
          attrs: {
            title: "修改到期日",
            visible: _vm.dialogEditDataVisible,
            "close-on-click-modal": false,
            width: "600px",
            "before-close": _vm.handleEditDataClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogEditDataVisible = $event
            },
          },
        },
        [
          _vm.dialogEditDataVisible
            ? _c("EditData", {
                attrs: { datas: _vm.itemData },
                on: { handleCloseMod: _vm.handleEditDataCloseMod },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "taskEditDataModal",
          attrs: {
            title: "开通记录",
            visible: _vm.dialogOpenDetailsVisible,
            "close-on-click-modal": false,
            width: "1200px",
            "before-close": _vm.handleOpenDetailsClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogOpenDetailsVisible = $event
            },
          },
        },
        [
          _vm.dialogOpenDetailsVisible
            ? _c("OpenDetailsList", {
                attrs: { datas: _vm.itemData },
                on: { handleCloseMod: _vm.handleOpenDetailsCloseMod },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "taskEditDataModal",
          attrs: {
            title: "VIP配置",
            visible: _vm.dialogVipConfigVisible,
            "close-on-click-modal": false,
            width: "1200px",
            "before-close": _vm.handleVipConfigClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVipConfigVisible = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("span", { staticClass: "vip-title-style" }, [_vm._v("VIP配置")]),
            _vm._v(" (价格和余额，在购买时二选一)\n    "),
          ]),
          _vm._v(" "),
          _vm.dialogVipConfigVisible
            ? _c("VipConfig", {
                attrs: { datas: _vm.itemData },
                on: { handleCloseMod: _vm.handleVipConfigCloseMod },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }