"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _hotelment = require("@/api/hotelment");
var _auth = require("@/utils/auth");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//接口
// 权限判断函数
var _default = exports.default = {
  name: "ProductList",
  data: function data() {
    return {
      props: {
        children: "child",
        label: "name",
        value: "id",
        emitPath: false
      },
      listLoading: true,
      title: false,
      tasklist: [],
      tableData: {
        data: [],
        //列表
        total: 0
      },
      // 新增编辑参数
      user: {
        name: "",
        littleTeamActiveness: "",
        activeMembers: "",
        activeSpreader: "",
        bigTeamActiveness: "",
        taskPackageTitle: "",
        levelId: "",
        count: "",
        fee: "",
        status: ""
      },
      tableFrom: {
        page: 1,
        limit: 20,
        keywords: ""
      },
      // 效验
      rules: {
        name: [{
          required: true,
          message: "请输入星级名称",
          trigger: "change"
        }],
        taskPackageTitle: [{
          required: true,
          message: "请选择任务包",
          trigger: "change"
        }],
        fee: [{
          required: true,
          message: "请输入手续费",
          trigger: "change"
        }],
        littleTeamActiveness: [{
          required: true,
          message: "请输入小区活跃度",
          trigger: "change"
        }],
        bigTeamActiveness: [{
          required: true,
          message: "请输入大区活跃度",
          trigger: "change"
        }],
        activeMembers: [{
          required: true,
          message: "请输入直推人数",
          trigger: "change"
        }],
        activeSpreader: [{
          required: true,
          message: "请输入活跃推广人数",
          trigger: "change"
        }],
        status: [{
          required: true,
          message: "请选择状态",
          trigger: "change"
        }],
        count: [{
          required: true,
          message: "请输入赠送数量",
          trigger: "change"
        }]
      },
      loading: false,
      dialogVisible: false
    };
  },
  mounted: function mounted() {
    _permission.checkPermi, this.getList();
    this.tasklistadm();
  },
  methods: {
    // 任务包列表
    tasklistadm: function tasklistadm() {
      var _this = this;
      (0, _hotelment.taskPakageListApi)({
        page: 1,
        limit: 9999
      }).then(function (res) {
        _this.tasklist = res.list;
      });
    },
    // 搜索
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    // 状态
    Formatter: function Formatter(row, column) {
      if (row.status == 1) {
        return "开启";
      } else if (row.status == 2) {
        return "关闭";
      }
    },
    // 新增
    add: function add() {
      this.dialogVisible = true;
      this.title = true;
      this.user.name = "";
      this.user.littleTeamActiveness = "0";
      this.user.activeMembers = "0";
      this.user.activeSpreader = "0";
      this.user.bigTeamActiveness = "0";
      this.user.taskPackageTitle = "";
      this.user.levelId = "";
      this.user.count = "0";
      this.user.fee = "0";
      this.user.status = "1";
    },
    // 编辑
    edit: function edit(row) {
      this.user = row;
      this.user.levelId = row.id;
      this.user.status = row.status.toString();
      this.dialogVisible = true;
      this.title = false;
    },
    // 新增编辑
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          console.log(_this2.user);
          if (!_this2.user.levelId) {
            (0, _hotelment.productCreateApi)(_this2.user).then(function (res) {
              _this2.$message.success("新增成功");
              _this2.loading = false;
              _this2.dialogVisible = false;
              _this2.getList();
            }).catch(function () {
              _this2.loading = false;
            });
          } else {
            (0, _hotelment.productUpdateApi)(_this2.user).then(function (res) {
              _this2.$message.success("编辑成功");
              _this2.getList();
              _this2.loading = false;
              _this2.dialogVisible = false;
            }).catch(function () {
              _this2.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    // 列表
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _hotelment.productLstApi)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.listLoading = false;
      }).catch(function (res) {
        _this3.listLoading = false;
        _this3.$message.error(res.message);
      });
    },
    // 分页
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    // 分页
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除   是否确认删除名称为"' + row.name + '"的数据项？'
    handleDelete: function handleDelete(row, type) {
      var _this4 = this;
      this.$modalSure("要删除星级等级为：" + row.name + "的数据吗?").then(function () {
        // const deleteFlag = type == 5 ? "delete" : "recycle";
        (0, _hotelment.productDeleteApi)({
          levelId: row.id
        }).then(function () {
          _this4.$message.success("删除成功");
          _this4.getList();
        });
      });
    },
    // 取消
    resetForm: function resetForm(user) {
      this.dialogVisible = false;
      // this[user] = {};
      this.getList();
    }
  }
};