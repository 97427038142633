var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { model: _vm.ruleForm, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        [
          _c("el-alert", {
            attrs: {
              title: "请勿频繁更改，以免计算产生混乱！",
              type: "warning",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "系统顾客数" } },
        [
          _c("el-input-number", {
            attrs: { placeholder: "请输入系统顾客数", clearable: "" },
            model: {
              value: _vm.ruleForm.levelSysCount,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "levelSysCount", $$v)
              },
              expression: "ruleForm.levelSysCount",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resetForm("ruleForm")
                },
              },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }