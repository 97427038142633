var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "divBox", staticStyle: { "padding-bottom": "0" } },
      [
        _vm.checkPermi(["admin:statistics:user:total:data"])
          ? _c("cards-data", { attrs: { cardLists: _vm.list } })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "divBox", staticStyle: { "padding-top": "0" } },
      [_c("user-info", { key: "1" })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "divBox", staticStyle: { "padding-top": "0" } },
      [
        _c("user-region", {
          key: "3",
          ref: "userRegions",
          attrs: { formInline: _vm.formInline },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }