import { render, staticRenderFns } from "./creatSeckill.vue?vue&type=template&id=0be8f38b&scoped=true"
import script from "./creatSeckill.vue?vue&type=script&lang=js"
export * from "./creatSeckill.vue?vue&type=script&lang=js"
import style0 from "./creatSeckill.vue?vue&type=style&index=0&id=0be8f38b&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0be8f38b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\code\\hny-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0be8f38b')) {
      api.createRecord('0be8f38b', component.options)
    } else {
      api.reload('0be8f38b', component.options)
    }
    module.hot.accept("./creatSeckill.vue?vue&type=template&id=0be8f38b&scoped=true", function () {
      api.rerender('0be8f38b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/marketing/seckill/seckillList/creatSeckill.vue"
export default component.exports