var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      ref: "excel-upload-input",
      staticClass: "excel-upload-input",
      attrs: { type: "file", accept: ".xlsx, .xls" },
      on: { change: _vm.handleClick },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.errorShow,
            expression: "errorShow",
          },
        ],
        staticClass: "errorbox",
      },
      [_vm._v("错误信息：" + _vm._s(_vm.returnData))]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "filename" }, [
      _vm._v("文件名称：" + _vm._s(_vm.fileName)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "drop",
        on: {
          drop: _vm.handleDrop,
          dragover: _vm.handleDragover,
          dragenter: _vm.handleDragover,
        },
      },
      [
        _c(
          "el-button",
          {
            staticStyle: { "margin-left": "16px" },
            attrs: { loading: _vm.loading, size: "large", type: "primary" },
            on: { click: _vm.handleUpload },
          },
          [_vm._v("\n      浏览EXCEL文件\n    ")]
        ),
        _vm._v("  或 拖放EXCEL文件\n  "),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }