"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'item1Inner',
  computed: {},
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var flowId = document.getElementById('flowId');
      var myChart = echarts.init(flowId);
      var option = {
        grid: {
          left: '5%',
          // 调整图表左边距
          right: '6%',
          // 调整图表右边距
          top: '4%',
          // 调整图表上边距
          bottom: '4%',
          // 调整图表下边距
          containLabel: true // 自动计算坐标轴标签是否显示在内部
        },
        xAxis: {
          show: false
        },
        yAxis: [{
          type: 'category',
          data: ['北京', '上海', '广东', '深圳', '杭州', '成都'],
          inverse: true,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            textStyle: {
              fontFamily: '苹方-简 中黑体',
              fontSize: 16
            },
            color: 'white' // 设置 x 坐标字体颜色为白色
          }
        }, {
          type: 'category',
          data: ['1', '2', '3', '4', '5', '6'],
          inverse: true,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            textStyle: {
              fontFamily: '苹方-简 中黑体',
              fontSize: 16
            },
            color: 'white' // 设置 x 坐标字体颜色为白色
          }
        }],
        animationDurationUpdate: 2000,
        series: [{
          type: 'bar',
          itemStyle: {
            normal: {
              color: '#ffffff',
              barBorderRadius: [50, 50, 50, 50],
              opacity: 0.1
            }
          },
          silent: true,
          barWidth: 12,
          barGap: '-100%',
          // Make series be ove
          data: []
        }, {
          type: 'bar',
          itemStyle: {
            barBorderRadius: [50, 50, 50, 50],
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [{
                offset: 0,
                color: '#2BCCA8FF' // 设置起始颜色为红色
              }, {
                offset: 0.5,
                color: '#54ffd9' // 设置中间颜色为白色
              }, {
                offset: 1,
                color: '#2BCCA8FF' // 设置结束颜色为黑色
              }],
              global: false // 独立设置每个系列的颜色
            }
          },
          barWidth: 12,
          data: []
        }]
      };
      var data = [98, 80, 75, 73, 70, 68];
      var max = 0;
      var bgData = [];
      data.forEach(function (it) {
        if (max < it) {
          max = it;
        }
      });
      data.forEach(function (item) {
        bgData.push(max + 20);
      });
      option.series[0].data = bgData;
      option.series[1].data = data;
      myChart.setOption(option);
    }
  }
};