var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-steps",
                { attrs: { active: _vm.currentTab, "align-center": "" } },
                [
                  _c("el-step", { attrs: { title: "安装系统" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "应用配置" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "支付配置" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "站点配置" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "一号通" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 0,
                  expression: "currentTab === 0",
                },
              ],
              staticClass: "install",
            },
            [
              _c(
                "div",
                { staticClass: "ins_name flex align-center" },
                [
                  _c("span", [_vm._v("选择应用配置:")]),
                  _vm._v(" "),
                  _vm.checkArr.includes(_vm.installList[0]) ||
                  _vm.checkArr.includes(_vm.installList[1])
                    ? _c("el-alert", {
                        staticStyle: { width: "300px", "margin-left": "20px" },
                        attrs: {
                          type: "warning",
                          "show-icon": "",
                          title: "使用前提，绑定微信平台",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex" },
                _vm._l(_vm.installList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      class: { active: _vm.checkArr.includes(item) },
                      on: {
                        click: function ($event) {
                          return _vm.install(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "ins_item" }, [
                        _c("img", { attrs: { src: item.img, alt: "" } }),
                        _vm._v(" "),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.checkArr.includes(item),
                              expression: "checkArr.includes(item)",
                            },
                          ],
                          staticClass: "el-icon-success icon_abs",
                        }),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "ins_item_name" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 1,
                  expression: "currentTab === 1",
                },
              ],
              staticClass: "application",
            },
            [
              _c(
                "el-tabs",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.applicationName,
                    callback: function ($$v) {
                      _vm.applicationName = $$v
                    },
                    expression: "applicationName",
                  },
                },
                _vm._l(_vm.checkArr, function (tabItem, itemIndex) {
                  return _c(
                    "el-tab-pane",
                    {
                      key: itemIndex,
                      attrs: {
                        label: tabItem.name,
                        name: tabItem.id.toString(),
                      },
                    },
                    [
                      _vm.formConf.render
                        ? _c("parser", {
                            attrs: {
                              "is-edit": _vm.formConf.isEdit,
                              "form-conf": _vm.formConf.content,
                              "form-edit-data": _vm.currentEditData,
                            },
                            on: { submit: _vm.handlerSubmit },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 2,
                  expression: "currentTab === 2",
                },
              ],
              staticClass: "application",
            },
            [
              _c(
                "el-tabs",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.payConfigName,
                    callback: function ($$v) {
                      _vm.payConfigName = $$v
                    },
                    expression: "payConfigName",
                  },
                },
                _vm._l(_vm.payConfigArr, function (tabItem, itemIndex) {
                  return _c(
                    "el-tab-pane",
                    {
                      key: itemIndex,
                      attrs: { label: tabItem.name, name: tabItem.extra },
                    },
                    [
                      _vm.formConf.render
                        ? _c("parser", {
                            attrs: {
                              "is-edit": _vm.formConf.isEdit,
                              "form-conf": _vm.formConf.content,
                              "form-edit-data": _vm.currentEditData,
                            },
                            on: { submit: _vm.handlerSubmit },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 3,
                  expression: "currentTab === 3",
                },
              ],
              staticClass: "application",
            },
            [
              _c(
                "el-tabs",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.siteConfigName,
                    callback: function ($$v) {
                      _vm.siteConfigName = $$v
                    },
                    expression: "siteConfigName",
                  },
                },
                _vm._l(_vm.siteConfigArr, function (tabItem, itemIndex) {
                  return _c(
                    "el-tab-pane",
                    {
                      key: itemIndex,
                      attrs: { label: tabItem.name, name: tabItem.extra },
                    },
                    [
                      _vm.formConf.render
                        ? _c("parser", {
                            attrs: {
                              "is-edit": _vm.formConf.isEdit,
                              "form-conf": _vm.formConf.content,
                              "form-edit-data": _vm.currentEditData,
                            },
                            on: { submit: _vm.handlerSubmit },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 4,
                  expression: "currentTab === 4",
                },
              ],
              staticClass: "application flex-center",
            },
            [
              _c(
                "div",
                { staticClass: "sms_reg" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "formInline",
                      attrs: {
                        model: _vm.formInline,
                        rules: _vm.ruleInline,
                        "label-position": "right",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c("div", [
                        _c("p", { staticClass: "title mb20" }, [
                          _vm._v("一号通账户注册"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "phone", label: "手机号码" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "340px" },
                            attrs: { placeholder: "请输入您的手机号" },
                            model: {
                              value: _vm.formInline.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInline, "phone", $$v)
                              },
                              expression: "formInline.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password", label: "登录密码" } },
                        [
                          _c("el-input", {
                            key: _vm.passwordType,
                            staticStyle: { width: "340px" },
                            attrs: {
                              type: _vm.passwordType,
                              placeholder: "密码",
                              tabindex: "2",
                              "auto-complete": "off",
                            },
                            model: {
                              value: _vm.formInline.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInline, "password", $$v)
                              },
                              expression: "formInline.password",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "domain", label: "网址域名" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "340px" },
                            attrs: { placeholder: "请输入网址域名" },
                            model: {
                              value: _vm.formInline.domain,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInline, "domain", $$v)
                              },
                              expression: "formInline.domain",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "captcha",
                          attrs: { prop: "code", label: "验证码" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "acea-row",
                              staticStyle: { "flex-wrap": "nowrap" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "240px",
                                  "margin-right": "10px",
                                },
                                attrs: {
                                  placeholder: "验证码",
                                  type: "text",
                                  tabindex: "1",
                                },
                                model: {
                                  value: _vm.formInline.code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formInline, "code", $$v)
                                  },
                                  expression: "formInline.code",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["admin:pass:send:code"],
                                      expression: "['admin:pass:send:code']",
                                    },
                                  ],
                                  attrs: {
                                    size: "mini",
                                    disabled: !this.canClick,
                                    type: "primary",
                                    plain: "",
                                  },
                                  on: { click: _vm.cutDown },
                                },
                                [_vm._v(_vm._s(_vm.cutNUm))]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex-center mb20" },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["admin:pass:register"],
                                  expression: "['admin:pass:register']",
                                },
                              ],
                              attrs: { loading: _vm.loading, type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.formSubmit("formInline")
                                },
                              },
                            },
                            [_vm._v("注册")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex-center go_login" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/operation/onePass" } },
                            [_vm._v("已有帐号 去登录")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab > 0,
                  expression: "currentTab>0",
                },
              ],
              staticClass: "step_btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.beforeStep()
                },
              },
            },
            [_vm._v("上一步")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab < 4,
                  expression: "currentTab<4",
                },
              ],
              class: _vm.currentTab == 0 ? "step_btn" : "",
              attrs: { type: "primary", disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  return _vm.nextStep()
                },
              },
            },
            [_vm._v("下一步")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 4,
                  expression: "currentTab===4",
                },
              ],
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.complate()
                },
              },
            },
            [_vm._v("完成")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }