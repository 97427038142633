"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  computed: {},
  data: function data() {
    return {
      list: [{
        name: '城市',
        value: "拉萨"
      }, {
        name: '在线天数',
        value: "230天"
      }, {
        name: '在线率',
        value: ">=98%"
      }, {
        name: '设备维护',
        value: "累计5次"
      }, {
        name: '技术维护',
        value: "累计2次"
      }]
    };
  }
};