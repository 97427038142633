var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox", staticStyle: { "padding-top": "0" } },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _vm.checkPermi(["admin:integral:skl:list"])
            ? _c("div", { staticClass: "flex justify-between mb20" }, [
                _c("div", { staticClass: "header_title" }, [
                  _vm._v("平台收益统计"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "acea-row" },
                  [
                    _c(
                      "div",
                      { staticClass: "mr-20" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "mr20",
                            attrs: { type: "button", size: "small" },
                            on: {
                              change: function ($event) {
                                return _vm.selectChange(_vm.dateLimit)
                              },
                            },
                            model: {
                              value: _vm.dateLimit,
                              callback: function ($$v) {
                                _vm.dateLimit = $$v
                              },
                              expression: "dateLimit",
                            },
                          },
                          _vm._l(_vm.fromList.fromTxt, function (item, i) {
                            return _c(
                              "el-radio-button",
                              { key: i, attrs: { label: item.val } },
                              [_vm._v(_vm._s(item.text) + "\n            ")]
                            )
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            "value-format": "yyyy-MM-dd",
                            format: "yyyy-MM-dd",
                            size: "small",
                            type: "daterange",
                            placement: "bottom-end",
                            placeholder: "创建时间",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                          },
                          on: { change: _vm.onchangeTime },
                          model: {
                            value: _vm.timeVal,
                            callback: function ($$v) {
                              _vm.timeVal = $$v
                            },
                            expression: "timeVal",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.checkPermi(["admin:integral:skl:list"])
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.search },
                          },
                          [_vm._v("查询")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.checkPermi(["admin:integral:skl:list:download"])
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "default",
                              size: "small",
                              icon: "el-icon-upload2",
                            },
                            on: { click: _vm.exports },
                          },
                          [_vm._v("导出")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: "center",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "reportDate",
                  label: "日期",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "ydaIncrIntegral",
                  label: "新增收益",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "towIncome",
                  label: "托管设备收益",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "selfIncome",
                  label: "自装设备收益",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "proxyIncome",
                  label: "代理人分红",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "partnerIncome",
                  label: "服务商分红",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "platIntegral",
                  label: "平台",
                  align: "center",
                  "min-width": "100",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-center" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableForm.limit,
                  "current-page": _vm.tableForm.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }