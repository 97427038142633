var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-style" }, [
    _c(
      "div",
      { staticClass: "center-bg-style" },
      [
        _c("div", { staticClass: "title-style" }, [
          _vm._v("\n      7月内容平台流量排行\n    "),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.list, function (item, index) {
          return _c("div", { staticClass: "content-style" }, [
            _c(
              "div",
              { staticStyle: { height: "100%", width: "100%", float: "left" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "content-left-style",
                    staticStyle: { color: "#ffffff", "font-size": "16px" },
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "content-content-style",
                    staticStyle: { color: "#ffffff", "font-size": "16px" },
                  },
                  [_vm._v(_vm._s(item.value))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "content-right-style",
                    staticStyle: { color: "#ffffff", "font-size": "16px" },
                  },
                  [_vm._v(_vm._s(item.content))]
                ),
              ]
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-style" }, [
      _c(
        "div",
        { staticStyle: { height: "100%", width: "100%", float: "left" } },
        [
          _c("div", { staticClass: "content-left-style" }, [_vm._v("排名")]),
          _vm._v(" "),
          _c("div", { staticClass: "content-content-style" }, [
            _vm._v("内容平台名称"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-right-style" }, [_vm._v("流量")]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }