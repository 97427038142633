"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  computed: {},
  data: function data() {
    return {
      list: [{
        name: 'Top1',
        value: "转发量",
        content: "1.45EB"
      }, {
        name: 'Top2',
        value: "成交量",
        content: "1.73万"
      }, {
        name: 'Top3',
        value: "分发总数",
        content: "158.15万"
      }, {
        name: 'Top4',
        value: "分发用户",
        content: "13.26万"
      }, {
        name: 'Top5',
        value: "总节点量",
        content: "60383"
      }]
    };
  }
};