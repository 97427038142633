"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/Tinymce/index"));
var _device = require("@/api/device");
var _validate = require("@/utils/validate");
var _permission = require("@/utils/permission");
var _uploadFile = _interopRequireDefault(require("@/components/Upload/uploadFile.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  // name: "edit",
  components: {
    Tinymce: _index.default,
    UploadFile: _uploadFile.default
  },
  data: function data() {
    return {
      loading: false,
      constants: this.$constants,
      pram: {
        cid: null,
        name: null,
        info: "",
        //<span>My Document\'s Title</span>

        price: "",
        stock: "",
        buyMin: "",
        buyMax: "",
        towCycle: "",
        cycleUint: "天",
        packDay: "",
        incomeMin: "",
        incomeMax: "",
        salesContract: "",
        technicalContract: "",
        scName: "",
        tcName: "",
        img: "",
        sort: 0,
        id: null
      },
      editData: {},
      grid2: {
        xl: 12,
        lg: 12,
        md: 12,
        sm: 24,
        xs: 24
      }
    };
  },
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
  },
  mounted: function mounted() {
    if (this.$route.params.id) {
      this.getInfo();
      this.setTagsViewTitle();
    }
  },
  methods: {
    checkPermi: _permission.checkPermi,
    getInfo: function getInfo() {
      var _this2 = this;
      (0, _device.deviceManagerDetailApi)({
        id: this.$route.params.id
      }).then(function (data) {
        _this2.editData = data;
        _this2.hadlerInitEditData();
      });
    },
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.pram.img = img[0].sattDir;
      }, tit, "content");
    },
    hadlerInitEditData: function hadlerInitEditData() {
      if (!this.$route.params.id) return;
      var _this$editData = this.editData,
        name = _this$editData.name,
        cid = _this$editData.cid,
        info = _this$editData.info,
        price = _this$editData.price,
        stock = _this$editData.stock,
        buyMin = _this$editData.buyMin,
        buyMax = _this$editData.buyMax,
        towCycle = _this$editData.towCycle,
        cycleUint = _this$editData.cycleUint,
        packDay = _this$editData.packDay,
        incomeMin = _this$editData.incomeMin,
        incomeMax = _this$editData.incomeMax,
        img = _this$editData.img,
        sort = _this$editData.sort,
        id = _this$editData.id,
        salesContract = _this$editData.salesContract,
        technicalContract = _this$editData.technicalContract,
        scName = _this$editData.scName,
        tcName = _this$editData.tcName;
      this.pram.name = name;
      this.pram.cid = Number.parseInt(cid);
      this.pram.info = info;
      this.pram.img = img;
      this.pram.price = price;
      this.pram.stock = stock;
      this.pram.buyMin = buyMin;
      this.pram.buyMax = buyMax;
      this.pram.towCycle = towCycle;
      this.pram.cycleUint = cycleUint || "天";
      this.pram.packDay = packDay;
      this.pram.incomeMin = incomeMin;
      this.pram.incomeMax = incomeMax;
      this.pram.sort = sort;
      this.pram.id = id;
      this.pram.salesContract = salesContract;
      this.pram.technicalContract = technicalContract;
      this.pram.scName = scName;
      this.pram.tcName = tcName;
      console.log(this.pram);
    },
    handerSubmit: (0, _validate.Debounce)(function (form) {
      var _this3 = this;
      this.$refs[form].validate(function (valid) {
        if (!valid) return;
        console.log(_this3.pram);
        _this3.$modalSure("\u63D0\u4EA4").then(function () {
          _this3.handlerSave();
        });
      });
    }),
    handlerSave: function handlerSave() {
      var _this4 = this;
      this.loading = true;
      this.pram.cid = Array.isArray(this.pram.cid) ? this.pram.cid[0] : this.pram.cid;
      this.pram.shareTitle = this.pram.name;
      this.pram.shareSynopsis = this.pram.synopsis;
      (0, _device.deviceManagerAddApi)(this.pram).then(function (data) {
        _this4.$message.success("保存成功");
        _this4.loading = false;
        _this4.$router.push({
          path: "/device/manager",
          query: {
            time: new Date().getTime()
          }
        });
      }).catch(function () {
        _this4.loading = false;
      });
    },
    setTagsViewTitle: function setTagsViewTitle() {
      var title = "编辑设备";
      var route = Object.assign({}, this.tempRoute, {
        title: "".concat(title, "-").concat(this.$route.params.id)
      });
      this.$store.dispatch("tagsView/updateVisitedView", route);
    },
    /////////////////////////////合同上传
    onChangeContract: function onChangeContract(e, typeUrl, typeName) {
      this.pram[typeUrl] = e.url;
      this.pram[typeName] = e.name;
    },
    // 预览
    openPdf: function openPdf(url) {
      window.open(url, "_blank");
    }
  }
};