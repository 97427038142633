var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "formValidate",
      staticClass: "demo-formValidate",
      attrs: {
        model: _vm.formValidate,
        rules: _vm.rules,
        "label-width": "100px",
      },
    },
    [
      _c("el-form-item", { attrs: { label: "商品：", prop: "productId" } }, [
        _c(
          "div",
          { staticClass: "upLoadPicBox", on: { click: _vm.changeGood } },
          [
            _vm.formValidate.productId
              ? _c("div", { staticClass: "pictrue" }, [
                  _c("img", { attrs: { src: _vm.image } }),
                ])
              : _c("div", { staticClass: "upLoad" }, [
                  _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
                ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "用户昵称：", prop: "nickname" } },
        [
          _c("el-input", {
            attrs: { type: "text" },
            model: {
              value: _vm.formValidate.nickname,
              callback: function ($$v) {
                _vm.$set(_vm.formValidate, "nickname", $$v)
              },
              expression: "formValidate.nickname ",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "评价文字：", prop: "comment" } },
        [
          _c("el-input", {
            attrs: { type: "textarea" },
            model: {
              value: _vm.formValidate.comment,
              callback: function ($$v) {
                _vm.$set(_vm.formValidate, "comment", $$v)
              },
              expression: "formValidate.comment ",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          staticClass: "productScore",
          attrs: { label: "商品分数：", prop: "productScore" },
        },
        [
          _c("el-rate", {
            model: {
              value: _vm.formValidate.productScore,
              callback: function ($$v) {
                _vm.$set(_vm.formValidate, "productScore", $$v)
              },
              expression: "formValidate.productScore",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          staticClass: "productScore",
          attrs: { label: "服务分数：", prop: "serviceScore" },
        },
        [
          _c("el-rate", {
            model: {
              value: _vm.formValidate.serviceScore,
              callback: function ($$v) {
                _vm.$set(_vm.formValidate, "serviceScore", $$v)
              },
              expression: "formValidate.serviceScore",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-form-item", { attrs: { label: "用户头像：", prop: "avatar" } }, [
        _c(
          "div",
          {
            staticClass: "upLoadPicBox",
            on: {
              click: function ($event) {
                return _vm.modalPicTap("1")
              },
            },
          },
          [
            _vm.formValidate.avatar
              ? _c("div", { staticClass: "pictrue" }, [
                  _c("img", { attrs: { src: _vm.formValidate.avatar } }),
                ])
              : _c("div", { staticClass: "upLoad" }, [
                  _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
                ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("el-form-item", { attrs: { label: "评价图片：" } }, [
        _c(
          "div",
          { staticClass: "acea-row" },
          [
            _vm._l(_vm.pics, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "pictrue",
                  attrs: { draggable: "false" },
                  on: {
                    dragstart: function ($event) {
                      return _vm.handleDragStart($event, item)
                    },
                    dragover: function ($event) {
                      $event.preventDefault()
                      return _vm.handleDragOver($event, item)
                    },
                    dragenter: function ($event) {
                      return _vm.handleDragEnter($event, item)
                    },
                    dragend: function ($event) {
                      return _vm.handleDragEnd($event, item)
                    },
                  },
                },
                [
                  _c("img", { attrs: { src: item } }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "el-icon-error btndel",
                    on: {
                      click: function ($event) {
                        return _vm.handleRemove(index)
                      },
                    },
                  }),
                ]
              )
            }),
            _vm._v(" "),
            _vm.pics < 10
              ? _c(
                  "div",
                  {
                    staticClass: "upLoadPicBox",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("2")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "upLoad" }, [
                      _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary", loading: _vm.loadingbtn },
              on: {
                click: function ($event) {
                  return _vm.submitForm("formValidate")
                },
              },
            },
            [_vm._v("提交")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.resetForm("formValidate")
                },
              },
            },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }