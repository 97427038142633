"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _task = require("@/api/task");
var _permission = require("@/utils/permission");
var _createUserTaskDetail = _interopRequireDefault(require("@/views/task/usertaskDetail/createUserTaskDetail"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "UserTaskDetailindex",
  components: {
    createUsertaskdetail: _createUserTaskDetail.default
  },
  data: function data() {
    return {
      taskInfo: {},
      tableFrom: {
        // keywords: null,
        inviterPhone: '',
        page: 1,
        limit: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  mounted: function mounted() {
    _permission.checkPermi, this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _task.usertaskDetailListApi)({
        limit: this.tableFrom.limit,
        page: this.tableFrom.page,
        inviterPhone: this.tableFrom.inviterPhone
      }).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      // this.changePageCoreRecordData()
      this.tableFrom.page = page;
      this.getList();
    },
    onAdd: function onAdd(row) {
      //task与create-task ref="task"中的task一样
      this.$refs.task.dialogVisible = true;
      this.taskInfo = {};
    },
    edit: function edit(id) {
      this.taskInfo = id;
      this.$refs.task.dialogVisible = true;
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this2 = this;
      this.$modalSure('删除吗？删除会导致对应用户任务明细清空，请谨慎操作！').then(function () {
        (0, _task.usertaskDetailDeleteApi)(id).then(function () {
          _this2.$message.success('删除成功');
          _this2.tableData.data.splice(idx, 1);
        });
      });
    },
    handerSearch: function handerSearch() {
      this.tableFrom.page = 1;
      this.getList();
    }
  }
};