"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _combinedDataEdit = _interopRequireDefault(require("@/views/maintain/devconfig/combinedDataEdit"));
var systemGroupApi = _interopRequireWildcard(require("@/api/systemGroup"));
var _combineDataList = _interopRequireDefault(require("./combineDataList"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "combinedData"
  components: {
    edit: _combinedDataEdit.default,
    cmDataList: _combineDataList.default
  },
  data: function data() {
    return {
      constants: this.$constants,
      dataList: {
        list: [],
        total: 0
      },
      listPram: {
        keywords: null,
        page: 1,
        pageSize: this.$constants.page.limit[0]
      },
      editDialogConfig: {
        visible: false,
        isCreate: 0,
        // 0=创建 1=编辑
        editData: {}
      },
      comDataListConfig: {
        visible: false,
        formData: {}
      }
    };
  },
  mounted: function mounted() {
    this.handlerGetList(this.listPram);
  },
  methods: {
    handlerSearch: function handlerSearch() {
      this.listPram.page = 1;
      this.handlerGetList(this.listPram);
    },
    handlerOpenEdit: function handlerOpenEdit(editData, isCreate) {
      isCreate === 0 ? this.editDialogConfig.editData = {} : this.editDialogConfig.editData = editData;
      this.editDialogConfig.isCreate = isCreate;
      this.editDialogConfig.visible = true;
    },
    handlerGetList: function handlerGetList(pram) {
      var _this = this;
      systemGroupApi.groupList(pram).then(function (data) {
        _this.dataList = data;
      });
    },
    handleDataList: function handleDataList(rowData) {
      if (rowData.formId <= 0) return this.$message.error('请先关联表单');
      this.comDataListConfig.formData = rowData;
      this.comDataListConfig.visible = true;
    },
    handleDelete: function handleDelete(rowData) {
      var _this2 = this;
      this.$confirm('确定删除当前数据', '提示').then(function () {
        systemGroupApi.groupDelete(rowData).then(function (data) {
          _this2.$message.success('删除数据成功');
          setTimeout(function () {
            _this2.handlerGetList(_this2.listPram);
          }, 800);
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetList(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetList(this.listPram);
    },
    handlerHideDialog: function handlerHideDialog() {
      var _this3 = this;
      setTimeout(function () {
        _this3.editDialogConfig.visible = false;
        _this3.handlerGetList(_this3.listPram);
      }, 800);
    }
  }
};