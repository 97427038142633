"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _im = require("@/api/im");
var _validate = require("@/utils/validate");
var _permission = require("@/utils/permission");
var _imMember = _interopRequireDefault(require("./imMember"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  components: {
    imMember: _imMember.default
  },
  data: function data() {
    return {
      tableForm: {
        tName: "",
        intro: "",
        minNum: "",
        maxNum: "",
        startDate: "",
        endDate: "",
        page: 1,
        limit: 20
      },
      timeVal: [],
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      // im成员弹窗
      imGroupForm: null,
      imMemberVisible: false,
      imHandleVisible: false,
      loadingImHandle: false,
      loadingBtn: false,
      imHandleForm: {
        tid: '',
        tname: '',
        invitemode: 0,
        mute: false,
        edit: 0,
        intro: ''
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    reset: function reset() {
      this.tableForm.tName = "";
      this.tableForm.startDate = "";
      this.tableForm.endDate = "";
      this.tableForm.intro = "";
      this.tableForm.minNum = "";
      this.tableForm.maxNum = "";
      this.timeVal = [];
      this.tableForm.page = 1;
      this.getList();
    },
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _im.imPageGroupListApi)(this.tableForm).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableForm.startDate = e ? this.timeVal[0] : "";
      this.tableForm.endDate = e ? this.timeVal[1] : "";
      this.tableForm.page = 1;
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    },
    Close: function Close() {
      this.imGroupForm = null;
      this.imMemberVisible = false;
      this.imHandleVisible = false;
    },
    viewIM: function viewIM(row) {
      this.imGroupForm = row;
      this.imMemberVisible = true;
    },
    setIM: function setIM(row) {
      var _this2 = this;
      (0, _im.imPageGroupDetailApi)(row.tid).then(function (res) {
        _this2.imHandleForm = {
          tid: res.tid,
          tname: res.tname,
          invitemode: res.invitemode || 0,
          mute: res.mute,
          edit: res.edit || 0,
          intro: res.intro
        };
        _this2.imHandleVisible = true;
      });
    },
    // 提交修改
    submitIMForm: function submitIMForm(formName) {
      var _this3 = this;
      var that = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this3.imHandleForm.tname === '' || _this3.imHandleForm.tname === null) {
            that.$message.error('请填写群名称');
            return false;
          }
          if (_this3.imHandleForm.intro === '' || _this3.imHandleForm.intro === null) {
            that.$message.error('请填写群介绍');
            return false;
          }
          that.loadingBtn = true;
          (0, _im.imPageGroupConfigSetApi)(that.imHandleForm).then(function (res) {
            that.$message.success('操作成功');
            that.loadingBtn = false;
            that.Close();
            that.getList();
          }).catch(function (res) {
            that.loadingBtn = false;
          });
        } else {
          return false;
        }
      });
    },
    imRemoveHandle: function imRemoveHandle() {
      var that = this;
      that.loadingBtn = true;
      that.$confirm("\u786E\u8BA4\u89E3\u6563\u7FA4\u804A?", "提示").then(function () {
        (0, _im.imPageGroupRemoveApi)({
          tid: that.imHandleForm.tid
        }).then(function (res) {
          that.$message.success('操作成功');
          that.loadingBtn = false;
          that.Close();
          that.getList();
        }).catch(function (res) {
          that.loadingBtn = false;
        });
      }).catch(function () {
        that.loadingBtn = false;
      });
    },
    handleOk: function handleOk(v) {
      this.getList();
    }
  }
};