"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _task = require("@/api/task");
var _createTask = _interopRequireDefault(require("@/views/task/taskpackageManager/createTask"));
var _permission = require("@/utils/permission");
var _user = require("@/api/user");
var _statistic = require("@/api/statistic");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
// 权限判断函数
var _default = exports.default = {
  name: "TaskPakageManagerindex",
  components: {
    createTask: _createTask.default
  },
  data: function data() {
    return {
      taskInfo: {},
      tableFrom: {
        keywords: null,
        page: 1,
        limit: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      upgradeCondVisible: false,
      upgradeCondForm: {
        certifyMembers: "0",
        littleTeamActiveness: "0",
        phone: "",
        startLevelId: "0",
        teamActiveness: "0",
        uid: '0'
      },
      loading: false
    };
  },
  mounted: function mounted() {
    _permission.checkPermi, this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _task.taskPakageListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      // this.changePageCoreRecordData()
      this.tableFrom.page = page;
      this.getList();
    },
    onAdd: function onAdd(row) {
      //task与create-task ref="task"中的task一样
      this.$refs.task.dialogVisible = true;
      this.taskInfo = {
        image: '',
        manageStock: false
      };
    },
    edit: function edit(item) {
      this.taskInfo = _objectSpread(_objectSpread({}, item), {}, {
        manageStock: item.manageStock || false
      });
      this.$refs.task.dialogVisible = true;
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this2 = this;
      this.$modalSure('删除吗？删除会导致对应任务包管理数据清空，请谨慎操作！').then(function () {
        (0, _task.taskPackageManagerDeleteApi)(id).then(function () {
          _this2.$message.success('删除成功');
          _this2.tableData.data.splice(idx, 1);
        });
      });
    },
    handerSearch: function handerSearch() {
      this.tableFrom.page = 1;
      this.getList();
    },
    upgrade: function upgrade(row) {
      console.log(row, JSON.stringify(row));
      this.upgradeCondForm.startLevelId = row.id;
      this.upgradeCondVisible = true;
    },
    handleCloseUpgradeCond: function handleCloseUpgradeCond() {
      this.upgradeCondVisible = false;
    },
    submitUpgradeCondForm: function submitUpgradeCondForm(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          console.log(_this3.upgradeCondForm);
          (0, _statistic.manuallySetUpgradeConditionsApi)(_this3.upgradeCondForm).then(function (res) {
            _this3.$message.success('升级成功');
            _this3.loading = false;
            _this3.handleCloseUpgradeCond();
            _this3.getList();
          }).catch(function () {
            _this3.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};