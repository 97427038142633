"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _WriteOff = _interopRequireDefault(require("../components/WriteOff"));
var _wechat = require("@/libs/wechat");
var _order = require("@/api/order");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { orderVerific } from "@api/order";
var NAME = "OrderCancellation";
var _default = exports.default = {
  name: NAME,
  components: {
    WriteOff: _WriteOff.default
  },
  props: {},
  data: function data() {
    return {
      isWeixin: this.$wechat.isWeixin(),
      iShidden: true,
      orderInfo: null,
      verify_code: ""
    };
  },
  created: function created() {
    Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/assets/js/media_750'));
    });
  },
  methods: {
    cancel: function cancel(res) {
      this.iShidden = res;
    },
    confirm: function confirm() {
      var _this = this;
      (0, _order.writeUpdateApi)(this.verify_code).then(function (res) {
        _this.iShidden = true;
        _this.verify_code = "";
        _this.$dialog.success(res.msg);
      }).catch(function (res) {
        _this.$dialog.error(res.msg);
      });
    },
    storeCancellation: function storeCancellation() {
      var _this2 = this;
      var ref = /[0-9]{10}/;
      if (!this.verify_code) return this.$dialog.error("请输入核销码");
      if (!ref.test(this.verify_code)) return this.$dialog.error("请输入正确的核销码");
      this.$dialog.loading.open("查询中");
      (0, _order.writeConfirmApi)(this.verify_code).then(function (res) {
        _this2.$dialog.loading.close();
        _this2.orderInfo = res;
        _this2.iShidden = false;
      }).catch(function (res) {
        _this2.$dialog.loading.close();
        _this2.verify_code = "";
        return _this2.$dialog.error(res.message);
      });
    },
    openQRCode: function openQRCode() {
      var that = this;
      (0, _wechat.wechatEvevt)("scanQRCode", {
        needResult: 1,
        scanType: ["qrCode", "barCode"]
      }).then(function (res) {
        if (res.resultStr) {
          that.verify_code = res.resultStr;
          that.storeCancellation();
        } else that.$dialog.error("没有扫描到什么！");
      }).catch(function (res) {
        if (res.is_ready) {
          res.wx.scanQRCode({
            needResult: 1,
            scanType: ["qrCode", "barCode"],
            success: function success(res) {
              that.verify_code = res.resultStr;
              that.storeCancellation();
            },
            fail: function fail(res) {
              if (res.errMsg == "scanQRCode:permission denied") {
                that.$dialog.error("没有权限");
              }
            }
          });
        }
      });
    }
  }
};