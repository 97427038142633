"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vScaleScreen = _interopRequireDefault(require("v-scale-screen"));
var _leftTop = _interopRequireDefault(require("./indexs/leftTop.vue"));
var _leftCenter = _interopRequireDefault(require("./indexs/leftCenter.vue"));
var _leftBottom = _interopRequireDefault(require("./indexs/leftBottom.vue"));
var _centerTop = _interopRequireDefault(require("./indexs/centerTop.vue"));
var _centerBottom = _interopRequireDefault(require("./indexs/centerBottom.vue"));
var _rightTop = _interopRequireDefault(require("./indexs/rightTop.vue"));
var _rightCenter = _interopRequireDefault(require("./indexs/rightCenter.vue"));
var _rightBottom = _interopRequireDefault(require("./indexs/rightBottom.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'item1Inner',
  computed: {},
  components: {
    ScaleScreen: _vScaleScreen.default,
    LeftTop: _leftTop.default,
    LeftCenter: _leftCenter.default,
    LeftBottom: _leftBottom.default,
    CenterTop: _centerTop.default,
    CenterBottom: _centerBottom.default,
    RightTop: _rightTop.default,
    RightCenter: _rightCenter.default,
    RightBottom: _rightBottom.default
  }
};