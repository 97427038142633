"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// Here is a list of the toolbar
// Detail list see https://www.tinymce.com/docs/advanced/editor-control-identifiers/#toolbarcontrols

//hr bullist numlist    后面的 link image 屏蔽了

var toolbar = ['searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample fontsizeselect  fontselect', 'hr bullist numlist charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'];
var _default = exports.default = toolbar;