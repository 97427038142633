var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-card", { staticClass: "box-card" }, [
        _vm.item && _vm.item.uid
          ? _c("div", { staticClass: "container mt-1 text-center" }, [
              _c("div", { staticClass: "sign-content" }, [
                _c("div", { staticClass: "sign-content-cell" }, [
                  _c("span", [_vm._v("姓名")]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.item.realName))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sign-content-cell" }, [
                  _c("span", [_vm._v("身份证号")]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.item.cardId))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sign-content-cell" }, [
                  _c("span", [_vm._v("合同签署状态")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.item.signState == 1
                          ? "已签署"
                          : _vm.item.signUrl
                          ? "未签署"
                          : "合同未拟定"
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sign-content-cell" }, [
                  _c("span", { staticClass: "contract-title" }, [
                    _vm._v("签署合同"),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "img-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: {
                              size: "small",
                              title: _vm.item.scName,
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.hander(
                                  "contractView",
                                  _vm.item.signUrl,
                                  "星蒙云服务协议及服务器托管技术合同"
                                )
                              },
                            },
                          },
                          [_vm._v("预览合同")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.item.status > 0
                  ? _c("div", { staticClass: "sign-content-cell" }, [
                      _c("span", [_vm._v("审核时间")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.item.applyTime))]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.item.status == 2
                  ? _c("div", { staticClass: "sign-content-cell" }, [
                      _c("span", [_vm._v("确认上线时间")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.item.activeTime))]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sign-btn" },
                [
                  _vm.viewType == "edit" &&
                  _vm.checkPermi(["admin:device:order:contract"])
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["admin:device:order:process"],
                              expression: "['admin:device:order:process']",
                            },
                          ],
                          staticClass: "mr10",
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.hander("process")
                            },
                          },
                        },
                        [_vm._v("审核通过")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.viewType == "edit" &&
                  _vm.checkPermi(["admin:device:order:contract"])
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["admin:device:order:process"],
                              expression: "['admin:device:order:process']",
                            },
                          ],
                          staticClass: "mr10",
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.hander("no-process")
                            },
                          },
                        },
                        [_vm._v("审核不通过")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.hander("close")
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }