var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "container mt-1" },
          [
            _c(
              "el-form",
              {
                ref: "formValidate",
                staticClass: "formValidate mt20",
                attrs: {
                  rules: _vm.ruleValidate,
                  model: _vm.formValidate,
                  "label-width": "120px",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "标题：", prop: "title" } },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "249", placeholder: "请输入标题" },
                      model: {
                        value: _vm.formValidate.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "title", $$v)
                        },
                        expression: "formValidate.title",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "材料类型：", prop: "type" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.onChangeType(_vm.formValidate.type)
                          },
                        },
                        model: {
                          value: _vm.formValidate.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "type", $$v)
                          },
                          expression: "formValidate.type",
                        },
                      },
                      [
                        _c(
                          "el-radio",
                          { staticClass: "radio", attrs: { label: "V" } },
                          [_vm._v("视频")]
                        ),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "I" } }, [
                          _vm._v("图文"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "分类：", prop: "categoryId" } },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "500px" } },
                      [
                        _c("CollegeCategorySelect", {
                          attrs: { id: _vm.formValidate.categoryId },
                          on: {
                            onChange: _vm.handleSelect,
                            onChangeItem: _vm.handleSelectItem,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "封面图片：", prop: "image" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "upLoadPicBox",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap("1", "pic")
                          },
                        },
                      },
                      [
                        _vm.formValidate.image
                          ? _c("div", { staticClass: "pictrue" }, [
                              _c("img", {
                                attrs: { src: _vm.formValidate.image },
                              }),
                            ])
                          : _c("div", { staticClass: "upLoad" }, [
                              _c("i", {
                                staticClass: "el-icon-camera cameraIconfont",
                              }),
                            ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.formValidate.type == "V"
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "视频上传方式：", prop: "videoType" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.onChangeVideoType(
                                  _vm.formValidate.videoType
                                )
                              },
                            },
                            model: {
                              value: _vm.formValidate.videoType,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "videoType", $$v)
                              },
                              expression: "formValidate.videoType",
                            },
                          },
                          [
                            _c(
                              "el-radio",
                              { staticClass: "radio", attrs: { label: "L" } },
                              [_vm._v("本地上传")]
                            ),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "O" } }, [
                              _vm._v("外部链接"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.formValidate.type == "V" &&
                _vm.formValidate.videoType == "O"
                  ? _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "400px" },
                        attrs: { label: "视频外部链接：", prop: "videoLink" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "videoLink-style",
                          attrs: {
                            maxlength: "250",
                            placeholder: "请输入视频外部链接",
                          },
                          model: {
                            value: _vm.formValidate.videoLink,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "videoLink", $$v)
                            },
                            expression: "formValidate.videoLink",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.formValidate.type == "V" &&
                _vm.formValidate.videoType == "L"
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "视频：", prop: "videoUrl" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("1", "video")
                              },
                            },
                          },
                          [
                            _vm.formValidate.videoUrl
                              ? _c(
                                  "div",
                                  { staticClass: "iview-video-style" },
                                  [
                                    _c(
                                      "video",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100% !important",
                                          "border-radius": "10px",
                                        },
                                        attrs: {
                                          src: _vm.formValidate.videoUrl,
                                          controls: "",
                                          muted: "",
                                        },
                                        domProps: { muted: true },
                                      },
                                      [
                                        _vm._v(
                                          "\n                您的浏览器不支持 video 标签。\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "mark" }),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass:
                                        "iconv iconfont iconmd-trash",
                                      on: {
                                        click: function ($event) {
                                          return _vm.delVideo()
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.formValidate.type == "I"
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "内容：", prop: "content" } },
                      [
                        _c("Tinymce", {
                          ref: "collegeTinymce",
                          model: {
                            value: _vm.formValidate.content,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "content", $$v)
                            },
                            expression: "formValidate.content",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSubmit("formValidate")
                          },
                        },
                      },
                      [_vm._v("保存\n          ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }