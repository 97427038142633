var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "container mt-1" },
          [
            _c(
              "el-form",
              {
                ref: "formValidate",
                staticClass: "formValidate mt20",
                attrs: {
                  rules: _vm.ruleValidate,
                  model: _vm.formValidate,
                  "label-width": "140px",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-row",
                  { attrs: { align: "middle" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "驿站名称：", prop: "yzName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "50",
                                placeholder: "请输入驿站名称",
                              },
                              model: {
                                value: _vm.formValidate.yzName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "yzName", $$v)
                                },
                                expression: "formValidate.yzName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "驿站编码：", prop: "yzCode" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "50",
                                placeholder: "请输入驿站编码",
                                disabled: _vm.isDisabled,
                              },
                              model: {
                                value: _vm.formValidate.yzCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "yzCode", $$v)
                                },
                                expression: "formValidate.yzCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { align: "middle" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 23 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "驿站图片：",
                              prop: "yzImg",
                              rules: [
                                {
                                  required: true,
                                  message: "请上传驿站图片",
                                  trigger: "change",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "upLoadPicBox",
                                on: {
                                  click: function ($event) {
                                    return _vm.modalPicTap("1")
                                  },
                                },
                              },
                              [
                                _vm.formValidate.yzImg
                                  ? _c("div", { staticClass: "pictrue" }, [
                                      _c("img", {
                                        attrs: { src: _vm.formValidate.yzImg },
                                      }),
                                    ])
                                  : _c("div", { staticClass: "upLoad" }, [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-camera cameraIconfont",
                                      }),
                                    ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "tips" }, [
                              _vm._v("只能上传jpg/png格式文件，限1张。"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 1 } }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { align: "middle" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "驿站地址：", prop: "addressId" } },
                          [
                            _c("el-cascader", {
                              ref: "crowdfCascader",
                              staticClass: "w240 dialogWidth",
                              attrs: {
                                filterable: "",
                                clearable: "",
                                options: _vm.addresData,
                                props: {
                                  value: "cityId",
                                  label: "name",
                                  children: "child",
                                },
                              },
                              on: { change: _vm.handleChange },
                              model: {
                                value: _vm.formValidate.addressId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "addressId", $$v)
                                },
                                expression: "formValidate.addressId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "详细地址：", prop: "address" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "50",
                                placeholder: "请输入详细地址",
                              },
                              model: {
                                value: _vm.formValidate.address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "address", $$v)
                                },
                                expression: "formValidate.address",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { align: "middle" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "众筹总份数：",
                              prop: "crowdCount",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "step-strictly": "",
                                precision: 0,
                                step: 1,
                                min: 0,
                                max: 99999,
                              },
                              model: {
                                value: _vm.formValidate.crowdCount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "crowdCount", $$v)
                                },
                                expression: "formValidate.crowdCount",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "每份兑换价：", prop: "cnyPrice" },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "step-strictly": "",
                                precision: 2,
                                step: 0.01,
                                min: 0,
                                max: 99999,
                              },
                              model: {
                                value: _vm.formValidate.cnyPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "cnyPrice", $$v)
                                },
                                expression: "formValidate.cnyPrice",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "priceUnit" }, [
                              _vm._v("CNY"),
                            ]),
                            _vm._v(" "),
                            _c("el-input-number", {
                              attrs: {
                                "step-strictly": "",
                                precision: 2,
                                step: 0.01,
                                min: 0,
                                max: 99999,
                              },
                              model: {
                                value: _vm.formValidate.sklPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "sklPrice", $$v)
                                },
                                expression: "formValidate.sklPrice",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "priceUnit" }, [
                              _vm._v("余额"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { align: "middle" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "总产出：", prop: "totalEarn" } },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "step-strictly": "",
                                precision: 2,
                                step: 0.01,
                                min: 0,
                                max: 99999,
                                disabled: _vm.isDisabled,
                              },
                              model: {
                                value: _vm.formValidate.totalEarn,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "totalEarn", $$v)
                                },
                                expression: "formValidate.totalEarn",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "产出次数：", prop: "earnCount" } },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "step-strictly": "",
                                precision: 0,
                                step: 1,
                                min: 0,
                                max: 99999,
                                disabled: _vm.isDisabled,
                              },
                              model: {
                                value: _vm.formValidate.earnCount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "earnCount", $$v)
                                },
                                expression: "formValidate.earnCount",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { align: "middle" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "每人限购数量（总持有）：",
                              prop: "personalBuyLimit",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "step-strictly": "",
                                precision: 0,
                                step: 1,
                                min: 0,
                                max: 99999,
                              },
                              model: {
                                value: _vm.formValidate.personalBuyLimit,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "personalBuyLimit",
                                    $$v
                                  )
                                },
                                expression: "formValidate.personalBuyLimit",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "每份增加贡献值：",
                              prop: "contribution",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "step-strictly": "",
                                precision: 2,
                                step: 0.01,
                                min: 0,
                                max: 99999,
                              },
                              model: {
                                value: _vm.formValidate.contribution,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "contribution",
                                    $$v
                                  )
                                },
                                expression: "formValidate.contribution",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { align: "middle" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "状态：", prop: "status" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.onChangeSpec(
                                      _vm.formValidate.status
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.formValidate.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "status", $$v)
                                  },
                                  expression: "formValidate.status",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  { staticClass: "radio", attrs: { label: 1 } },
                                  [_vm._v("正常")]
                                ),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("禁用"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "排序：", prop: "sort" } },
                          [
                            _c("el-input", {
                              attrs: {
                                minlength: "1",
                                maxlength: "5",
                                placeholder: "请输入排序0-99999",
                              },
                              model: {
                                value: _vm.formValidate.sort,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "sort",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "formValidate.sort",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { align: "middle" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "内容详情：", prop: "content" } },
                          [
                            _c("Tinymce", {
                              ref: "stationTinymce",
                              model: {
                                value: _vm.formValidate.content,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "content", $$v)
                                },
                                expression: "formValidate.content",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSubmit("formValidate")
                          },
                        },
                      },
                      [_vm._v("保存\n          ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }