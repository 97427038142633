"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserInfo',
  props: {
    id: {
      type: [Number, String],
      default: ''
    }
  },
  data: function data() {
    return {
      userInfo: {},
      imgError: 'this.src="' + require('../../assets/imgs/default_avatar.png') + '"'
    };
  },
  mounted: function mounted() {
    this.getUserInfo();

    // if (this.id) {
    // } else {
    //   this.$message.error('未获取到用户信息')
    // }
  },
  methods: {
    // 列表
    getUserInfo: function getUserInfo() {
      var _this = this;
      (0, _user.GetUserDetailApi)({
        id: this.id
      }).then(function (res) {
        _this.userInfo = res;
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    }
  }
};