var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox", staticStyle: { "padding-bottom": "0" } },
    [
      _vm.checkPermi(["admin:statistics:trade:data"])
        ? _c(
            "el-row",
            { staticClass: "baseInfo", attrs: { gutter: 20 } },
            _vm._l(_vm.cardLists, function (item, index) {
              return _c(
                "el-col",
                _vm._b(
                  { key: index, staticClass: "ivu-mb" },
                  "el-col",
                  _vm.grid,
                  false
                ),
                [
                  _c(
                    "el-card",
                    {
                      attrs: { bordered: false, "dis-hover": "", padding: 12 },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "acea-row row-between-wrapper" },
                        [
                          _c("div", { staticClass: "acea-row align-center" }, [
                            _c("span", { staticClass: "main_tit" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-tag", { attrs: { type: "primary" } }, [
                            _vm._v(_vm._s(item.tag)),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "span",
                          { staticClass: "content-number spBlock count_data" },
                          [_vm._v(_vm._s(item.count))]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "spBlock pt10" }, [
                          _vm._v("环比：\n              "),
                          _c(
                            "i",
                            {
                              staticClass: "content-is",
                              class: item.percent >= 0 ? "up" : "down",
                            },
                            [
                              _vm._v(
                                _vm._s(item.percent >= 0 ? "+" : "-") +
                                  _vm._s(item.percent)
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            class:
                              item.percent >= 0
                                ? "el-icon-caret-top"
                                : "el-icon-caret-bottom",
                          }),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }