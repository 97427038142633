"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _render = _interopRequireDefault(require("@/components/FormGenerator/components/render/render"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var components = {
  itemBtns: function itemBtns(h, element, index, parent) {
    var _this$$listeners = this.$listeners,
      copyItem = _this$$listeners.copyItem,
      deleteItem = _this$$listeners.deleteItem;
    return [h("span", {
      "class": 'drawing-item-copy',
      "attrs": {
        "title": '复制'
      },
      "on": {
        "click": function click(event) {
          copyItem(element, parent);
          event.stopPropagation();
        }
      }
    }, [h("i", {
      "class": 'el-icon-copy-document'
    })]), h("span", {
      "class": 'drawing-item-delete',
      "attrs": {
        "title": '删除'
      },
      "on": {
        "click": function click(event) {
          deleteItem(index, parent);
          event.stopPropagation();
        }
      }
    }, [h("i", {
      "class": 'el-icon-delete'
    })])];
  }
};
var layouts = {
  colFormItem: function colFormItem(h, element, index, parent) {
    var _this = this;
    var activeItem = this.$listeners.activeItem;
    var config = element.__config__;
    var className = this.activeId === config.formId ? 'drawing-item active-from-item' : 'drawing-item';
    if (this.formConf.unFocusedComponentBorder) className += ' unfocus-bordered';
    var labelWidth = config.labelWidth ? "".concat(config.labelWidth, "px") : null;
    if (config.showLabel === false) labelWidth = '0';
    if (config.tips == undefined) {
      this.$set(config, 'tips', false); //如果以前的表单没有tooltip配置，就赋值一个默认值用来读取
    }
    if (config.tips) {
      return h("el-col", {
        "attrs": {
          "span": config.span
        },
        "class": className,
        "nativeOn": {
          "click": function click(event) {
            activeItem(element);
            event.stopPropagation();
          }
        }
      }, [h("el-form-item", {
        "attrs": {
          "label-width": labelWidth,
          "label": config.showLabel ? config.label : '',
          "required": config.required
        }
      }, [h("el-tooltip", {
        "attrs": {
          "effect": "dark",
          "placement": "top-start"
        },
        "style": "padding:10px 5px 0 0;"
      }, ["\xA0 \xA0 \xA0 \xA0 \xA0 \xA0 ", h("i", {
        "class": "el-icon-warning-outline"
      }), "\xA0 \xA0 \xA0 \xA0 \xA0 \xA0 ", h("div", {
        "slot": "content",
        "style": "max-width:400px;"
      }, [config.tipsDesc]), "\xA0 \xA0 \xA0 \xA0 \xA0"]), h(_render.default, {
        "key": config.renderKey,
        "attrs": {
          "conf": element
        },
        "on": {
          "input": function input(event) {
            _this.$set(config, 'defaultValue', event);
          }
        }
      })]), components.itemBtns.apply(this, arguments)]);
    } else {
      return h("el-col", {
        "attrs": {
          "span": config.span
        },
        "class": className,
        "nativeOn": {
          "click": function click(event) {
            activeItem(element);
            event.stopPropagation();
          }
        }
      }, [h("el-form-item", {
        "attrs": {
          "label-width": labelWidth,
          "label": config.showLabel ? config.label : '',
          "required": config.required
        }
      }, [h(_render.default, {
        "key": config.renderKey,
        "attrs": {
          "conf": element
        },
        "on": {
          "input": function input(event) {
            _this.$set(config, 'defaultValue', event);
          }
        }
      })]), components.itemBtns.apply(this, arguments)]);
    }
  },
  rowFormItem: function rowFormItem(h, element, index, parent) {
    var activeItem = this.$listeners.activeItem;
    var className = this.activeId === element.__config__.formId ? 'drawing-row-item active-from-item' : 'drawing-row-item';
    var child = renderChildren.apply(this, arguments);
    if (element.type === 'flex') {
      child = h("el-row", {
        "attrs": {
          "type": element.type,
          "justify": element.justify,
          "align": element.align
        }
      }, [child]);
    }
    return h("el-col", {
      "attrs": {
        "span": element.__config__.span
      }
    }, [h("el-row", {
      "attrs": {
        "gutter": element.__config__.gutter
      },
      "class": className,
      "nativeOn": {
        "click": function click(event) {
          activeItem(element);
          event.stopPropagation();
        }
      }
    }, [h("span", {
      "class": 'component-name'
    }, [element.__config__.componentName]), h(_vuedraggable.default, {
      "attrs": {
        "list": element.__config__.children,
        "animation": 340,
        "group": 'componentsGroup'
      },
      "class": 'drag-wrapper'
    }, [child]), components.itemBtns.apply(this, arguments)])]);
  }
};
function renderChildren(h, element, index, parent) {
  var _this2 = this;
  var config = element.__config__;
  if (!Array.isArray(config.children)) return null;
  return config.children.map(function (el, i) {
    var layout = layouts[el.__config__.layout];
    if (layout) {
      return layout.call(_this2, h, el, i, config.children);
    }
    return layoutIsNotFound.call(_this2);
  });
}
function layoutIsNotFound() {
  throw new Error("\u6CA1\u6709\u4E0E".concat(this.element.__config__.layout, "\u5339\u914D\u7684layout"));
}
var _default = exports.default = {
  components: {
    render: _render.default,
    draggable: _vuedraggable.default
  },
  props: ['element', 'index', 'drawingList', 'activeId', 'formConf'],
  render: function render(h) {
    var layout = layouts[this.element.__config__.layout];
    if (layout) {
      return layout.call(this, h, this.element, this.index, this.drawingList);
    }
    return layoutIsNotFound.call(this);
  }
};