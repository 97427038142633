var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "用户任务明细",
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "userTaskDetail",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.userTaskDetail,
                rules: _vm.rules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户id", prop: "uid" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "用户id" },
                    model: {
                      value: _vm.userTaskDetail.uid,
                      callback: function ($$v) {
                        _vm.$set(_vm.userTaskDetail, "uid", $$v)
                      },
                      expression: "userTaskDetail.uid",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "任务类型", prop: "price" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "任务完成进度" },
                      model: {
                        value: _vm.userTaskDetail.taskType,
                        callback: function ($$v) {
                          _vm.$set(_vm.userTaskDetail, "taskType", $$v)
                        },
                        expression: "userTaskDetail.taskType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "每日激励广告任务", value: 0 },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "每日推广任务", value: 1 },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "每日直播任务", value: 2 },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "设备任务", value: 3 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "任务完成进度", prop: "taskStatus" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "任务完成进度" },
                      model: {
                        value: _vm.userTaskDetail.taskStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.userTaskDetail, "taskStatus", $$v)
                        },
                        expression: "userTaskDetail.taskStatus",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "进行中", value: 0 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "已完成", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "未完成", value: 2 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "对应任务的ID", prop: "taskId" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "对应任务的ID" },
                    model: {
                      value: _vm.userTaskDetail.taskId,
                      callback: function ($$v) {
                        _vm.$set(_vm.userTaskDetail, "taskId", _vm._n($$v))
                      },
                      expression: "userTaskDetail.taskId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "任务标题", prop: "taskTitle" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入任务标题" },
                    model: {
                      value: _vm.userTaskDetail.taskTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.userTaskDetail, "taskTitle", _vm._n($$v))
                      },
                      expression: "userTaskDetail.taskTitle",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "奖励金额", prop: "number" } },
                [
                  _c("el-input-number", {
                    staticClass: "priceBox",
                    attrs: { size: "small", min: 0, precision: 2, step: 0.1 },
                    model: {
                      value: _vm.userTaskDetail.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.userTaskDetail, "number", $$v)
                      },
                      expression: "userTaskDetail.number",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "余额", prop: "balance" } },
                [
                  _c("el-input-number", {
                    staticClass: "priceBox",
                    attrs: { size: "small", min: 0, precision: 2, step: 0.1 },
                    model: {
                      value: _vm.userTaskDetail.balance,
                      callback: function ($$v) {
                        _vm.$set(_vm.userTaskDetail, "balance", $$v)
                      },
                      expression: "userTaskDetail.balance",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "奖励类型", prop: "rewardType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "任务完成进度" },
                      model: {
                        value: _vm.userTaskDetail.rewardType,
                        callback: function ($$v) {
                          _vm.$set(_vm.userTaskDetail, "rewardType", $$v)
                        },
                        expression: "userTaskDetail.rewardType",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "积分", value: 0 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "活跃度", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "门票", value: 2 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("userTaskDetail")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["admin:task:userTaskDetail:update"],
                      expression: "['admin:task:userTaskDetail:update']",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formValidate")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }