var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "meQrcode", title: _vm.qrText } }, [
    _c("div", { staticClass: "qrcode_box" }, [
      _c("img", {
        ref: "qrcode_canvas",
        staticClass: "qrcode_canvas",
        attrs: { id: "qrcode_canvas", alt: "二维码" },
      }),
      _vm._v(" "),
      _vm.qrLogo
        ? _c("img", {
            ref: "qrcode_logo",
            staticClass: "qrcode_logo",
            attrs: { src: _vm.qrLogo, alt: "logo" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("canvas", {
        ref: "canvas",
        staticClass: "canvas",
        attrs: { width: _vm.qrSize, height: _vm.qrSize, id: "canvas" },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }