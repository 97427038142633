"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _assist = require("@/api/assist");
var _validate = require("@/utils/validate");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      commonEnums: this.$commonEnums,
      tableForm: {
        startDate: "",
        endDate: "",
        keyWords: "",
        busType: "",
        page: 1,
        limit: 20
      },
      timeVal: [],
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    reset: function reset() {
      this.tableForm.startDate = "";
      this.tableForm.endDate = "";
      this.timeVal = [];
      this.tableForm.busType = "";
      this.tableForm.keyWords = "";
      this.tableForm.productName = "";
      this.tableForm.page = 1;
      this.getList();
    },
    onClickTab: function onClickTab() {
      this.tableForm.page = 1;
      this.getList();
    },
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _assist.ticketListApi)(this.tableForm).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableForm.startDate = e ? this.timeVal[0] : "";
      this.tableForm.endDate = e ? this.timeVal[1] : "";
      this.tableForm.page = 1;
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    },
    // 导出
    exports: function exports() {
      var exportParams = JSON.parse(JSON.stringify(this.tableForm));
      exportParams.page = 1;
      exportParams.limit = 100 * 10000;
      (0, _assist.ticketRecordExportApi)(exportParams).then(function (res) {
        window.location.href = res.fileName;
      });
    }
  }
};