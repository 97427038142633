"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var articleApi = _interopRequireWildcard(require("@/api/article.js"));
var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
var _edit = _interopRequireDefault(require("./edit"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "list",
  components: {
    edit: _edit.default
  },
  data: function data() {
    return {
      constants: this.$constants,
      listPram: {
        keywords: null,
        cid: null,
        page: 1,
        limit: this.$constants.page.limit[0]
      },
      listData: {
        list: [],
        total: 0
      },
      editDialogConfig: {
        visible: false,
        data: {},
        isEdit: 0 // 0=add 1=edit
      },
      listLoading: true,
      categoryTreeData: [],
      categoryProps: {
        value: "id",
        label: "name",
        children: "child",
        expandTrigger: "hover",
        checkStrictly: true,
        emitPath: false
      }
    };
  },
  mounted: function mounted() {
    this.handlerGetListData(this.listPram);
    this.handlerGetTreeList();
  },
  methods: {
    handlerGetTreeList: function handlerGetTreeList() {
      var _this = this;
      categoryApi.listCategroy({
        type: 3,
        status: ""
      }).then(function (data) {
        _this.categoryTreeData = data.list;
        localStorage.setItem("articleClass", JSON.stringify(data.list));
      });
    },
    handerSearch: function handerSearch() {
      this.listPram.page = 1;
      this.handlerGetListData(this.listPram);
    },
    handlerGetListData: function handlerGetListData(pram) {
      var _this2 = this;
      this.listLoading = true;
      articleApi.ListArticle(pram).then(function (data) {
        _this2.listData = data;
        _this2.listLoading = false;
      });
    },
    handlerOpenEdit: function handlerOpenEdit(isEdit, editData) {
      // 0=add 1=edit
      if (isEdit === 1) {
        this.editDialogConfig.isEdit = 1;
        this.editDialogConfig.editData = editData;
      } else {
        this.editDialogConfig.isEdit = 0;
      }
      this.editDialogConfig.visible = true;
    },
    // handlerGetCategoryTreeData() {
    //   const _pram = { type: constants.categoryType[2].value, status: 1 }
    //   categoryApi.treeCategroy(_pram).then(data => {
    //     this.categoryTreeData = selfUtil.addTreeListLabelForCasCard(data)
    //   })
    // },
    handlerHideDialog: function handlerHideDialog() {
      this.handlerGetListData(this.listPram);
      this.editDialogConfig.visible = false;
    },
    handlerDelete: function handlerDelete(rowData) {
      var _this3 = this;
      this.$confirm("确定删除当前数据", "提示").then(function (result) {
        articleApi.DelArticle(rowData).then(function (data) {
          _this3.$message.success("删除数据成功");
          _this3.handlerGetListData(_this3.listPram);
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetListData(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetListData(this.listPram);
    }
  }
};