"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/echartsNew/index"));
var _dashboard = require("@/api/dashboard");
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  components: {
    echartsNew: _index.default
  },
  data: function data() {
    return {
      grid: {
        xl: 3,
        lg: 3,
        md: 6,
        sm: 8,
        xs: 8
      },
      nav_list: [{
        bgColor: "#1890FF",
        icon: "iconhuiyuanguanli",
        title: "用户管理",
        url: "/user/index",
        perms: ['admin:user:list']
      }, {
        bgColor: "#1BBE6B",
        icon: "iconxitongshezhi",
        title: "系统设置",
        url: "/operation/setting",
        perms: ['admin:system:config:info']
      }, {
        bgColor: "#EF9C20",
        icon: "iconshangpinguanli",
        title: "商品管理",
        url: "/store/index",
        perms: ['admin:product:list']
      }, {
        bgColor: "#F56C6C",
        icon: "icondingdanguanli",
        title: "订单管理",
        url: "/order/index",
        perms: ['admin:order:list']
      },
      // {
      //   bgColor:"#A277FF",
      //   icon:"iconduanxinpeizhi",
      //   title: "一号通",
      //   url: "/operation/onePass",
      //   perms:['admin:pass:login']
      // },
      {
        bgColor: "#E8B600",
        icon: "iconwenzhangguanli",
        title: "文章管理",
        url: "/content/articleManager",
        perms: ['admin:article:list']
      }
      // {
      //   bgColor:"#4BCAD5",
      //   icon:"iconfenxiaoguanli",
      //   title: "分销管理",
      //   url: "/distribution/index",
      //   perms:['admin:retail:list']
      // },
      // {
      //   bgColor:"#1890FF",
      //   icon:"iconyouhuiquan",
      //   title: "优惠券",
      //   url: "/marketing/coupon/list",
      //   perms:['admin:coupon:list']
      // },
      ],
      statisticData: [{
        title: '待发货订单',
        num: 0,
        path: '/order/index',
        perms: ['admin:order:list']
      }, {
        title: '退款中订单',
        num: 0,
        path: '/order/index',
        perms: ['admin:order:list']
      }, {
        title: '待核销订单',
        num: 0,
        path: '/order/index',
        perms: ['admin:order:list']
      }, {
        title: '库存预警',
        num: 0,
        path: '/store/index',
        perms: ['admin:product:list']
      }, {
        title: '上架商品',
        num: 0,
        path: '/store/index',
        perms: ['admin:product:list']
      }, {
        title: '仓库商品',
        num: 0,
        path: '/store/index',
        perms: ['admin:product:list']
      }, {
        title: '提现待审核',
        num: 0,
        path: '/financial/commission/template',
        perms: ['admin:finance:apply:list']
      }, {
        title: '账户充值',
        num: 0,
        path: '/financial/record/charge',
        perms: ['admin:recharge:list']
      }],
      optionData: {},
      applyNum: 0,
      style: {
        height: "250px"
      }
    };
  },
  computed: {
    //鉴权处理
    permList: function permList() {
      var _this = this;
      var arr = [];
      this.nav_list.forEach(function (item) {
        if (_this.checkPermi(item.perms)) {
          arr.push(item);
        }
      });
      return arr;
    },
    businessList: function businessList() {
      var _this2 = this;
      var arr = [];
      this.statisticData.forEach(function (item) {
        if (_this2.checkPermi(item.perms)) {
          arr.push(item);
        }
      });
      return arr;
    }
  },
  mounted: function mounted() {
    this.getbusinessData();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    navigatorTo: function navigatorTo(path) {
      this.$router.push(path);
    },
    getbusinessData: function getbusinessData() {
      var _this3 = this;
      (0, _dashboard.businessData)().then(function (res) {
        _this3.statisticData[0].num = res.notShippingOrderNum; //待发货订单
        _this3.statisticData[1].num = res.refundingOrderNum; //退款中订单
        _this3.statisticData[2].num = res.notWriteOffOrderNum; //待核销订单
        _this3.statisticData[3].num = res.vigilanceInventoryNum; //库存预警
        _this3.statisticData[4].num = res.onSaleProductNum; //上架商品
        _this3.statisticData[5].num = res.notSaleProductNum; //仓库商品
        _this3.statisticData[6].num = res.notAuditNum; //提现待审核
        _this3.statisticData[7].num = res.totalRechargeAmount; //账户充值
      });
    }
  }
};