"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _visualization = _interopRequireDefault(require("./components/visualization"));
var _tableData = _interopRequireDefault(require("./components/tableData"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    product: _visualization.default,
    tableData: _tableData.default
  }
};