"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _permission = require("@/utils/permission");
var _toDay = _interopRequireDefault(require("./components/toDay"));
var _tableData = _interopRequireDefault(require("./components/tableData"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  components: {
    toData: _toDay.default,
    tableData: _tableData.default
  },
  data: function data() {
    return {
      list: []
    };
  },
  mounted: function mounted() {},
  methods: {
    checkPermi: _permission.checkPermi
  }
};