"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _statistic = require("@/api/statistic");
var _utils = require("@/utils");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 权限判断函数

var yesterday = new Date(new Date().toLocaleDateString()).getTime() - 3600 * 24 * 1000;
var sevenDays = new Date(new Date().toLocaleDateString()).getTime() - 7 * 3600 * 24 * 1000;
var thirtyDays = new Date(new Date().toLocaleDateString()).getTime() - 30 * 3600 * 24 * 1000;
var _default = exports.default = {
  data: function data() {
    return {
      dateLimit: 'lately7',
      // ``  lately7 lately30
      fromList: this.$constants.timeList,
      timeVal: [],
      tableForm: {
        startTime: '',
        endTime: '',
        page: 1,
        limit: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  created: function created() {
    // const end = new Date();
    // const start = new Date();
    // start.setTime(
    //   start.setTime(
    //     new Date(
    //       new Date().getFullYear(),
    //       new Date().getMonth(),
    //       new Date().getDate() - 29
    //     )
    //   )
    // );
    // this.timeVal = [start, end];
  },
  mounted: function mounted() {
    // this.getList();
    this.selectChange('lately7');
  },
  methods: {
    checkPermi: _permission.checkPermi,
    search: function search() {
      this.getList();
    },
    onchangeTime: function onchangeTime(e) {
      this.tableForm.startTime = e ? this.timeVal[0] : '';
      this.tableForm.endTime = e ? this.timeVal[1] : '';
      this.tableForm.page = 1;
    },
    selectChange: function selectChange(v) {
      console.log(v, 'vvvv');
      if (v == 'lately7') {
        this.timeVal = [(0, _utils.formatDates)(new Date(sevenDays), 'yyyy-MM-dd'), (0, _utils.formatDates)(new Date(), 'yyyy-MM-dd')];
      } else if (v == 'lately30') {
        this.timeVal = [(0, _utils.formatDates)(new Date(thirtyDays), 'yyyy-MM-dd'), (0, _utils.formatDates)(new Date(), 'yyyy-MM-dd')];
      } else {
        this.timeVal = [(0, _utils.formatDates)(new Date(yesterday), 'yyyy-MM-dd'), (0, _utils.formatDates)(new Date(yesterday), 'yyyy-MM-dd')];
      }
      this.onchangeTime(this.timeVal);
      this.search();
    },
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _statistic.IntegralListStatisticsApi)(this.tableForm).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    },
    // 导出
    exports: function exports() {
      var exportParams = JSON.parse(JSON.stringify(this.tableForm));
      exportParams.page = 1;
      exportParams.limit = 100 * 10000;
      (0, _statistic.IntegralListDownloadApi)(exportParams).then(function (res) {
        window.location.href = res.fileName;
      });
    }
  }
};