"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _statisticsData = _interopRequireDefault(require("../components/statisticsData"));
var _Loading = _interopRequireDefault(require("../components/Loading"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "OrderIndex",
  components: {
    Loading: _Loading.default,
    statisticsData: _statisticsData.default
  },
  props: {},
  data: function data() {
    return {
      census: [],
      list: [],
      where: {
        page: 1,
        limit: 10
      },
      loaded: false,
      loading: false
    };
  },
  created: function created() {
    Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/assets/js/media_750'));
    });
  },
  mounted: function mounted() {
    var _this = this;
    this.getIndex();
    this.getList();
    this.$scroll(this.$refs.container, function () {
      !_this.loading && _this.getList();
    });
  },
  methods: {
    getIndex: function getIndex() {
      var _this2 = this;
      (0, _order.orderStatisticsApi)().then(function (res) {
        _this2.census = res;
      }, function (err) {
        _this2.$dialog.message(err.message);
      });
    },
    getList: function getList() {
      var _this3 = this;
      if (this.loading || this.loaded) return;
      this.loading = true;
      (0, _order.statisticsDataApi)(this.where).then(function (res) {
        _this3.loading = false;
        _this3.loaded = res.length < _this3.where.limit;
        _this3.list.push.apply(_this3.list, res);
        _this3.where.page = _this3.where.page + 1;
      }, function (error) {
        _this3.$dialog.message(error.message);
      }, 300);
    }
  }
};