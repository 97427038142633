"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _wxApi = require("@/api/wxApi");
var _systemConfig = require("@/api/systemConfig");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      iframeUrl: ""
    };
  },
  mounted: function mounted() {
    this.getUrl();
  },
  methods: {
    downCode: function downCode() {
      (0, _wxApi.wechatCodeDownload)().then(function (res) {
        window.open(res);
      });
    },
    getUrl: function getUrl() {
      var _this = this;
      (0, _systemConfig.configGetUniq)({
        key: 'site_url'
      }).then(function (res) {
        _this.iframeUrl = res;
      });
    }
  }
};