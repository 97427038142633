var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.JavaInfo && _vm.JavaInfo.account == "admin"
      ? _c(
          "div",
          [
            _vm.checkPermi(["admin:statistics:home:index"])
              ? _c("base-info", { ref: "baseInfo" })
              : _vm._e(),
            _vm._v(" "),
            _c("grid-menu", { staticClass: "mb20" }),
            _vm._v(" "),
            _c("user-overview"),
            _vm._v(" "),
            _c("visit-chart", { ref: "visitChart" }),
            _vm._v(" "),
            _vm.checkPermi(["admin:statistics:home:chart:user"])
              ? _c("user-chart", { ref: "userChart", staticClass: "mb20" })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.JavaInfo && _vm.JavaInfo.account != "admin"
      ? _c("div", { staticClass: "detail-info" }, [
          _c("div", { staticClass: "detail-info-tips" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.JavaInfo.realName) +
                "，欢迎使用星蒙云系统\n    "
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }