"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("../utils/index");
var _clipboard = _interopRequireDefault(require("clipboard"));
var _fileSaver = require("file-saver");
var _loadMonaco = _interopRequireDefault(require("../utils/loadMonaco"));
var _loadBeautifier = _interopRequireDefault(require("../utils/loadBeautifier"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import * as monaco from "monaco-editor";

var beautifier;
var monaco;
var _default = exports.default = {
  components: {},
  props: {
    jsonStr: {
      type: String,
      required: true,
      beautifier: null,
      jsonEditor: null
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    window.addEventListener('keydown', this.preventDefaultSave);
    var clipboard = new _clipboard.default('.copy-json-btn', {
      text: function text(trigger) {
        _this.$notify({
          title: '成功',
          message: '代码已复制到剪切板，可粘贴。',
          type: 'success'
        });
        return _this.beautifierJson;
      }
    });
    clipboard.on('error', function (e) {
      _this.$message.error('代码复制失败');
    });
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('keydown', this.preventDefaultSave);
  },
  methods: {
    preventDefaultSave: function preventDefaultSave(e) {
      if (e.key === 's' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
      }
    },
    onOpen: function onOpen() {
      var _this2 = this;
      (0, _loadBeautifier.default)(function (btf) {
        beautifier = btf;
        _this2.beautifierJson = beautifier.js(_this2.jsonStr, _index.beautifierConf.js);
        (0, _loadMonaco.default)(function (val) {
          monaco = val;
          _this2.setEditorValue('editorJson', _this2.beautifierJson);
        });
      });
      // monaco.editor.create(document.getElementById("editorJson"), {
      //   value: [
      //     "function x() {",
      //     '\tconsole.integralLog("Hello world!");',
      //     "}"].join(
      //     "\n"
      //   ),
      //   language: "javascript",
      //   theme: 'vs-dark',
      //   automaticLayout: true
      // });
    },
    onClose: function onClose() {},
    setEditorValue: function setEditorValue(id, codeStr) {
      var _this3 = this;
      if (this.jsonEditor) {
        this.jsonEditor.setValue(codeStr);
      } else {
        this.jsonEditor = monaco.editor.create(document.getElementById(id), {
          value: codeStr,
          theme: 'vs-dark',
          language: 'json',
          automaticLayout: true
        });
        // ctrl + s 刷新
        this.jsonEditor.onKeyDown(function (e) {
          if (e.keyCode === 49 && (e.metaKey || e.ctrlKey)) {
            _this3.refresh();
          }
        });
      }
    },
    exportJsonFile: function exportJsonFile() {
      var _this4 = this;
      this.$prompt('文件名:', '导出文件', {
        inputValue: "".concat(+new Date(), ".json"),
        closeOnClickModal: false,
        inputPlaceholder: '请输入文件名'
      }).then(function (_ref) {
        var value = _ref.value;
        if (!value) value = "".concat(+new Date(), ".json");
        var codeStr = _this4.jsonEditor.getValue();
        var blob = new Blob([codeStr], {
          type: 'text/plain;charset=utf-8'
        });
        (0, _fileSaver.saveAs)(blob, value);
      });
    },
    refresh: function refresh() {
      try {
        this.$emit('refresh', JSON.parse(this.jsonEditor.getValue()));
      } catch (error) {
        this.$notify({
          title: '错误',
          message: 'JSON格式错误，请检查',
          type: 'error'
        });
      }
    }
  }
};