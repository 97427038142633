"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Parser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/Parser"));
var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));
var logistics = _interopRequireWildcard(require("@/api/logistics.js"));
var _validate = require("@/utils/validate");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CityList',
  components: {
    parser: _Parser.default
  },
  data: function data() {
    return {
      // 表单
      formConf: {
        fields: []
      },
      // 生成表单id
      formId: 70,
      tableData: [],
      // 上级名称
      parentName: '中国',
      // 父级城市id
      parentId: 0,
      loading: false,
      listLoading: true,
      dialogVisible: false,
      // 选中id
      editId: 0,
      formShow: false,
      formData: {},
      isCreate: 0,
      isShowTable: true
    };
  },
  created: function created() {
    this.getCityList();
  },
  methods: {
    // 获取城市数据
    getCityList: function getCityList() {
      var _this = this;
      this.listLoading = true;
      logistics.cityList({
        parentId: this.parentId
      }).then(function (data) {
        _this.listLoading = false;
        var arr = [];
        data.forEach(function (item) {
          var str = {};
          str = item;
          str.hasChildren = true;
          str.parentName = '中国';
          arr.push(str);
        });
        _this.tableData = arr;
      });
    },
    // 状态
    cityStatus: function cityStatus(e) {
      var _this2 = this;
      logistics.updateStatus({
        id: e.id,
        cityId: e.cityId,
        status: e.isShow
      }).then(function (res) {
        _this2.$message.success('操作成功');
      }).catch(function () {
        e.isShow = !e.isShow;
      });
    },
    // 编辑
    editCity: function editCity(item) {
      var _this3 = this;
      this.$confirm('请勿频繁修改此配置项', '提示', {
        confirmButtonText: '确定修改',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.editId = item.id;
        _this3.parentId = item.parentId;
        var _pram = {
          id: _this3.formId
        };
        systemFormConfigApi.getFormConfigInfo(_pram).then(function (data) {
          _this3.formShow = false;
          _this3.isCreate = 0;
          _this3.getCityInfo();
          _this3.dialogVisible = true;
          _this3.formConf = JSON.parse(data.content);
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 详情
    getCityInfo: function getCityInfo() {
      var _this4 = this;
      logistics.cityInfo({
        id: this.editId
      }).then(function (res) {
        _this4.isCreate = 1;
        _this4.formData = res;
        _this4.formData.parentName = _this4.parentName;
        setTimeout(function () {
          // 让表单重复渲染待编辑数据
          _this4.formShow = true;
        }, 80);
      });
    },
    submit: (0, _validate.Debounce)(function (data) {
      var _this5 = this;
      var param = {
        id: this.editId,
        parentId: this.parentId,
        name: data.name
      };
      logistics.cityUpdate(param).then(function (res) {
        _this5.$message.success('修改成功');
        _this5.dialogVisible = false;
        _this5.parentId = 0;
        _this5.isShowTable = false;
        _this5.getCityList();
        _this5.$nextTick(function () {
          _this5.isShowTable = true;
        });
      });
    }),
    //  关闭模态框
    handleClose: function handleClose(done) {
      this.formConf.fields = [];
      this.dialogVisible = false;
    },
    load: function load(tree, treeNode, resolve) {
      var _this6 = this;
      logistics.cityList({
        parentId: tree.cityId
      }).then(function (data) {
        var arr = [];
        data.forEach(function (item) {
          var str = {};
          str = item;
          str.hasChildren = true; //给每个省市节点赋一个可展开的值
          str.parentName = tree.name;
          _this6.parentName = tree.name;
          if (item.level == 2) {
            str.hasChildren = false; //当节点为三级时，不可展开
          }
          arr.push(str);
        });
        resolve(arr);
      });
    }
  }
};