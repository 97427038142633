var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-card", { staticClass: "box-card" }, [
        _vm.item && _vm.item.id
          ? _c("div", { staticClass: "container mt-1 text-center" }, [
              _c("div", { staticClass: "sign-content" }, [
                _c("div", { staticClass: "sign-content-cell" }, [
                  _c("span", [_vm._v("汇款账户")]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.item.accountName))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sign-content-cell" }, [
                  _c("span", [_vm._v("汇款账号")]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.item.accountNum))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sign-content-cell" }, [
                  _c("span", [_vm._v("充值金额")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.item.price) +
                        "   |   " +
                        _vm._s(
                          _vm._f("rechargeTypeFilter")(_vm.item.rechargeType)
                        )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sign-content-cell" }, [
                  _c("span", [_vm._v("汇款凭证")]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "sign-img",
                    attrs: { src: _vm.item.proof },
                    on: { onerror: _vm.imgError },
                  }),
                ]),
                _vm._v(" "),
                _vm.viewType == "view"
                  ? _c("div", { staticClass: "sign-content-cell" }, [
                      _c("span", [_vm._v("备注")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.item.remark || "-"))]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sign-btn" },
                [
                  _vm.viewType == "edit" &&
                  _vm.checkPermi(["admin:recharge:examine"])
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["admin:recharge:examine"],
                              expression: "['admin:recharge:examine']",
                            },
                          ],
                          staticClass: "mr10",
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.hander("process")
                            },
                          },
                        },
                        [_vm._v("审核通过")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.viewType == "edit" &&
                  _vm.checkPermi(["admin:recharge:examine"])
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["admin:recharge:examine"],
                              expression: "['admin:recharge:examine']",
                            },
                          ],
                          staticClass: "mr10",
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.hander("no-process")
                            },
                          },
                        },
                        [_vm._v("审核不通过")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.hander("close")
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }