var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: "", size: "small" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户昵称：" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "请输入用户昵称",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.tableFrom.nickname,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "nickname", $$v)
                              },
                              expression: "tableFrom.nickname",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户ID：" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "请输入用户ID",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.tableFrom.uid,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "uid", $$v)
                              },
                              expression: "tableFrom.uid",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "任务类型：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "任务类型" },
                              model: {
                                value: _vm.tableFrom.taskType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "taskType", $$v)
                                },
                                expression: "tableFrom.taskType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "每日激励广告任务", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "每日推广任务", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "每日直播任务", value: 2 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "设备任务", value: 3 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "完成时间：" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "直推人：" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "请输入直推人",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.tableFrom.inviterPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "inviterPhone", $$v)
                              },
                              expression: "tableFrom.inviterPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖励类型：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "奖励类型" },
                              model: {
                                value: _vm.tableFrom.rewardType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "rewardType", $$v)
                                },
                                expression: "tableFrom.rewardType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "余额", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "活跃度", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "门票", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.getList },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "编号", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "nickname",
                  label: "用户昵称",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "uid", label: "用户id", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "任务类型", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("userTaskTypeTypeFilter")(row.taskType)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "taskTitle",
                  label: "任务名称",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sn", label: "SN", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "完成状态", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("userTaskStatusTypeFilter")(row.taskStatus)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createDay",
                  label: "完成时间",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "inviterPhone",
                  label: "直推人",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "奖励类型", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("userRewardTypeTypeFilter")(row.rewardType)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }