"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _statistic = require("@/api/statistic");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {
      timeVal: [],
      dateLimit: '',
      options: [{
        value: 'pageviews',
        label: '浏览量'
      }, {
        value: 'collectNum',
        label: '收藏数'
      }, {
        value: 'addCartNum',
        label: '加购件数'
      }, {
        value: 'salesNum',
        label: '销量'
      }, {
        value: 'salesAmount',
        label: '销售额'
      }],
      value: 'pageviews',
      columns1: [{
        title: '浏览量',
        key: 'pageView',
        sortable: true
      }, {
        title: '收藏数量',
        key: 'collectNum',
        sortable: true
      }, {
        title: '加购件数',
        key: 'addCartNum',
        sortable: true
      }, {
        title: '销量',
        key: 'salesNum',
        sortable: true
      }, {
        title: '销售额',
        key: 'salesAmount',
        sortable: true
      }],
      pickerOptions: this.$timeOptions,
      LogLoading: false,
      tableData: [],
      limit: 20,
      currentPage: 1
    };
  },
  created: function created() {
    var end = new Date();
    var start = new Date();
    start.setTime(start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 29)));
    this.timeVal = [start, end];
    // this.dataTime = formatDate(start, "yyyy/MM/dd") + "-" + formatDate(end, "yyyy/MM/dd");
  },
  mounted: function mounted() {
    this.dateLimit = 'lately30';
    this.getRankData();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    search: function search() {
      this.getRankData();
    },
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.dateLimit = e ? this.timeVal.join(',') : '';
    },
    getRankData: function getRankData() {
      var _this = this;
      this.LogLoading = true;
      var data = {
        dateLimit: this.dateLimit,
        limit: this.limit,
        page: this.currentPage,
        sortKey: this.value
      };
      (0, _statistic.productRankApi)(data).then(function (res) {
        _this.tableData = res;
        _this.LogLoading = false;
      }).catch(function () {
        _this.LogLoading = false;
      });
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.limit = val;
      this.getRankData();
    },
    pageChangeLog: function pageChangeLog(val) {
      this.currentPage = val;
      this.getRankData();
    }
  }
};