var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "container mt-1" },
          [
            _c(
              "el-form",
              {
                ref: "formValidate",
                staticClass: "formValidate mt20",
                attrs: {
                  rules: _vm.ruleValidate,
                  model: _vm.formValidate,
                  "label-width": "100px",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "位置：", prop: "type" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "selWidth",
                        attrs: { placeholder: "位置" },
                        model: {
                          value: _vm.formValidate.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "type", $$v)
                          },
                          expression: "formValidate.type",
                        },
                      },
                      _vm._l(_vm.typeList, function (item, key) {
                        return _c("el-option", {
                          key: key,
                          attrs: { label: item.value, value: item.key },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "标题：", prop: "title" } },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "249", placeholder: "请输入标题" },
                      model: {
                        value: _vm.formValidate.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "title", $$v)
                        },
                        expression: "formValidate.title",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "图文封面",
                      prop: "imageInput",
                      rules: [
                        {
                          required: true,
                          message: "请上传图文封面",
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "upLoadPicBox",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap("1")
                          },
                        },
                      },
                      [
                        _vm.formValidate.imageInput
                          ? _c("div", { staticClass: "pictrue" }, [
                              _c("img", {
                                attrs: { src: _vm.formValidate.imageInput },
                              }),
                            ])
                          : _c("div", { staticClass: "upLoad" }, [
                              _c("i", {
                                staticClass: "el-icon-camera cameraIconfont",
                              }),
                            ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "状态：", prop: "status" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.onChangeSpec(_vm.formValidate.status)
                          },
                        },
                        model: {
                          value: _vm.formValidate.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "status", $$v)
                          },
                          expression: "formValidate.status",
                        },
                      },
                      [
                        _c(
                          "el-radio",
                          { staticClass: "radio", attrs: { label: false } },
                          [_vm._v("启用")]
                        ),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: true } }, [
                          _vm._v("禁用"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "内容详情：", prop: "content" } },
                  [
                    _c("Tinymce", {
                      ref: "stationTinymce",
                      model: {
                        value: _vm.formValidate.content,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "content", $$v)
                        },
                        expression: "formValidate.content",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSubmit("formValidate")
                          },
                        },
                      },
                      [_vm._v("保存\n          ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }