"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _authInformation = require("../../../api/authInformation.js");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "AuthenticationList",
  components: {},
  data: function data() {
    return {
      taskInfo: {},
      tableFrom: {
        keywords: '',
        // 用户实名状态：1=认证成功；2=审核中；3=系统操作 5.认证失败
        status: '',
        page: 1,
        limit: 20
      },
      authStatusList: [{
        value: '',
        label: '全部'
      }, {
        value: '0',
        label: '未认证'
      }, {
        value: '1',
        label: '认证成功'
      }, {
        value: '2',
        label: '审核中'
      }, {
        value: '3',
        label: '系统操作'
      }, {
        value: '5',
        label: '认证失败'
      }],
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  mounted: function mounted() {
    _permission.checkPermi, this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _authInformation.userRealnameList)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    }
  }
};