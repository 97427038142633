"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _validate = require("@/utils/validate");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: "EditBusType",
  props: {
    item: {
      type: Object,
      default: {}
    }
  },
  components: {},
  data: function data() {
    return {
      commonEnums: this.$commonEnums,
      modal_loading: false,
      userBusTypeForm: {
        uid: null,
        level: null,
        busType: null
        // levelRate: null,
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getInfo();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    getInfo: function getInfo() {
      this.userBusTypeForm = {
        uid: this.item.uid,
        level: +this.item.level || null,
        busType: +this.item.busType || 0
        // levelRate: (+this.item.levelRate || 0) * 100 || null,
      };
      console.log(this.userBusTypeForm);
    },
    hander: (0, _validate.Debounce)(function (type) {
      var _this = this;
      if (type == "close") {
        this.$emit("close", true);
      }
      if (type == "save") {
        this.$refs["userBusTypeForm"].validate(function (valid) {
          if (!valid) return;
          _this.$confirm("\u786E\u8BA4\u4FEE\u6539\u7528\u6237\u7C7B\u578B?", "提示").then(function () {
            var queryParams = JSON.parse(JSON.stringify(_this.userBusTypeForm));
            // queryParams.levelRate = this.userBusTypeForm.levelRate / 100;
            (0, _user.editUserBusTypeApi)(queryParams).then(function (data) {
              _this.$message.success("操作成功");
              _this.$emit("submit", true);
            });
          });
        });
      }
    })
  }
};