"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "SelectCommodity",
  props: {},
  data: function data() {
    return {
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableForm: {
        page: 1,
        limit: 20,
        cateId: '',
        keywords: '',
        type: '1',
        productType: 2 //1-商城商品、2-签到商品
      },
      // merCateList: [],

      // 分类
      // props: {
      //   children: 'child',
      //   label: 'name',
      //   value: 'id',
      //   emitPath: false
      // },
      // 选中
      // selectionList: [],
      // modal_loading: false,

      // 单选
      templateRadio: 0
    };
  },
  mounted: function mounted() {
    this.init();
    this.getList();
    // this.getCategorySelect()
  },
  methods: {
    init: function init() {
      this.tableForm.page = 1;
      this.tableForm.cateId = '';
      this.tableForm.keywords = '';
      this.selectionList = [];
    },
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _store.productLstApi)(this.tableForm).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        _this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    },
    // 分类；
    // getCategorySelect() {
    //   categoryApi({
    //     status: -1,
    //     type: 1
    //   }).then(res => {
    //     this.merCateList = res
    //   }).catch(res => {
    //     this.$message.error(res.message)
    //   })
    // },
    //搜索
    seachList: function seachList() {
      this.tableForm.page = 1;
      this.getList();
    },
    // 全选
    onSelectTab: function onSelectTab(selection) {
      this.selectionList = selection;
    },
    // 提交
    // handleSubmit: Debounce(function (name) {
    //   if (!this.selectionList || this.selectionList.length == 0) {
    //     return this.$message.error('请选择商品~')
    //   }
    //   if (this.selectionList.length != 1) {
    //     return this.$message.error('请选择单个商品~')
    //   }
    //   this.modal_loading = true
    //   this.$confirm('确认提交打卡商品', '提示').then(() => {
    //     setMustBuyApi(this.selectionList[0].id).then(async res => {
    //       this.$message.success('设置成功');
    //       this.$emit('handleCloseMod', false)
    //       this.modal_loading = false
    //     }).catch(() => {
    //       this.modal_loading = false
    //     })
    //   })
    // }),
    getTemplateRow: function getTemplateRow(row) {
      this.$emit('handleCloseMod', row);
    }
  }
};