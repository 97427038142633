"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _financial = require("@/api/financial");
var _validate = require("@/utils/validate");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: "ChargeContract",
  props: {
    viewType: {
      type: String,
      default: "view"
    },
    item: {
      type: Object,
      default: {}
    }
  },
  components: {},
  data: function data() {
    return {
      datas: {},
      modal_loading: false,
      imgError: 'this.src="' + require("../../../../assets/imgs/default_avatar.png") + '"'
    };
  },
  created: function created() {},
  mounted: function mounted() {
    if (this.$route.params.id) {
      this.getInfo();
    }
  },
  methods: {
    checkPermi: _permission.checkPermi,
    getInfo: function getInfo() {
      this.datas = {};
    },
    hander: (0, _validate.Debounce)(function (type) {
      var _this = this;
      if (type == "close") {
        this.$emit("close", true);
      }
      if (type == "process") {
        this.$confirm("\u786E\u8BA4".concat(type == "process" ? "审核通过" : "审核不通过", "?"), "提示").then(function () {
          if (type == "process") {
            (0, _financial.rechargePassApi)({
              id: _this.item.id
            }).then(function (data) {
              _this.$message.success("操作成功");
              _this.$emit("submit", true);
            });
          }
        });
      }
      // 拆分-审核不通过需备注
      if (type == "no-process") {
        this.$prompt("审核不通过", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputErrorMessage: "请输入审核备注",
          inputType: "textarea",
          inputValue: this.item.remark || '',
          inputPlaceholder: "请输入审核备注",
          inputValidator: function inputValidator(value) {
            if (!value) return "输入不能为空";
          }
        }).then(function (_ref) {
          var value = _ref.value;
          (0, _financial.rechargeUnPassApi)({
            id: _this.item.id,
            remark: value
          }).then(function (data) {
            _this.$message.success("操作成功");
            _this.$emit("submit", true);
          });
        }).catch(function () {
          _this.$message.info("取消输入");
        });
      }
    })
  }
};