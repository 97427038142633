var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.onClickTab },
              model: {
                value: _vm.tabType,
                callback: function ($$v) {
                  _vm.tabType = $$v
                },
                expression: "tabType",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "基本信息", name: "N" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "订单记录", name: "D" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabType == "N",
                  expression: "tabType == 'N'",
                },
              ],
              staticClass: "container mt-1",
            },
            [
              _c(
                "el-form",
                {
                  attrs: { "label-width": "120px" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "门店名称：" } },
                            [_c("div", [_vm._v("谭百万烤鸡铺")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "联联订单号：" } },
                            [_c("div", [_vm._v("202201030857591254")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "订单号：" } }, [
                            _c("div", [_vm._v("test_202212060001")]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "订单创建时间：" } },
                            [_c("div", [_vm._v("2021-11-29  15:30:20")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户姓名：" } },
                            [_c("div", [_vm._v("王大力")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品名称：" } },
                            [
                              _c("div", [
                                _vm._v("【免预约】长沙三毛烧烤虾蟹全套餐"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "数量：" } }, [
                            _c("div", [_vm._v("1")]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "已取码数量：" } },
                            [_c("div", [_vm._v("1")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "详情链接：" } },
                            [
                              _c("div", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "https://qd.llzby.vip/s/KahZFMeJ",
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v("https://qd.llzby.vip/s/KahZFMeJ")]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "付款方式：" } },
                            [_c("div", [_vm._v("余额")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "订单金额：" } },
                            [_c("div", [_vm._v("12.00余额")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "付款金额：" } },
                            [_c("div", [_vm._v("10.00余额")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "支付状态：" } },
                            [_c("div", [_vm._v("已支付")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "支付时间：" } },
                            [_c("div", [_vm._v("2021-11-29  15:39:20")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "付款金额：" } },
                            [_c("div", [_vm._v("10.00余额")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "备注：" } }, [
                            _c("div", [_vm._v("222")]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabType == "N",
                  expression: "tabType == 'N'",
                },
              ],
              staticClass: "container mt-1 ",
            },
            [_c("div", { staticClass: "title" }, [_vm._v("小订单详情列表")])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabType == "D",
                  expression: "tabType == 'D'",
                },
              ],
              staticClass: "container pd-20",
            },
            [
              _c(
                "el-timeline",
                _vm._l(_vm.activities, function (activity, index) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: index,
                      attrs: {
                        icon: activity.icon,
                        type: activity.type,
                        color: activity.color,
                        size: activity.size,
                        timestamp: activity.timestamp,
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(activity.content) + "\n        "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }