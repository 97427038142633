"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Parser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/Parser"));
var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));
var systemSettingApi = _interopRequireWildcard(require("@/api/systemSetting.js"));
var systemConfigApi = _interopRequireWildcard(require("@/api/systemConfig.js"));
var _index = _interopRequireDefault(require("@/views/appSetting/wxAccount/wxTemplate/index"));
var _utils = require("@/components/FormGenerator/utils");
var _permission = require("@/utils/permission");
var _validate = require("@/utils/validate");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  // name: "index",
  components: {
    Template: _index.default,
    parser: _Parser.default
  },
  data: function data() {
    return {
      loading: false,
      formConf: {
        content: {
          fields: []
        },
        id: null,
        render: false,
        isEdit: false
      },
      formConfChild: {
        content: {
          fields: []
        },
        id: null,
        render: false,
        isEdit: false
      },
      activeNamel1: null,
      activeNamel2: '',
      //针对文件特殊处理
      treeList: [],
      editDataChild: {},
      isCreate: 0,
      currentEditId: null,
      currentEditData: null,
      currentSelectedUploadFlag: null
    };
  },
  mounted: function mounted() {
    this.handlerGetTreeList();
    this.getCurrentUploadSelectedFlag();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    handleTabClick: function handleTabClick(tab) {
      this.activeNamel2 = tab.$children[0].panes[0].name;
      this.handlerGetLevel2FormConfig(this.activeNamel2);
    },
    handlerGetLevel1FormConfig: function handlerGetLevel1FormConfig(id) {
      var _this = this;
      var formPram = {
        id: id
      };
      this.currentEditId = id;
      this.formConf.content = {
        fields: []
      };
      this.formConf.render = false;
      this.loading = true;
      systemFormConfigApi.getFormConfigInfo(formPram).then(function (data) {
        var id = data.id,
          name = data.name,
          info = data.info,
          content = data.content;
        _this.formConf.content = JSON.parse(content);
        _this.formConf.id = id;
        _this.handlerGetSettingInfo(id, 1);
        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
    },
    handleItemTabClick: function handleItemTabClick(tab, event) {
      //这里对tabs=tab.name和radio=id做了兼容
      var _id = tab.name ? tab.name : tab;
      if (!_id) return this.$message.error('表单配置不正确，请关联正确表单后使用');
      this.handlerGetLevel2FormConfig(_id);
    },
    handlerGetLevel2FormConfig: function handlerGetLevel2FormConfig(id) {
      var _this2 = this;
      var formPram = {
        id: id
      };
      this.currentEditId = id;
      this.formConfChild.content = {
        fields: []
      };
      this.formConfChild.render = false;
      this.loading = true;
      systemFormConfigApi.getFormConfigInfo(formPram).then(function (data) {
        var id = data.id,
          name = data.name,
          info = data.info,
          content = data.content;
        _this2.formConfChild.content = JSON.parse(content);
        _this2.formConfChild.id = id;
        _this2.handlerGetSettingInfo(id, 2);
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    },
    handlerGetSettingInfo: function handlerGetSettingInfo(id, level) {
      var _this3 = this;
      systemSettingApi.systemConfigInfo({
        id: id
      }).then(function (data) {
        _this3.currentEditData = data;
        if (level === 1) {
          _this3.formConf.isEdit = _this3.currentEditData !== null;
          _this3.formConf.render = true;
        } else {
          _this3.formConfChild.isEdit = _this3.currentEditData !== null;
          _this3.formConfChild.render = true;
        }
      });
    },
    handlerSubmit: (0, _validate.Debounce)(function (formValue) {
      this.handlerSave(formValue);
    }),
    handlerSave: function handlerSave(formValue) {
      var _this4 = this;
      var _pram = this.buildFormPram(formValue);
      var _formId = 0;
      systemSettingApi.systemConfigSave(_pram).then(function (data) {
        _this4.$message.success('添加数据成功');
      });
    },
    handlerGetTreeList: function handlerGetTreeList() {
      var _this5 = this;
      var _pram = {
        type: this.$constants.categoryType[5].value,
        status: 1
      };
      this.loading = true;
      categoryApi.treeCategroy(_pram).then(function (data) {
        _this5.treeList = _this5.handleAddArrt(data);
        if (_this5.treeList.length > 0) _this5.activeNamel1 = _this5.treeList[0].id.toString();
        if (_this5.treeList.length > 0 && _this5.treeList[0].child.length > 0) {
          _this5.activeNamel2 = _this5.treeList[0].child[0].extra;
        }
        if (_this5.activeNamel2) {
          _this5.handlerGetLevel2FormConfig(_this5.treeList[0].child[0].extra);
        }
        // else {
        //  this.handlerGetLevel1FormConfig(this.treeList[0].extra)
        //}
        _this5.loading = false;
      }).catch(function () {
        _this5.loading = false;
      });
    },
    handleAddArrt: function handleAddArrt(treeData) {
      // let _result = this.addTreeListLabel(treeData)
      var _result = selfUtil.addTreeListLabel(treeData);
      return _result;
    },
    buildFormPram: function buildFormPram(formValue) {
      var _pram = {
        fields: [],
        id: this.currentEditId,
        sort: 0,
        // 参数暂时无用
        status: true // 参数暂时无用
      };
      var _fields = [];
      Object.keys(formValue).forEach(function (key) {
        _fields.push({
          name: key,
          title: key,
          value: formValue[key]
        });
      });
      _pram.fields = _fields;
      return _pram;
    },
    getCurrentUploadSelectedFlag: function getCurrentUploadSelectedFlag() {
      var _this6 = this;
      systemConfigApi.configGetUniq({
        key: "uploadType"
      }).then(function (data) {
        _this6.currentSelectedUploadFlag = parseInt(data);
      });
    }
  }
};