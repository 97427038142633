"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _device = require("@/api/device");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: "DeviceManager",
  filters: {},
  components: {},
  data: function data() {
    return {
      listLoading: true,
      constants: this.$constants,
      tableForm: {
        name: "",
        page: 1,
        limit: this.$constants.page.limit[0]
      },
      tableData: {
        list: [],
        total: 0
      },
      detailInfo: {}
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query && query.time) {
          this.seachList();
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    reset: function reset() {
      this.tableForm.name = "";
    },
    seachList: function seachList() {
      this.tableForm.page = 1;
      this.getList();
    },
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _device.deviceManagerListApi)(this.tableForm).then(function (res) {
        _this.tableData.list = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this2 = this;
      this.$modalSure("确认删除设备吗？请谨慎操作！").then(function () {
        (0, _device.deviceManagerRemoveApi)({
          id: id
        }).then(function () {
          _this2.$message.success("删除成功");
          _this2.tableData.list.splice(idx, 1);
        });
      });
    },
    // 修改状态
    hander: function hander(item) {
      var _this3 = this;
      this.$modalSure("\u786E\u8BA4".concat(item.status == 1 ? "禁用" : "启用", "\u8BBE\u5907\u3010").concat(item.name, "\u3011\u5417\uFF1F\u8BF7\u8C28\u614E\u64CD\u4F5C\uFF01")).then(function () {
        if (item.status == 1) {
          (0, _device.deviceManagerDisabledApi)({
            id: item.id
          }).then(function () {
            _this3.$message.success("禁用成功");
            _this3.getList();
          });
        } else {
          (0, _device.deviceManagerEnableApi)({
            id: item.id
          }).then(function () {
            _this3.$message.success("启用成功");
            _this3.getList();
          });
        }
      });
    },
    edit: function edit(item) {}
  }
};