"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _logistics = require("@/api/logistics");
var _validate = require("@/utils/validate");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      commonEnums: this.$commonEnums,
      tableForm: {
        proxyType: "",
        //1-直推、2-系统推荐、3-后台操作
        beginDate: "",
        endDate: "",
        keywords: "",
        page: 1,
        limit: 20
      },
      timeVal: [],
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    reset: function reset() {
      this.tableForm.beginDate = "";
      this.tableForm.endDate = "";
      this.timeVal = [];
      this.tableForm.keywords = "";
      this.tableForm.proxyType = "";
      this.tableForm.page = 1;
      this.getList();
    },
    onClickTab: function onClickTab() {
      this.tableForm.page = 1;
      this.getList();
    },
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _user.proxyList)(this.tableForm).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableForm.beginDate = e ? this.timeVal[0] : "";
      this.tableForm.endDate = e ? this.timeVal[1] : "";
      this.tableForm.page = 1;
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    },
    // 导出
    exports: function exports() {
      var exportParams = JSON.parse(JSON.stringify(this.tableForm));
      exportParams.page = 1;
      exportParams.limit = 100 * 10000;
      (0, _user.proxyListDownload)(exportParams).then(function (res) {
        window.location.href = res.fileName;
      });
    }
  }
};