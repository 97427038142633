"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _task = require("@/api/task");
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "OpenDetailsList",
  props: {
    datas: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableForm: {
        page: 1,
        limit: 20,
        uid: ''
      },
      modal_loading: false,
      imgError: 'this.src="' + require('../../../assets/imgs/default_avatar.png') + '"'
    };
  },
  mounted: function mounted() {
    this.tableForm.uid = this.datas.uid;
    this.init();
    this.getList();
  },
  methods: {
    init: function init() {
      this.tableForm.page = 1;
    },
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _task.openDetailsListApi)(this.tableForm).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        _this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    }
  }
};