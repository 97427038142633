"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _userInfo = _interopRequireDefault(require("./components/userInfo"));
var _userRegion = _interopRequireDefault(require("./components/userRegion"));
var _index = _interopRequireDefault(require("@/components/cards/index"));
var _statistic = require("@/api/statistic");
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  components: {
    userInfo: _userInfo.default,
    // wechetInfo,
    userRegion: _userRegion.default,
    cardsData: _index.default
  },
  data: function data() {
    return {
      list: [],
      formInline: {
        channel_type: "",
        data: ""
      }
    };
  },
  mounted: function mounted() {
    this.getStatistics();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // 统计
    getStatistics: function getStatistics() {
      var _this = this;
      (0, _statistic.userTotalData)().then(function (res) {
        _this.list = [{
          name: "累计用户数",
          icon: "iconleijiyonghushu",
          count: res.userNum,
          class: 'one',
          color: '#1890FF'
        }, {
          name: "累计充值人数",
          icon: "iconyonghu",
          count: res.rechargePeopleNum,
          class: 'two',
          color: '#A277FF'
        }, {
          name: "累计充值金额",
          icon: "iconchongzhijine",
          count: res.rechargeTotalAmount,
          class: 'three',
          color: '#EF9C20'
        }, {
          name: "累计消费金额",
          icon: "iconzhichujine1",
          count: res.consumptionAmount,
          class: 'four',
          color: '#1BBE6B'
        }];
      });
    }
  }
};