"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _statisticsData = _interopRequireDefault(require("../components/statisticsData"));
var _vueEcharts = _interopRequireDefault(require("vue-echarts"));
require("echarts/lib/chart/line");
require("echarts/lib/component/polar");
var _mpvueCalendar = _interopRequireDefault(require("mpvue-calendar"));
require("mpvue-calendar/src/browser-style.css");
var _order = require("@/api/order");
var _utils = require("@/utils");
var _Loading = _interopRequireDefault(require("../components/Loading"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var year = new Date().getFullYear();
var month = new Date().getMonth() + 1;
var day = new Date().getDate();
var _default = exports.default = {
  name: "Statistics",
  components: {
    ECharts: _vueEcharts.default,
    Calendar: _mpvueCalendar.default,
    Loading: _Loading.default,
    statisticsData: _statisticsData.default
  },
  props: {},
  data: function data() {
    return {
      polar: {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: [""]
        },
        toolbox: {
          show: false,
          feature: {
            mark: {
              show: true
            },
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ["line"]
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        calculable: true,
        xAxis: [{
          type: "category",
          boundaryGap: false,
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#999",
              width: 1 //这里是为了突出显示加上的
            }
          }
        }],
        yAxis: [{
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#f5f5f5"],
              width: 1,
              type: "solid"
            }
          },
          axisLine: {
            lineStyle: {
              color: "#999",
              width: 1 //这里是为了突出显示加上的
            }
          }
        }],
        series: [{
          name: "邮件营销",
          type: "line",
          stack: "总量",
          itemStyle: {
            normal: {
              color: "#2291f8",
              //折点颜色
              lineStyle: {
                color: "#2291f8" //折线颜色
              }
            }
          },
          data: [120, 132.5, 101, 134, 90, 150, 30]
        }],
        grid: {
          x: 30,
          x2: 10,
          y: 20,
          y2: 110,
          left: 40
        },
        animationDuration: 2000
      },
      value: [[year, month, day - 1], [year, month, day]],
      isrange: true,
      weekSwitch: false,
      ismulti: false,
      monFirst: true,
      clean: true,
      //简洁模式
      lunar: false,
      //显示农历
      renderValues: [],
      monthRange: [],
      current: false,
      where: {
        dateLimit: '',
        type: ''
      },
      types: "",
      //类型|order=订单数|price=营业额
      time: "",
      //时间|today=今天|yesterday=昨天|month=本月
      title: "",
      //时间|today=今天|yesterday=昨天|month=本月
      growth_rate: "",
      //增长率
      increase_time: "",
      //增长率
      increase_time_status: "",
      //增长率
      time_price: "",
      //增长率
      loaded: false,
      loading: false,
      filter: {
        page: 1,
        limit: 10,
        dateLimit: ""
      },
      list: []
    };
  },
  watch: {
    "$route.params": function $routeParams(newVal) {
      var that = this;
      if (newVal != undefined) {
        that.setType(newVal.type);
        that.setTime(newVal.time);
        that.getIndex();
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.handelRenderValues();
    this.setTime(this.$route.params.time);
    this.setType(this.$route.params.type);
    this.getIndex();
    this.getInfo();
    this.$scroll(this.$refs.container, function () {
      !_this.loading && _this.getInfo();
    });
  },
  computed: {
    monthRangeText: function monthRangeText() {
      return this.monthRange.length ? "固定" : "指定范围";
    }
  },
  methods: {
    getIndex: function getIndex() {
      var that = this;
      (0, _order.orderTimeApi)(that.where).then(function (res) {
        var _info = res.chart,
          day = [],
          num = [];
        _info.forEach(function (item) {
          day.push(item.time);
          num.push(item.num);
        });
        that.polar.xAxis[0].data = day;
        that.polar.series[0].data = num;
        that.growth_rate = res.growthRate;
        that.increase_time = res.increaseTime;
        that.increase_time_status = res.increaseTimeStatus;
        that.time_price = res.time;
      }, function (error) {
        that.$dialog.error(error.msg);
      });
    },
    setTime: function setTime(time) {
      this.time = time;
      this.where.dateLimit = time;
      this.filter.dateLimit = time;
      this.list = [];
      this.filter.page = 1;
      this.loaded = false;
      this.loading = false;
      this.getIndex();
      this.getInfo();
    },
    setType: function setType(type) {
      switch (type) {
        case "price":
          this.where.type = 1;
          break;
        case "order":
          this.where.type = 2;
          break;
      }
    },
    handelRenderValues: function handelRenderValues(data) {
      if (this.ismulti) {
        this.renderValues = this.value.map(function (v) {
          return v.join("-");
        });
      } else if (this.isrange) {
        var values = [];
        data || this.value;
        this.value.forEach(function (v, i) {
          values.push(v.join("-"));
          // if (!i) {
          //   values.push("~");
          // }
        });
        this.renderValues = values;
      } else {
        this.renderValues = [this.value.join("-")];
      }
      this.where.dateLimit = this.renderValues.join(',');
      // this.where.dateLimit = parseTime(this.renderValues[0], '{y}-{m}-{d}')+','+parseTime(this.renderValues[1], '{y}-{m}-{d}')
      this.filter.dateLimit = this.where.dateLimit;
    },
    prev: function prev(y, m, w) {
      console.log(y, m, w);
    },
    next: function next(year, month, week) {
      console.log(year, month, week);
    },
    selectYear: function selectYear(year) {},
    setToday: function setToday() {
      this.$refs.calendar.setToday();
    },
    dateInfo: function dateInfo() {
      var info = this.$refs.calendar.dateInfo(2018, 8, 23);
    },
    renderer: function renderer() {
      if (this.monthRange.length) {
        this.monthRange = ["2018-08", "2018-08"];
      }
      this.$refs.calendar.renderer(2018, 8); //渲染2018年8月份
    },
    select: function select(val, val2) {
      if (this.isrange) {
        this.handelRenderValues([val, val2]);
      } else if (this.ismulti) {
        this.handelRenderValues(val);
      } else {
        this.handelRenderValues([val]);
      }
      this.list = [];
      this.filter.page = 1;
      this.loaded = false;
      this.loading = false;
      this.time = "date";
      this.title = "";
      // this.getIndex();
      // this.getInfo();
    },
    dateTitle: function dateTitle() {
      this.current = true;
    },
    close: function close() {
      this.current = false;
      this.getIndex();
      this.getInfo();
    },
    getInfo: function getInfo() {
      var that = this;
      if (that.loading || that.loaded) return;
      that.loading = true;
      (0, _order.statisticsDataApi)(that.filter).then(function (res) {
        that.loading = false;
        that.loaded = res.length < that.filter.limit;
        that.list.push.apply(that.list, res);
        that.filter.page = that.filter.page + 1;
      }, function (error) {
        that.$dialog.message(error.msg);
      });
    }
  }
};