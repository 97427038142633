"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _college = require("@/api/college.js");
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "info"
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    mode: {
      type: String,
      default: ''
    },
    isAll: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      categoryProps: {
        value: 'id',
        label: 'name',
        children: 'children',
        expandTrigger: 'hover',
        checkStrictly: true,
        emitPath: false,
        disabled: false
      },
      allTreeList: []
    };
  },
  mounted: function mounted() {
    if (this.mode == 'first') {
      this.categoryProps['disabled'] = 'first';
    }
    this.handlerGetTreeList();
  },
  methods: {
    handlerGetTreeList: function handlerGetTreeList() {
      var _this = this;
      (0, _college.CollegeTree)().then(function (res) {
        var allArr = [];
        if (!_this.isAll) {
          allArr = res[0] && res[0].children;
        } else {
          allArr = res;
        }
        _this.allTreeList = allArr;
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    },
    onChange: function onChange(data) {
      this.$emit('onChange', data);
      this.$emit('onChangeItem', this.$refs['demoCascader'].getCheckedNodes());
    }
  }
};