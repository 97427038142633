var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "lock-scroll": "",
            "show-close": "",
            title: "选择链接",
            "close-on-click-modal": false,
            data: _vm.categoryData,
            width: "860",
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "table_box" }, [
            _c(
              "div",
              { staticClass: "left_box" },
              [
                _c("el-tree", {
                  attrs: {
                    data: _vm.categoryData,
                    props: _vm.defaultProps,
                    "default-expanded-keys": [1, 2, 3, 4],
                    "current-node-key": 5,
                    "highlight-current": true,
                    "node-key": "id",
                  },
                  on: { "node-click": _vm.handleNodeClick },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.currenType == "link"
              ? _c("div", { staticClass: "right_box" }, [
                  _vm.basicsList.length
                    ? _c("div", [
                        _c("div", { staticClass: "cont" }, [
                          _vm._v("基础链接"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "Box" },
                          _vm._l(_vm.basicsList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "cont_box",
                                class: _vm.currenId == item.id ? "on" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.getUrl(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.userList.length
                    ? _c("div", [
                        _c("div", { staticClass: "cont" }, [
                          _vm._v("个人中心"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "Box" },
                          _vm._l(_vm.userList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "cont_box",
                                class: _vm.currenId == item.id ? "on" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.getUrl(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.distributionList.length
                    ? _c("div", [
                        _c("div", { staticClass: "cont" }, [_vm._v("分销")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "Box" },
                          _vm._l(_vm.distributionList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "cont_box",
                                class: _vm.currenId == item.id ? "on" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.getUrl(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.currenType == "marketing_link"
              ? _c("div", { staticClass: "right_box" }, [
                  _vm.coupon.length
                    ? _c("div", [
                        _c("div", { staticClass: "cont" }, [_vm._v("优惠券")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "Box" },
                          _vm._l(_vm.coupon, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "cont_box",
                                class: _vm.currenId == item.id ? "on" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.getUrl(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.basicsList.length
                    ? _c("div", [
                        _c("div", { staticClass: "cont" }, [_vm._v("秒杀")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "Box" },
                          _vm._l(_vm.basicsList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "cont_box",
                                class: _vm.currenId == item.id ? "on" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.getUrl(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.distributionList.length
                    ? _c("div", [
                        _c("div", { staticClass: "cont" }, [_vm._v("砍价")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "Box" },
                          _vm._l(_vm.distributionList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "cont_box",
                                class: _vm.currenId == item.id ? "on" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.getUrl(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.userList.length
                    ? _c("div", [
                        _c("div", { staticClass: "cont" }, [_vm._v("拼团")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "Box" },
                          _vm._l(_vm.userList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "cont_box",
                                class: _vm.currenId == item.id ? "on" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.getUrl(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.integral.length
                    ? _c("div", [
                        _c("div", { staticClass: "cont" }, [_vm._v("积分")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "Box" },
                          _vm._l(_vm.integral, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "cont_box",
                                class: _vm.currenId == item.id ? "on" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.getUrl(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.luckDraw.length
                    ? _c("div", [
                        _c("div", { staticClass: "cont" }, [_vm._v("抽奖")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "Box" },
                          _vm._l(_vm.luckDraw, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "cont_box",
                                class: _vm.currenId == item.id ? "on" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.getUrl(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.currenType == "special" ||
            _vm.currenType == "product_category" ||
            _vm.currenType == "product" ||
            _vm.currenType == "seckill" ||
            _vm.currenType == "bargain" ||
            _vm.currenType == "combination" ||
            _vm.currenType == "news" ||
            _vm.currenType == "integral"
              ? _c(
                  "div",
                  { staticClass: "right_box" },
                  [
                    _vm.currenType == "product"
                      ? _c(
                          "el-form",
                          {
                            ref: "formValidate",
                            staticClass: "tabform",
                            attrs: { model: _vm.formValidate },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      [
                                        _c("el-input", {
                                          staticStyle: {
                                            width: "250px",
                                            "margin-right": "20px",
                                          },
                                          attrs: {
                                            placeholder:
                                              "请输入商品名称,关键字,编号",
                                          },
                                          model: {
                                            value: _vm.formValidate.keywords,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formValidate,
                                                "keywords",
                                                $$v
                                              )
                                            },
                                            expression: "formValidate.keywords",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          { attrs: { type: "primary" } },
                                          [_vm._v("搜索")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.lodingList,
                            expression: "lodingList",
                          },
                        ],
                        ref: "table",
                        attrs: {
                          "row-key": "id",
                          size: "small",
                          data: _vm.tableList.list,
                          "tree-props": {
                            children: "child",
                            hasChildren: "hasChildren",
                          },
                          "max-height":
                            _vm.currenType == "product_category"
                              ? "410"
                              : _vm.currenType == "product" ||
                                _vm.currenType == "seckill" ||
                                _vm.currenType == "bargain" ||
                                _vm.currenType == "combination" ||
                                _vm.currenType == "news" ||
                                _vm.currenType == "integral"
                              ? "400"
                              : "",
                        },
                        on: { "row-click": _vm.singleElection },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "", width: "80" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-radio",
                                      {
                                        staticClass: "radio",
                                        attrs: { label: scope.$index },
                                        model: {
                                          value: _vm.radioData,
                                          callback: function ($$v) {
                                            _vm.radioData = $$v
                                          },
                                          expression: "radioData",
                                        },
                                      },
                                      [_vm._v(" ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2423043706
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "id", label: "ID", width: "80" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "图片", width: "80" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.currenType == "news"
                                      ? _c("el-image", {
                                          staticStyle: {
                                            width: "50px",
                                            height: "50px",
                                          },
                                          attrs: {
                                            lazy: "",
                                            src: scope.row.imageInput,
                                            fit: "fill",
                                          },
                                        })
                                      : _vm.currenType == "product_category"
                                      ? _c("el-image", {
                                          staticStyle: {
                                            width: "50px",
                                            height: "50px",
                                          },
                                          attrs: {
                                            lazy: "",
                                            src: scope.row.extra,
                                            fit: "fill",
                                          },
                                        })
                                      : _c("el-image", {
                                          staticStyle: {
                                            width: "50px",
                                            height: "50px",
                                          },
                                          attrs: {
                                            lazy: "",
                                            src: scope.row.image,
                                            fit: "fill",
                                          },
                                        }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1644741407
                          ),
                        }),
                        _vm._v(" "),
                        _vm.currenType == "product"
                          ? _c("el-table-column", {
                              attrs: {
                                prop: "storeName",
                                label: "商品名称",
                                "show-overflow-tooltip": true,
                                "max-width": "250",
                              },
                            })
                          : _vm.currenType == "product_category"
                          ? _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "分类名称",
                                "show-overflow-tooltip": true,
                                "max-width": "250",
                              },
                            })
                          : _c("el-table-column", {
                              attrs: {
                                prop: "title",
                                label:
                                  _vm.currenType == "news"
                                    ? "文章名称"
                                    : "商品名称",
                                "show-overflow-tooltip": true,
                                "max-width": "250",
                              },
                            }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.currenType !== "product_category"
                      ? _c("el-pagination", {
                          attrs: {
                            "current-page": _vm.params.page,
                            "page-sizes": _vm.constants.page.limit,
                            layout: _vm.constants.page.layout,
                            total: _vm.tableList.total,
                          },
                          on: {
                            "size-change": _vm.handleSizeChange,
                            "current-change": _vm.handleCurrentChange,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.currenType == "custom"
              ? _c("div", { staticClass: "right_box" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "440px",
                        margin: "50px 100px 0 120px",
                      },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "customDate",
                          attrs: {
                            model: _vm.customDate,
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "跳转路径：", prop: "url" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入跳转路径" },
                                model: {
                                  value: _vm.customDate.url,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.customDate, "url", $$v)
                                  },
                                  expression: "customDate.url",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", [_vm._v("取消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSubmit()
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }