var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-style" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticStyle: { width: "100%", height: "100%" } }, [
      _c(
        "div",
        {
          ref: "item1Inner",
          staticClass: "item1Inner",
          style: {
            width: _vm.width ? _vm.width + "px" : "60%",
            height: _vm.height ? _vm.height + "px" : "68%",
          },
        },
        [
          _c("div", {
            staticClass: "centerMap",
            style: { width: _vm.width + "px", height: _vm.height + "px" },
            attrs: { id: "centerMap" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "map" }, [
            _c("img", {
              ref: "img",
              attrs: { src: require("../assets/centerMap.png"), alt: "" },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "center-top-city-style" }, [
        _c("div", { staticClass: "dataNav" }, [
          _c("div", { staticClass: "city-font-s" }, [
            _c("div", { staticClass: "left-bg", on: { click: _vm.navPrev } }),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { ref: "dataNavList", staticClass: "dataNavList" },
            _vm._l(_vm.navList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  class:
                    _vm.isCityClass == item.id
                      ? "dataNavListItem2"
                      : "dataNavListItem1",
                  style: { transform: "translateX(-" + _vm.move + "px)" },
                  on: {
                    click: function ($event) {
                      return _vm.clickCity(item)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n              " + _vm._s(item.name) + "\n            "
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "city-font-s" }, [
            _c("div", { staticClass: "right-bg", on: { click: _vm.navNext } }),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "smallLabel" }, [
      _c("div", { staticStyle: { height: "98%" } }, [
        _c("div", { staticClass: "label-style" }, [
          _c("div", { staticClass: "label-font-top" }, [_vm._v("数量统计")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "statisticsNumberStyle",
              staticStyle: { width: "500px" },
            },
            [
              _c("div", { staticClass: "statisticsNumber" }, [_vm._v("0")]),
              _vm._v(" "),
              _c("div", { staticClass: "statisticsNumber" }, [_vm._v("0")]),
              _vm._v(" "),
              _c("div", { staticClass: "statisticsNumber" }, [_vm._v("6")]),
              _vm._v(" "),
              _c("div", { staticClass: "statisticsNumber" }, [_vm._v("0")]),
              _vm._v(" "),
              _c("div", { staticClass: "statisticsNumber" }, [_vm._v("3")]),
              _vm._v(" "),
              _c("div", { staticClass: "statisticsNumber" }, [_vm._v("8")]),
              _vm._v(" "),
              _c("div", { staticClass: "statisticsNumber" }, [_vm._v("3")]),
            ]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }