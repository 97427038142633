var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.datas && _vm.datas.uid
        ? _c(
            "el-card",
            {
              staticClass: "box-card",
              attrs: {
                "body-style": { "max-height": "600px", "overflow-y": "auto" },
              },
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData.data,
                    size: "mini",
                    "highlight-current-row": true,
                    "header-cell-style": { fontWeight: "bold" },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "用户昵称", "min-width": "120" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(_vm.datas.nickname)
                                  ) +
                                    " | " +
                                    _vm._s(_vm._f("sexFilter")(_vm.datas.sex))
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3112536906
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "手机号", "min-width": "120" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm._f("filterEmpty")(_vm.datas.phone)
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3734222688
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "触发时间",
                      prop: "createAt",
                      "min-width": "130",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "风控描述",
                      prop: "name",
                      "min-width": "300",
                      "show-overflow-tooltip": true,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.tableForm.limit,
                      "current-page": _vm.tableForm.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }