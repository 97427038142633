var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-cascader", {
        ref: "demoCascader",
        staticStyle: { width: "100%" },
        attrs: {
          clearable: "",
          value: _vm.id,
          options: _vm.allTreeList,
          props: _vm.categoryProps,
        },
        on: { change: _vm.onChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }