var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "components-container" },
          [
            _c(
              "el-form",
              {
                ref: "pram",
                attrs: { "label-width": "150px", model: _vm.pram },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "标题",
                      prop: "name",
                      rules: [
                        {
                          required: true,
                          message: "请填写标题",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "标题", maxlength: "100" },
                      model: {
                        value: _vm.pram.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "name", $$v)
                        },
                        expression: "pram.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      _vm._b({}, "el-col", _vm.grid2, false),
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "售出价格",
                              prop: "price",
                              rules: [
                                {
                                  required: true,
                                  message: "请填写售出价格",
                                  trigger: ["blur", "change"],
                                },
                              ],
                            },
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "priceBox",
                              attrs: {
                                min: 0,
                                max: 99999,
                                precision: 2,
                                step: 1,
                              },
                              model: {
                                value: _vm.pram.price,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pram, "price", $$v)
                                },
                                expression: "pram.price",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      _vm._b({}, "el-col", _vm.grid2, false),
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "库存",
                              prop: "stock",
                              rules: [
                                {
                                  required: true,
                                  message: "请填写库存",
                                  trigger: ["blur", "change"],
                                },
                              ],
                            },
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "priceBox",
                              attrs: {
                                min: 0,
                                max: 99999,
                                precision: 0,
                                step: 1,
                              },
                              model: {
                                value: _vm.pram.stock,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pram, "stock", $$v)
                                },
                                expression: "pram.stock",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      _vm._b({}, "el-col", _vm.grid2, false),
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "单次起购数量",
                              prop: "buyMin",
                              rules: [
                                {
                                  required: true,
                                  message: "请填写单次起购数量",
                                  trigger: ["blur", "change"],
                                },
                              ],
                            },
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "priceBox",
                              attrs: {
                                min: 0,
                                max: 99999,
                                precision: 0,
                                step: 1,
                              },
                              model: {
                                value: _vm.pram.buyMin,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pram, "buyMin", $$v)
                                },
                                expression: "pram.buyMin",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      _vm._b({}, "el-col", _vm.grid2, false),
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "单用户限购数量",
                              prop: "buyMax",
                              rules: [
                                {
                                  required: true,
                                  message: "请填写单用户限购数量",
                                  trigger: ["blur", "change"],
                                },
                              ],
                            },
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "priceBox",
                              attrs: {
                                min: 0,
                                max: 99999,
                                precision: 0,
                                step: 1,
                              },
                              model: {
                                value: _vm.pram.buyMax,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pram, "buyMax", $$v)
                                },
                                expression: "pram.buyMax",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      _vm._b({}, "el-col", _vm.grid2, false),
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "托管周期",
                              prop: "towCycle",
                              rules: [
                                {
                                  required: true,
                                  message: "请填写托管周期",
                                  trigger: ["blur", "change"],
                                },
                              ],
                            },
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "priceBox",
                              attrs: {
                                min: 0,
                                max: 99999,
                                precision: 0,
                                step: 1,
                              },
                              model: {
                                value: _vm.pram.towCycle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pram, "towCycle", $$v)
                                },
                                expression: "pram.towCycle",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.pram.cycleUint,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pram, "cycleUint", $$v)
                                  },
                                  expression: "pram.cycleUint",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "天", value: "天" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "个月", value: "个月" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      _vm._b({}, "el-col", _vm.grid2, false),
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "封装时间",
                              prop: "packDay",
                              rules: [
                                {
                                  required: true,
                                  message: "请填写封装时间",
                                  trigger: ["blur", "change"],
                                },
                              ],
                            },
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "priceBox",
                              attrs: {
                                min: 0,
                                max: 99999,
                                precision: 0,
                                step: 1,
                              },
                              model: {
                                value: _vm.pram.packDay,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pram, "packDay", $$v)
                                },
                                expression: "pram.packDay",
                              },
                            }),
                            _vm._v("\n                  天\n            "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      _vm._b({}, "el-col", _vm.grid2, false),
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "每日收益下限",
                              prop: "incomeMin",
                              rules: [
                                {
                                  required: true,
                                  message: "请填写每日收益下限",
                                  trigger: ["blur", "change"],
                                },
                              ],
                            },
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "priceBox",
                              attrs: {
                                min: 0,
                                max: 99999,
                                precision: 2,
                                step: 1,
                              },
                              model: {
                                value: _vm.pram.incomeMin,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pram, "incomeMin", $$v)
                                },
                                expression: "pram.incomeMin",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      _vm._b({}, "el-col", _vm.grid2, false),
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "每日收益上限",
                              prop: "incomeMax",
                              rules: [
                                {
                                  required: true,
                                  message: "请填写每日收益上限",
                                  trigger: ["blur", "change"],
                                },
                              ],
                            },
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "priceBox",
                              attrs: {
                                min: 0,
                                max: 99999,
                                precision: 2,
                                step: 1,
                              },
                              model: {
                                value: _vm.pram.incomeMax,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pram, "incomeMax", $$v)
                                },
                                expression: "pram.incomeMax",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      _vm._b({}, "el-col", _vm.grid2, false),
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "设备主图",
                              prop: "img",
                              rules: [
                                {
                                  required: true,
                                  message: "请上传设备主图",
                                  trigger: "change",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "upLoadPicBox",
                                on: {
                                  click: function ($event) {
                                    return _vm.modalPicTap("1")
                                  },
                                },
                              },
                              [
                                _vm.pram.img
                                  ? _c("div", { staticClass: "pictrue" }, [
                                      _c("img", {
                                        attrs: { src: _vm.pram.img },
                                      }),
                                    ])
                                  : _c("div", { staticClass: "upLoad" }, [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-camera cameraIconfont",
                                      }),
                                    ]),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      _vm._b({}, "el-col", _vm.grid2, false),
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "排序",
                              prop: "sort",
                              rules: [
                                {
                                  required: true,
                                  message: "请填写",
                                  trigger: ["blur", "change"],
                                },
                              ],
                            },
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "priceBox",
                              attrs: {
                                min: 0,
                                max: 99999,
                                precision: 0,
                                step: 1,
                              },
                              model: {
                                value: _vm.pram.sort,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pram, "sort", $$v)
                                },
                                expression: "pram.sort",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      _vm._b({}, "el-col", _vm.grid2, false),
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "CDN设备销售合同",
                              prop: "salesContract",
                              rules: [
                                {
                                  required: true,
                                  message: "请上传CDN设备销售合同",
                                  trigger: "change",
                                },
                              ],
                            },
                          },
                          [
                            _c("UploadFile", {
                              attrs: {
                                name: "salesContract",
                                value: _vm.pram.salesContract,
                                accept: "pdf",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.onChangeContract(
                                    $event,
                                    "salesContract",
                                    "scName"
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.pram.salesContract && _vm.pram.scName
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "contract-name",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openPdf(
                                          _vm.pram.salesContract
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.pram.scName))]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      _vm._b({}, "el-col", _vm.grid2, false),
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "CDN服务器托管技术合同",
                              prop: "technicalContract",
                              rules: [
                                {
                                  required: true,
                                  message: "请上传CDN服务器托管技术合同",
                                  trigger: "change",
                                },
                              ],
                            },
                          },
                          [
                            _c("UploadFile", {
                              attrs: {
                                name: "technicalContract",
                                value: _vm.pram.technicalContract,
                                accept: "pdf",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.onChangeContract(
                                    $event,
                                    "technicalContract",
                                    "tcName"
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.pram.technicalContract && _vm.pram.tcName
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "contract-name",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openPdf(
                                          _vm.pram.technicalContract
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.pram.tcName))]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "设备详情",
                      prop: "info",
                      rules: [
                        {
                          required: false,
                          message: "请填写设备详情",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("Tinymce", {
                      model: {
                        value: _vm.pram.info,
                        callback: function ($$v) {
                          _vm.$set(_vm.pram, "info", $$v)
                        },
                        expression: "pram.info",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _vm.checkPermi([
                      "admin:device:manage:add",
                      "admin:device:manage:edit",
                    ])
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [
                                  "admin:device:manage:add",
                                  "admin:device:manage:edit",
                                ],
                                expression:
                                  "['admin:device:manage:add','admin:device:manage:edit']",
                              },
                            ],
                            attrs: { type: "primary", loading: _vm.loading },
                            on: {
                              click: function ($event) {
                                return _vm.handerSubmit("pram")
                              },
                            },
                          },
                          [_vm._v("保存")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }