"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
require("../../../../../node_modules/echarts/map/js/china.js");
var _statistic = require("@/api/statistic");
var _index = _interopRequireDefault(require("@/components/echartsNew/index"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 引入中国地图数据
// 权限判断函数
var _default2 = exports.default = {
  name: "userRegion",
  components: {
    echartsNew: _index.default
  },
  props: {
    formInline: {
      type: Object,
      default: function _default() {
        return {
          channel_type: "",
          data: ""
        };
      }
    }
  },
  data: function data() {
    return {
      chart: null,
      resdata: [],
      resdataList: [],
      columns1: [{
        title: "TOP省份",
        key: "area"
      }, {
        title: "支付金额",
        key: "payAmount",
        sortable: true
      }, {
        title: "成交用户数量",
        key: "payUserNum",
        sortable: true
      }, {
        title: "累计用户数量",
        key: "userNum",
        sortable: true
      }],
      style: {
        height: "400px"
      },
      style1: {
        height: "400px"
      },
      optionData: {},
      optionSex: {}
    };
  },
  mounted: function mounted() {
    this.getTrend();
    this.getSource();
    this.getSex();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    checkPermi: _permission.checkPermi,
    chinaConfigure: function chinaConfigure() {
      var myChart = _echarts.default.init(this.$refs.myEchart); //这里是为了获得容器所在位置
      window.onresize = myChart.resize;
      myChart.setOption({
        // 进行相关配置
        backgroundColor: "#fff",
        tooltip: {
          trigger: "item",
          formatter: function formatter(params) {
            return params.data ? "\u5730\u533A:".concat(params.name, "</br>\u652F\u4ED8\u91D1\u989D: ").concat(params.data.value, "</br>\u6210\u4EA4\u7528\u6237\u6570\u91CF: ").concat(params.data.newNum, "</br>\u7D2F\u8BA1\u7528\u6237\u6570\u91CF: ").concat(params.data.payPrice) : "\u5730\u533A:".concat(params.name, "</br>\u652F\u4ED8\u91D1\u989D: 0</br>\u6210\u4EA4\u7528\u6237\u6570\u91CF: 0</br>\u7D2F\u8BA1\u7528\u6237\u6570\u91CF: 0");
          }
        },
        // 鼠标移到图里面的浮动提示框
        dataRange: {
          show: false,
          min: 0,
          max: 1000,
          text: ["High", "Low"],
          realtime: true,
          calculable: true,
          color: ["orangered", "yellow", "lightskyblue"]
        },
        geo: {
          // 这个是重点配置区
          map: "china",
          // 表示中国地图
          roam: false,
          label: {
            normal: {
              show: false,
              // 是否显示对应地名
              textStyle: {
                color: "rgba(0,0,0,0.4)"
              }
            }
          },
          itemStyle: {
            normal: {
              borderColor: "rgba(0, 0, 0, 0.2)"
            },
            emphasis: {
              areaColor: null,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 10,
              borderWidth: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        },
        series: [{
          type: "scatter",
          zoom: 1.2,
          aspectScale: 1.75,
          //长宽比
          coordinateSystem: "geo" // 对应上方配置
        }, {
          type: "map",
          geoIndex: 0,
          data: this.resdata
        }]
      });
    },
    // 统计图
    getTrend: function getTrend() {
      var _this = this;
      (0, _statistic.userAreaData)().then(function (res) {
        _this.resdataList = res;
        _this.resdata = res.map(function (item) {
          var jsonData = {};
          jsonData.name = item.area;
          jsonData.value = item.payAmount;
          jsonData.newNum = item.payUserNum;
          jsonData.payPrice = item.userNum;
          return jsonData;
        });
        _this.chinaConfigure();
      });
    },
    //来源
    getSource: function getSource() {
      var _this2 = this;
      (0, _statistic.userChannelData)().then(function (res) {
        var channelData = new Array();
        channelData = [{
          name: 'H5',
          value: 0,
          channel: 'h5'
        }, {
          name: 'APP',
          value: 0,
          channel: 'ios'
        }, {
          name: '小程序',
          value: 0,
          channel: 'routine'
        }, {
          name: '公众号',
          value: 0,
          channel: 'wechat'
        }];
        var channelArr = new Array();
        channelData.forEach(function (item) {
          res.forEach(function (item1) {
            if (item.channel == item1.channel) {
              channelArr.push({
                name: item.name,
                value: item1.num ? item1.num : 0,
                channel: item1.channel
              });
            }
          });
        });
        _this2.optionData = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'right'
          },
          series: [{
            name: '访问来源',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },
              normal: {
                color: function color(params) {
                  //自定义颜色
                  var colorList = ['#F8C348', '#1991FF', '#40C9D3', '#F94748', '#854FF8'];
                  return colorList[params.dataIndex];
                }
              }
            },
            labelLine: {
              show: false
            },
            data: channelArr
          }]
        };
      });
    },
    getSex: function getSex() {
      var _this3 = this;
      (0, _statistic.userSexData)().then(function (res) {
        var sexData = new Array();
        sexData = [{
          name: '未知',
          value: '',
          sex: '0'
        }, {
          name: '男',
          value: '',
          sex: '1'
        }, {
          name: '女',
          value: '',
          sex: '2'
        }, {
          name: '保密',
          value: '',
          sex: '3'
        }];
        var sexArr = [];
        sexData.forEach(function (item) {
          res.forEach(function (item1) {
            if (item.sex == item1.sex) {
              sexArr.push({
                name: item.name,
                value: item1.num,
                sex: item1.sex
              });
            }
          });
        });
        _this3.optionSex = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'right'
          },
          series: [{
            name: '用户性别',
            type: 'pie',
            radius: '70%',
            itemStyle: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },
              normal: {
                color: function color(params) {
                  //自定义颜色
                  var colorList = ['#1890FF', '#EF9C20', '#4BCAD5', '#854FF8', '#F94748'];
                  return colorList[params.dataIndex];
                }
              }
            },
            labelLine: {
              show: true
            },
            data: sexArr
          }]
        };
      });
    }
  }
};