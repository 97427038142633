var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg-style" }, [
      _c("div", { staticClass: "center-bg-style" }, [
        _c("div", { staticClass: "title-style" }, [
          _vm._v("\n      7月城市流量排名\n    "),
        ]),
        _vm._v(" "),
        _c("div", {
          staticStyle: { width: "100%", height: "80%" },
          attrs: { id: "flowId" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }