"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Home = _interopRequireDefault(require("@/components/FormGenerator/index/Home.vue"));
var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "edit"
  components: {
    configList: _Home.default
  },
  props: {
    editData: {
      type: Object,
      default: {}
    },
    isCreate: {
      type: Number,
      default: 0 // 0=创建，1=编辑
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    handlerGetFormConfigData: function handlerGetFormConfigData(formConfigData) {
      formConfigData.id ? this.handlerEdit(formConfigData) : this.handlerSave(formConfigData);
    },
    handlerSave: function handlerSave(pram) {
      var _this = this;
      systemFormConfigApi.getFormConfigSave(pram).then(function (data) {
        _this.$message.success('创建表单配置成功');
        setTimeout(function () {
          _this.$emit('hideDialog');
        }, 800);
      });
    },
    handlerEdit: function handlerEdit(pram) {
      var _this2 = this;
      systemFormConfigApi.getFormConfigEdit(pram).then(function (data) {
        _this2.$message.success('编辑表单配置成功');
        setTimeout(function () {
          _this2.$emit('hideDialog');
        }, 800);
      });
    }
  }
};