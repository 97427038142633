"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var defaultObj = {
  amt: '',
  current: ''
};
var _default = exports.default = {
  name: "UserContribution",
  props: {
    datas: {
      type: Object,
      default: null
    }
  },
  filters: {},
  data: function data() {
    return {
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableForm: {
        page: 1,
        limit: 20,
        uid: '',
        "type": 0,
        //类型：1=增加；2=扣减;
        "busType": 0 //业务类型：1=后台增加；2=后台减少；3=划出;4=划入;5=直推认证;6=兑换
      },
      formValidate: Object.assign({}, defaultObj),
      ruleValidate: {
        amt: [{
          required: true,
          message: "请输入修改贡献值~",
          trigger: "blur"
        }]
      }
    };
  },
  mounted: function mounted() {
    this.init();
    this.getInfo();
    this.getList();
  },
  methods: {
    init: function init() {
      this.tableForm.page = 1;
      this.tableForm.uid = this.datas.uid;
      this.formValidate = Object.assign({}, defaultObj);
    },
    // 当前贡献值
    getInfo: function getInfo() {
      var _this = this;
      (0, _user.getUserContributionApi)(this.tableForm.uid).then(function (res) {
        _this.formValidate.current = res || 0;
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _user.viewUserContributionListApi)(this.tableForm).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        _this2.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    },
    // 操作
    handleSubmit: (0, _validate.Debounce)(function (form) {
      var _this3 = this;
      this.$refs[form].validate(function (valid) {
        if (!valid) return;
        if (_this3.formValidate.amt == 0) {
          return _this3.$message.error('修改贡献值不能为0');
        }
        (0, _user.setUserContributionApi)({
          "uid": _this3.tableForm.uid,
          "amt": _this3.formValidate.amt //正加负减
        }).then(function (res) {
          _this3.init();
          _this3.getInfo();
          _this3.getList();
        }).catch(function (res) {
          _this3.$message.error(res.message);
        });
      });
    }),
    close: function close() {
      this.$emit('close', true);
    }
  }
};