"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _statistic = require("@/api/statistic");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'ToDay',
  data: function data() {
    return {
      style: {
        height: '250px'
      },
      grid: {
        xl: 6,
        lg: 6,
        md: 12,
        sm: 24,
        xs: 24
      },
      cardLists: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    getList: function getList() {
      var _this = this;
      (0, _statistic.tradeDataApi)().then(function (res) {
        _this.cardLists = [{
          name: "昨日订单数量",
          icon: "iconline-order",
          tag: '昨日',
          count: res.yesterdayOrderNum,
          percent: res.yesterdayOrderNumRatio
        }, {
          name: "本月订单数量",
          icon: "iconxiaoshoue",
          count: res.monthOrderNum,
          tag: '本月',
          percent: res.monthOrderNumRatio
        }, {
          name: "昨日支付金额",
          icon: "iconline-coin",
          tag: '昨日',
          count: res.yesterdayOrderSales,
          percent: res.yesterdayOrderSalesRatio
        }, {
          name: "本月订单金额",
          icon: "iconshangpinzhifujine",
          count: res.monthOrderSales,
          tag: '本月',
          percent: res.monthOrderSalesRatio
        }];
      });
    }
  }
};