var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          !_vm.mode
            ? _c(
                "div",
                {
                  staticClass: "clearfix pt-20",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("联联商品详情"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["admin:article:info"],
                          expression: "['admin:article:info']",
                        },
                      ],
                      staticClass: "mr10",
                      staticStyle: { float: "right" },
                      attrs: { type: "primary" },
                    },
                    [_vm._v("确认\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      staticStyle: { float: "right" },
                      on: { click: _vm.back },
                    },
                    [_vm._v("返回")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.onClickTab },
              model: {
                value: _vm.tabType,
                callback: function ($$v) {
                  _vm.tabType = $$v
                },
                expression: "tabType",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "基本信息", name: "N" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "套餐列表", name: "G" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "店铺列表", name: "S" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "商品详情", name: "D" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabType == "N",
                  expression: "tabType == 'N'",
                },
              ],
              staticClass: "container mt-1",
            },
            [
              _c(
                "el-form",
                {
                  attrs: { "label-width": "140px" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品名称：" } },
                            [
                              _c("div", [
                                _vm._v("树木岭丨吃湘喝辣丨卤虾丨乳山生蚝 "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品简称：" } },
                            [
                              _c("div", [
                                _vm._v("树木岭丨吃湘喝辣丨卤虾丨乳山生蚝 "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "分享文字：" } },
                            [
                              _c("div", [
                                _vm._v("树木岭丨吃湘喝辣丨卤虾丨乳山生蚝 "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "标题：" } }, [
                            _c("div", [
                              _vm._v("树木岭丨吃湘喝辣丨卤虾丨乳山生蚝 "),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "已售数量：" } },
                            [_c("div", [_vm._v("100件")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "库存数量：" } },
                            [_c("div", [_vm._v("199990件")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否开启套餐库存：" } },
                            [_c("div", [_vm._v("是")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "售价：" } }, [
                            !_vm.mode
                              ? _c(
                                  "div",
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "70%" },
                                      attrs: {
                                        size: "small",
                                        min: 0,
                                        max: 99999,
                                        precision: 2,
                                        step: 1,
                                      },
                                      model: {
                                        value: _vm.retailPrice,
                                        callback: function ($$v) {
                                          _vm.retailPrice = $$v
                                        },
                                        expression: "retailPrice",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c("div", [_vm._v(_vm._s(_vm.retailPrice))]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "原价：" } }, [
                            !_vm.mode
                              ? _c(
                                  "div",
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "70%" },
                                      attrs: {
                                        size: "small",
                                        min: 0,
                                        max: 99999,
                                        precision: 2,
                                        step: 1,
                                      },
                                      model: {
                                        value: _vm.purplePrice,
                                        callback: function ($$v) {
                                          _vm.purplePrice = $$v
                                        },
                                        expression: "purplePrice",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c("div", [_vm._v(_vm._s(_vm.purplePrice))]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否售馨：" } },
                            [_c("div", [_vm._v("是")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "余额价：" } }, [
                            _c("div", [_vm._v("9.99")]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "余额原价：" } },
                            [_c("div", [_vm._v("29.90")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "支付方式：" } },
                            [_c("div", [_vm._v("支付宝，余额")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: " 推荐理由：" } },
                            [
                              _c("div", [
                                _vm._v(
                                  "出来吃夜宵啦！吃湘喝辣送福利！69.9元享金牌卤虾100只/钜惠2-3人餐，39.9元享乳山生蚝30只，148元享夜宵烧烤3-4人餐~"
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "备注：" } }, [
                            _c("div", [_vm._v("222")]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title pd-20" }, [
                    _vm._v("订单注意事项"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否需要预约配送：" } },
                            [_c("div", [_vm._v("否")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否需要身份证号：" } },
                            [_c("div", [_vm._v("否")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否需要填写使用日期：" } },
                            [_c("div", [_vm._v("否")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "预约开始时间：" } },
                            [_c("div", [_vm._v("2022-12-01")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "抢购开始时间：" } },
                            [_c("div", [_vm._v("2023-01-01")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "抢购结束时间：" } },
                            [_c("div", [_vm._v("2023-12-31")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品上线时间：" } },
                            [_c("div", [_vm._v("2022-12-01")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "有效开始时间：" } },
                            [_c("div", [_vm._v("2023-01-01")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "有效结束时间：" } },
                            [_c("div", [_vm._v("2023-12-31")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "单次最小购买量：" } },
                            [_c("div", [_vm._v("1")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "单次最大购买量：" } },
                            [_c("div", [_vm._v("10")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商城类型：" } },
                            [_c("div", [_vm._v("周边游")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品分类：" } },
                            [_c("div", [_vm._v("餐饮类-中餐-川菜")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商城类型：" } },
                            [_c("div", [_vm._v("周边游")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "封面图：" } }, [
                            _c("div", [
                              _c("img", {
                                staticStyle: { width: "64px", height: "17px" },
                                attrs: {
                                  src: require("@/assets/imgs/new_header1.png"),
                                  alt: "",
                                },
                              }),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "海报图片：" } },
                            [
                              _c("div", [
                                _c("img", {
                                  staticStyle: {
                                    width: "64px",
                                    height: "17px",
                                  },
                                  attrs: {
                                    src: require("@/assets/imgs/new_header1.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "分享图片：" } },
                            [
                              _c("div", [
                                _c("img", {
                                  staticStyle: {
                                    width: "64px",
                                    height: "17px",
                                  },
                                  attrs: {
                                    src: require("@/assets/imgs/new_header1.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "轮播图：" } }, [
                            _c("div", [
                              _c("img", {
                                staticStyle: { width: "64px", height: "17px" },
                                attrs: {
                                  src: require("@/assets/imgs/new_header1.png"),
                                  alt: "",
                                },
                              }),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商家资质：" } },
                            [
                              _c("div", [
                                _c("img", {
                                  staticStyle: {
                                    width: "64px",
                                    height: "17px",
                                  },
                                  attrs: {
                                    src: require("@/assets/imgs/new_header1.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabType == "G",
                  expression: "tabType == 'G'",
                },
              ],
              staticClass: "container mt-1",
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.groupData, size: "small" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "套餐ID", "min-width": "50" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "套餐名称",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "retailPrice",
                      label: "售价",
                      "min-width": "100",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input-number", {
                              staticStyle: { width: "70%" },
                              attrs: {
                                size: "small",
                                min: 0,
                                max: 99999,
                                precision: 2,
                                step: 1,
                              },
                              model: {
                                value: scope.row.retailPrice,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "retailPrice", $$v)
                                },
                                expression: "scope.row.retailPrice",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "purplePrice",
                      label: "原价",
                      "min-width": "100",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input-number", {
                              staticStyle: { width: "70%" },
                              attrs: {
                                size: "small",
                                min: 0,
                                max: 99999,
                                precision: 2,
                                step: 1,
                              },
                              model: {
                                value: scope.row.purplePrice,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "purplePrice", $$v)
                                },
                                expression: "scope.row.purplePrice",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "skl", label: "余额价", "min-width": "50" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "oldSkl",
                      label: "余额原价",
                      "min-width": "50",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "price",
                      label: "渠道结算价",
                      "min-width": "50",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "stock", label: "库存", "min-width": "50" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "max",
                      label: "最多购买",
                      "min-width": "50",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "num",
                      label: "发码数量",
                      "min-width": "50",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabType == "S",
                  expression: "tabType == 'S'",
                },
              ],
              staticClass: "container mt-1",
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.storeData, size: "small" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "id",
                      label: "商家编号",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "商家名称",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "skl", label: "经度", "min-width": "50" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "oldSkl", label: "纬度", "min-width": "50" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "price",
                      label: "所在城市",
                      "min-width": "50",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "stock", label: "电话", "min-width": "50" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "max", label: "地址", "min-width": "150" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabType == "D",
                  expression: "tabType == 'D'",
                },
              ],
              staticClass: "container mt-1",
            },
            [_vm._v("\n      商品详情\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }