"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
var _data2 = _interopRequireDefault(require("../data/data.json"));
var _labelImg = _interopRequireDefault(require("../assets/labelImg.png"));
var _icon_biaodian = _interopRequireDefault(require("../assets/icon_biaodian.png"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'item1Inner',
  computed: {},
  data: function data() {
    return {
      isCityClass: 14,
      navList: [{
        name: '成都',
        id: 1,
        value: [116.15428877945179, 40.32205252993779],
        left: 0,
        top: 0,
        scale: 1
      }, {
        name: '上海',
        id: 2,
        value: [116.15428877945179, 40.32205252993779],
        left: 0,
        top: 0,
        scale: 0.8
      }, {
        name: '西藏',
        id: 14,
        value: [91.150981, 29.64812],
        left: 0,
        top: 0,
        scale: 0.8
      }, {
        name: '四川',
        id: 3,
        value: [116.15428877945179, 40.32205252993779],
        left: 0,
        top: 0,
        scale: 0.8
      }, {
        name: '重庆',
        id: 4,
        value: [116.15428877945179, 40.32205252993779],
        left: 0,
        top: 0,
        scale: 0.8
      }, {
        name: '贵阳',
        id: 5,
        value: [112.98122725631805, 23.379101378110416],
        left: 0,
        top: 0,
        scale: 0.8
      }, {
        name: '北京',
        id: 6,
        value: [116.15428877945179, 40.32205252993779],
        left: 0,
        top: 0,
        scale: 0.8
      }, {
        name: '深圳',
        id: 7,
        value: [112.98122725631805, 23.379101378110416],
        left: 0,
        top: 0,
        scale: 0.8
      }, {
        name: '天津',
        id: 8,
        value: [96.87644329928071, 35.65226387325039],
        left: 0,
        top: 0,
        scale: 0.8
      }, {
        name: '黑龙江',
        id: 9,
        value: [116.15428877945179, 40.32205252993779],
        left: 0,
        top: 0,
        scale: 0.8
      }, {
        name: '长沙',
        id: 10,
        value: [116.15428877945179, 40.32205252993779],
        left: 0,
        top: 0,
        scale: 0.8
      }, {
        name: '湖南',
        id: 11
      }, {
        name: '广州',
        id: 12,
        value: [116.15428877945179, 40.32205252993779],
        left: 0,
        top: 0,
        scale: 0.8
      }, {
        name: '广西',
        id: 13,
        value: [116.15428877945179, 40.32205252993779],
        left: 0,
        top: 0,
        scale: 0.8
      }],
      move: 0,
      dataNavListRealWidth: 0,
      dataNavListViewWidth: 0,
      boxWidth: '100%',
      data: _data2.default,
      height: 0,
      width: 0,
      popShow: false,
      left: 0,
      top: 0,
      address: '',
      num: 10,
      popWidth: 0,
      popHeight: 0,
      cityList: [{
        name: '和林格尔1',
        id: '1'
      }, {
        name: '重庆',
        id: '2'
      }, {
        name: '西藏',
        id: '12'
      }, {
        name: '成都',
        id: '3'
      }, {
        name: '上海',
        id: '4'
      }, {
        name: '合肥',
        id: '5'
      }, {
        name: '四川1',
        id: '6'
      }, {
        name: '天津',
        id: '7'
      }, {
        name: '深圳',
        id: '8'
      }, {
        name: '贵阳',
        id: '9'
      }, {
        name: '长沙',
        id: '10'
      }, {
        name: '新疆',
        id: '11'
      }],
      list: [],
      serverdata: []
    };
  },
  watch: {},
  mounted: function mounted() {
    var _this = this;
    var obj = {
      name: '西藏',
      id: 14,
      value: [91.150981, 29.64812],
      left: 0,
      top: 0,
      scale: 0.8
    };
    this.clickCity(obj);
    // scrollWidth获取整个菜单实际宽度
    this.dataNavListRealWidth = this.$refs.dataNavList.scrollWidth;
    // offsetWidth获取菜单在当前页面可视宽度
    this.dataNavListViewWidth = this.$refs.dataNavList.offsetWidth;
    //实际宽度减去可视宽度就是可移动的范围即move的范围
    // 窗口大小变化时触发。实时更新可视宽度
    window.onresize = function () {
      return function () {
        _this.dataNavListViewWidth = _this.$refs.dataNavList.offsetWidth;
        //注1，详见下方
        if (_this.move > _this.dataNavListRealWidth - _this.dataNavListViewWidth - 70) {
          _this.move = _this.dataNavListRealWidth - _this.dataNavListViewWidth;
        }
      }();
    };
    var that = this;
    var viewElem = document.body;
    // 监听窗口变化,重绘echarts
    var resizeObserver = new ResizeObserver(function () {
      setTimeout(function () {
        that.drawEcharts();
      }, 300);
    });
    resizeObserver.observe(viewElem);
  },
  methods: {
    // 当菜单项向右的可移动距离大于单个菜单项的宽度时，move减去一个菜单项的宽度（即右移移动一个菜单项的宽度），
    // 当菜单项向右的可移动距离小于单个菜单项的宽度时，move等于0（即回到最开始没有移动的状态）
    navPrev: function navPrev() {
      if (this.move > 70) {
        this.move = this.move - 70;
      } else {
        this.move = 0;
      }
    },
    // 当菜单项向左的可移动距离大于单个菜单项的宽度时，move减去一个菜单项的宽度（即左移移动一个菜单项的宽度），
    // 当菜单项向左的可移动距离小于单个菜单项的宽度时，move等于可以向左移动的最大值（即到最末尾）
    navNext: function navNext() {
      if (this.move < this.dataNavListRealWidth - this.dataNavListViewWidth - 70) {
        this.move = this.move + 70;
      } else {
        this.move = this.dataNavListRealWidth - this.dataNavListViewWidth;
      }
    },
    drawEcharts: function drawEcharts() {
      var _this2 = this;
      this.width = this.$refs.img.offsetWidth;
      this.height = this.$refs.img.offsetHeight;
      this.$nextTick(function () {
        _this2.getEcharts();
      });
    },
    clickCity: function clickCity(type) {
      var that = this;
      this.isCityClass = type.id;
      var datamap = {
        type: 'scatter',
        tooltip: {
          show: true,
          formatter: function formatter(params) {
            return params.data.name;
          }
        },
        name: type.name,
        coordinateSystem: 'geo',
        symbol: 'image://' + _icon_biaodian.default,
        symbolSize: [31 * type.scale, 40 * type.scale],
        symbolOffset: [0 * type.scale, -20 * type.scale],
        hoverAnimation: true,
        z: 6,
        data: [type]
      };
      that.serverdata = [];
      that.serverdata.push(datamap);
      this.getEcharts();
    },
    getEcharts: function getEcharts() {
      var that = this;
      var chartDom = document.getElementById('centerMap');
      var myChart = echarts.init(chartDom);
      myChart.clear();
      myChart.resize();
      var nameMap = '地图数据';
      echarts.registerMap(nameMap, this.data);
      myChart.hideLoading();
      var mapInfo = [];
      //地图的坐标
      // this.list.forEach((type) => {
      //   var datamap = {
      //     type: 'scatter',
      //     tooltip: {
      //       show: true,
      //       formatter: function(params) {
      //         return params.data.name
      //       }
      //     },
      //     name: type.name,
      //     coordinateSystem: 'geo',
      //     symbol: 'image://' + icon_biaodian,
      //     symbolSize: [31 * type.scale, 40 * type.scale],
      //     symbolOffset: [0 * type.scale, (-20) * type.scale],
      //     hoverAnimation: true,
      //     z: 6,
      //     data: [type]
      //   }
      //   console.log("ddddddddddddddddddddddddddd")
      //   console.log(datamap)
      //   that.serverdata.push(datamap)
      // })

      var optionMap = {
        geo: {
          map: nameMap,
          show: true,
          aspectScale: 1.0,
          layoutCenter: ['49.5%', '48%'],
          layoutSize: '160%',
          roam: false,
          itemStyle: {
            normal: {
              borderColor: 'rgba(147, 235, 248, 0)',
              borderWidth: 0.5,
              areaColor: 'red',
              opacity: 0
            },
            emphasis: {
              borderColor: 'rgba(147, 235, 248, 0)',
              borderWidth: 0.5,
              areaColor: 'rgba(147, 235, 248, 0)',
              opacity: 0
            }
          },
          z: 0,
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: false
            }
          }
        },
        series: _toConsumableArray(that.serverdata)
      };
      myChart.clear();
      myChart.resize();
      myChart.setOption(optionMap);
      myChart.off('click');
      myChart.on('click', function (params) {
        console.log(params);
        that.left = params.event.event.offsetX;
        that.top = params.event.event.offsetY;
        // that.popShow = false
        // that.$nextTick(() => {
        //   that.popShow = true
        // })
        // that.address = params.name
        var data = myChart.convertFromPixel('geo', [that.left, that.top]);
        console.log(data);
        if (params.name) {
          var item = mapInfo.find(function (obj) {
            return obj.name === params.name;
          });
          if (item) {
            console.log(item.code); // 输出: 'code1'
            that.$emit('code-found', item.code);
          } else {
            console.log('没有找到对应的code');
          }
        }
      });
    }
  }
};