"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _storePoint = require("@/api/storePoint");
var _index = _interopRequireDefault(require("@/components/cards/index"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    cardsData: _index.default
  },
  data: function data() {
    return {
      storeSelectList: [],
      orderId: 0,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        keywords: '',
        storeId: '',
        dateLimit: '',
        page: 1,
        limit: 20
      },
      timeVal: [],
      fromList: this.$constants.fromList,
      ids: '',
      cardLists: []
    };
  },
  mounted: function mounted() {
    this.storeList();
    this.getList();
  },
  methods: {
    storeList: function storeList() {
      var _this = this;
      var artFrom = {
        page: 1,
        limit: 999,
        status: '1',
        keywords: ''
      };
      (0, _storePoint.storeListApi)(artFrom).then(function (res) {
        _this.storeSelectList = res.list;
      });
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList();
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList();
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.tableFrom.page = 1;
      this.timeVal = [];
      this.getList();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _storePoint.orderListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list.list;
        _this2.tableData.total = res.list.total;
        _this2.cardLists = [{
          name: '订单数量',
          count: res.total,
          color: '#1890FF',
          class: 'one',
          icon: 'icondingdan'
        }, {
          name: '订单金额',
          count: res.orderTotalPrice,
          color: '#A277FF',
          class: 'two',
          icon: 'icondingdanjine'
        }, {
          name: '退款总单数',
          count: res.refundTotal,
          color: '#EF9C20',
          class: 'three',
          icon: 'icondingdanguanli'
        }, {
          name: '退款总金额',
          count: res.refundTotalPrice,
          color: '#1BBE6B',
          class: 'four',
          icon: 'iconshangpintuikuanjine'
        }];
        // this.cardLists = res.data.stat
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};