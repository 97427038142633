"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _task = require("@/api/task");
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var obj = {
  title: '',
  price: 1,
  dayYield: 1,
  activeDays: 1,
  totalYield: 1,
  activeness: 1,
  maxCount: 1,
  taskQuantity: 1,
  status: 1
};
var _default = exports.default = {
  name: "CreateUsertask",
  props: {
    'userTaskPackage': Object
  },
  data: function data() {
    return {
      dialogVisible: false,
      formValidate: Object.assign({}, obj),
      loading: false,
      rules: {
        title: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          message: '请输入余额兑换价格',
          trigger: 'blur'
        }
        // { type: 'number', message: '出售金额须为数字值'}
        ],
        dayYield: [{
          required: true,
          message: '请输入每日余额收益',
          trigger: 'blur'
        }
        // { type: 'number', message: '日产量须为数字值'}
        ],
        totalActiveDays: [{
          required: true,
          message: '请输入总活跃天数',
          trigger: 'blur'
        }
        // { type: 'number', message: '有效周期须为数字值'}
        ],
        activeness: [{
          required: true,
          message: '请输活跃度',
          trigger: 'blur'
        }
        // { type: 'number', message: '活跃度须为数字值'}
        ],
        totalYield: [{
          required: true,
          message: '请输入总余额收益',
          trigger: 'blur'
        }
        // { type: 'number', message: '完成任务数量须为数字值'}
        ]
      }
    };
  },
  methods: {
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    resetForm: function resetForm(formName) {
      this.dialogVisible = false;
      this[formName] = {};
    },
    submitForm: (0, _validate.Debounce)(function (formName) {
      var _this = this;
      //userTaskPackage与'userTaskPackage':Object名称一样
      this.$refs.userTaskPackage.validate(function (valid) {
        if (valid) {
          _this.loading = true;
          var data = {
            title: _this.userTaskPackage.title,
            price: _this.userTaskPackage.price,
            dayYield: _this.userTaskPackage.dayYield,
            totalActiveDays: _this.userTaskPackage.totalActiveDays,
            totalYield: _this.userTaskPackage.activeness,
            status: _this.userTaskPackage.status,
            remark: _this.userTaskPackage.remark
          };
          (0, _task.usertaskPackageUpdateApi)(_this.userTaskPackage.id, data).then(function (res) {
            _this.$message.success('编辑成功');
            _this.loading = false;
            _this.handleClose();
            _this.formValidate = Object.assign({}, obj);
            _this.$parent.getList();
          }).catch(function () {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    })
  }
};