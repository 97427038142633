"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'rightCenter',
  computed: {},
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var contentId = document.getElementById('contentId');
      var myChart = echarts.init(contentId);
      var option = {
        title: {
          text: '',
          left: '25%',
          top: '48%',
          textStyle: {
            fontSize: 18,
            fontWeight: 'normal',
            width: 50,
            color: '#0DCEED',
            height: 60
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          data: [{
            value: "51%",
            name: '抖音',
            itemStyle: {
              borderColor: '#0ba5ff'
            }
          }, {
            value: "29%",
            name: '快手',
            itemStyle: {
              borderColor: '#0ba5ff'
            }
          }, {
            value: "20%",
            name: '小红书',
            itemStyle: {
              borderColor: '#0ba5ff'
            }
          }],
          textStyle: {
            fontSize: 12,
            color: '#fff' // 设置字体颜色为黑色
          },
          itemStyle: {
            color: '#fff',
            // 间隔
            borderWidth: 1,
            borderColor: "#0ba5ff"
          },
          orient: 'vertical',
          top: '20%',
          left: '58%',
          //列表的间距
          itemGap: 10,
          itemWidth: 14,
          // 设置图例图形的宽
          itemHeight: 12,
          //icon类型：'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
          icon: 'circle',
          formatter: function formatter(name) {
            var index = 0;
            var clientlabels = ['抖音', '快手', '小红书'];
            var clientcounts = ["51%", "29%", "20%"];
            clientlabels.forEach(function (value, i) {
              if (value == name) {
                index = i;
              }
            });
            return name + '  ' + clientcounts[index];
          },
          // 图例关闭后的描边颜色
          inactiveColor: '#fff'
        },
        series: [{
          name: '323',
          type: 'pie',
          center: ['30%', '54%'],
          radius: ['46%', '74%'],
          // 标签不会重叠
          avoidLabelOverlap: true,
          label: {
            show: false,
            position: 'center'
          },
          // 选中时候的样式
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          itemStyle: {
            // 间隔
            borderWidth: 0,
            borderColor: '#fff'
          },
          labelLine: {
            show: true
          },
          data: [{
            value: 51,
            name: '抖音',
            itemStyle: {
              color: '#0ba5ff'
            }
          }, {
            value: 29,
            name: '快手',
            itemStyle: {
              color: '#fcd501'
            }
          }, {
            value: 20,
            name: '小红书',
            itemStyle: {
              color: '#7cdaff'
            }
          }]
        }]
      };
      myChart.setOption(option);
    }
  }
};