var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-tabs",
                {
                  on: {
                    "tab-click": function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.loginType,
                    callback: function ($$v) {
                      _vm.loginType = $$v
                    },
                    expression: "loginType",
                  },
                },
                _vm._l(_vm.headeNum, function (item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: { label: item.name, name: item.type.toString() },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _vm.loginType != "detailAccount"
                ? _c(
                    "div",
                    { staticClass: "container" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "userFrom",
                          attrs: {
                            inline: "",
                            size: "small",
                            model: _vm.userFrom,
                            "label-position": _vm.labelPosition,
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 24,
                                    lg: 18,
                                    xl: 18,
                                  },
                                },
                                [
                                  _c(
                                    "el-col",
                                    _vm._b({}, "el-col", _vm.grid, false),
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "用户搜索：" } },
                                        [
                                          _c("el-input", {
                                            staticClass: "selWidth",
                                            attrs: {
                                              placeholder: "请输入昵称或手机号",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.userFrom.keywords,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.userFrom,
                                                  "keywords",
                                                  $$v
                                                )
                                              },
                                              expression: "userFrom.keywords",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    _vm._b({}, "el-col", _vm.grid, false),
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "设备状态：" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "selWidth",
                                              attrs: {
                                                placeholder: "设备状态",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.userFrom.eqStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.userFrom,
                                                    "eqStatus",
                                                    $$v
                                                  )
                                                },
                                                expression: "userFrom.eqStatus",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "未激活",
                                                  value: 0,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "激活中",
                                                  value: 1,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "正常",
                                                  value: 2,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    _vm._b({}, "el-col", _vm.grid, false),
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "用户类型：" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "selWidth",
                                              attrs: {
                                                placeholder: "用户类型",
                                                clearable: "",
                                              },
                                              on: { change: _vm.busTypeChange },
                                              model: {
                                                value: _vm.userFrom.busType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.userFrom,
                                                    "busType",
                                                    $$v
                                                  )
                                                },
                                                expression: "userFrom.busType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.commonEnums.userBusTypeEnums,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    _vm._b({}, "el-col", _vm.grid, false),
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "用户等级：" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "selWidth",
                                              attrs: {
                                                placeholder: "用户等级",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.userFrom.level,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.userFrom,
                                                    "level",
                                                    $$v
                                                  )
                                                },
                                                expression: "userFrom.level",
                                              },
                                            },
                                            _vm._l(
                                              _vm.userBusTypeLevelEnums,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.collapse
                                ? [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 24,
                                          md: 24,
                                          lg: 18,
                                          xl: 18,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          _vm._b({}, "el-col", _vm.grid, false),
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: { label: "用户状态：" },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass: "selWidth",
                                                    attrs: {
                                                      placeholder: "请选择",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.userFrom.status,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.userFrom,
                                                          "status",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "userFrom.status",
                                                    },
                                                  },
                                                  [
                                                    _c("el-option", {
                                                      attrs: {
                                                        value: 1,
                                                        label: "正常",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-option", {
                                                      attrs: {
                                                        value: 0,
                                                        label: "禁止",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          _vm._b({}, "el-col", _vm.grid, false),
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: { label: "用户标签：" },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass: "selWidth",
                                                    attrs: {
                                                      placeholder: "请选择",
                                                      clearable: "",
                                                      filterable: "",
                                                      multiple: "",
                                                    },
                                                    model: {
                                                      value: _vm.labelData,
                                                      callback: function ($$v) {
                                                        _vm.labelData = $$v
                                                      },
                                                      expression: "labelData",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.labelLists,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          value: item.id,
                                                          label: item.name,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 24,
                                          md: 24,
                                          lg: 18,
                                          xl: 18,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          _vm._b({}, "el-col", _vm.grid, false),
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: { label: "认证地址：" },
                                              },
                                              [
                                                _c("el-cascader", {
                                                  staticClass: "selWidth",
                                                  attrs: {
                                                    options: _vm.addresData,
                                                    props: _vm.propsCity,
                                                    filterable: "",
                                                    clearable: "",
                                                  },
                                                  on: {
                                                    change: _vm.handleChange,
                                                  },
                                                  model: {
                                                    value: _vm.address,
                                                    callback: function ($$v) {
                                                      _vm.address = $$v
                                                    },
                                                    expression: "address",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          _vm._b({}, "el-col", _vm.grid, false),
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "timeBox",
                                                attrs: { label: "时间选择：" },
                                              },
                                              [
                                                _c("el-date-picker", {
                                                  staticClass: "selWidth",
                                                  attrs: {
                                                    align: "right",
                                                    "unlink-panels": "",
                                                    "value-format":
                                                      "yyyy-MM-dd",
                                                    format: "yyyy-MM-dd",
                                                    size: "small",
                                                    type: "daterange",
                                                    placement: "bottom-end",
                                                    placeholder: "自定义时间",
                                                    "picker-options":
                                                      _vm.pickerOptions,
                                                  },
                                                  on: {
                                                    change: _vm.onchangeTime,
                                                  },
                                                  model: {
                                                    value: _vm.timeVal,
                                                    callback: function ($$v) {
                                                      _vm.timeVal = $$v
                                                    },
                                                    expression: "timeVal",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 24,
                                          md: 24,
                                          lg: 18,
                                          xl: 18,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          _vm._b({}, "el-col", _vm.grid, false),
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "推荐人搜索：",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "selWidth",
                                                  attrs: {
                                                    placeholder: "请输入手机号",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.userFrom
                                                        .spreadNickname,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.userFrom,
                                                        "spreadNickname",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "userFrom.spreadNickname",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          _vm._b({}, "el-col", _vm.grid, false),
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: { label: "访问情况：" },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass: "selWidth",
                                                    attrs: {
                                                      placeholder: "请选择",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.userFrom.accessType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.userFrom,
                                                          "accessType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "userFrom.accessType",
                                                    },
                                                  },
                                                  [
                                                    _c("el-option", {
                                                      attrs: {
                                                        value: 0,
                                                        label: "全部",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-option", {
                                                      attrs: {
                                                        value: 1,
                                                        label: "首次访问",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-option", {
                                                      attrs: {
                                                        value: 2,
                                                        label: "时间段访问过",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-option", {
                                                      attrs: {
                                                        value: 3,
                                                        label: "时间段未访问",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 24,
                                          md: 24,
                                          lg: 18,
                                          xl: 18,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          _vm._b({}, "el-col", _vm.grid, false),
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "性别：" } },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    staticClass: "selWidth",
                                                    attrs: { type: "button" },
                                                    model: {
                                                      value: _vm.userFrom.sex,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.userFrom,
                                                          "sex",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "userFrom.sex",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "" } },
                                                      [
                                                        _c("span", [
                                                          _vm._v("全部"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "0" } },
                                                      [
                                                        _c("span", [
                                                          _vm._v("未知"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "1" } },
                                                      [
                                                        _c("span", [
                                                          _vm._v("男"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "2" } },
                                                      [
                                                        _c("span", [
                                                          _vm._v("女"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "3" } },
                                                      [
                                                        _c("span", [
                                                          _vm._v("保密"),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          _vm._b({}, "el-col", _vm.grid, false),
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "身份：" } },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    staticClass: "selWidth",
                                                    attrs: { type: "button" },
                                                    model: {
                                                      value:
                                                        _vm.userFrom.isPromoter,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.userFrom,
                                                          "isPromoter",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "userFrom.isPromoter",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "" } },
                                                      [
                                                        _c("span", [
                                                          _vm._v("全部"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "1" } },
                                                      [
                                                        _c("span", [
                                                          _vm._v("推广员"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-radio-button",
                                                      { attrs: { label: "0" } },
                                                      [
                                                        _c("span", [
                                                          _vm._v("普通用户"),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "text-right userFrom",
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 24,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "mr15",
                                          attrs: {
                                            type: "primary",
                                            icon: "ios-search",
                                            label: "default",
                                            size: "small",
                                          },
                                          on: { click: _vm.userSearchs },
                                        },
                                        [_vm._v("搜索")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "ResetSearch mr10",
                                          attrs: { size: "small" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.reset("userFrom")
                                            },
                                          },
                                        },
                                        [_vm._v("重置")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "ivu-ml-8",
                                          on: {
                                            click: function ($event) {
                                              _vm.collapse = !_vm.collapse
                                            },
                                          },
                                        },
                                        [
                                          !_vm.collapse
                                            ? [
                                                _vm._v(
                                                  "\n                      展开 "
                                                ),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down",
                                                }),
                                              ]
                                            : [
                                                _vm._v(
                                                  "\n                      收起 "
                                                ),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-up",
                                                }),
                                              ],
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.loginType == "detailAccount"
                ? _c(
                    "div",
                    { staticClass: "container" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "userFrom",
                          attrs: {
                            inline: "",
                            size: "small",
                            model: _vm.userFrom,
                            "label-position": _vm.labelPosition,
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 24,
                                    lg: 18,
                                    xl: 18,
                                  },
                                },
                                [
                                  _c(
                                    "el-col",
                                    _vm._b({}, "el-col", _vm.grid, false),
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "用户搜索：" } },
                                        [
                                          _c("el-input", {
                                            staticClass: "selWidth",
                                            attrs: {
                                              placeholder: "请输入昵称或手机号",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.userFrom.keywords,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.userFrom,
                                                  "keywords",
                                                  $$v
                                                )
                                              },
                                              expression: "userFrom.keywords",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    _vm._b({}, "el-col", _vm.grid, false),
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "用户类型：" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "selWidth",
                                              attrs: {
                                                placeholder: "用户类型",
                                                clearable: "",
                                              },
                                              on: { change: _vm.busTypeChange },
                                              model: {
                                                value: _vm.userFrom.busType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.userFrom,
                                                    "busType",
                                                    $$v
                                                  )
                                                },
                                                expression: "userFrom.busType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.commonEnums.userBusTypeEnums,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                _vm._b({}, "el-col", _vm.grid, false),
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "timeBox",
                                      attrs: { label: "时间选择：" },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticClass: "selWidth",
                                        attrs: {
                                          align: "right",
                                          "unlink-panels": "",
                                          "value-format": "yyyy-MM-dd",
                                          format: "yyyy-MM-dd",
                                          size: "small",
                                          type: "daterange",
                                          placement: "bottom-end",
                                          placeholder: "自定义时间",
                                          "picker-options": _vm.pickerOptions,
                                        },
                                        on: { change: _vm.onchangeTime },
                                        model: {
                                          value: _vm.timeVal,
                                          callback: function ($$v) {
                                            _vm.timeVal = $$v
                                          },
                                          expression: "timeVal",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "text-right userFrom",
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 24,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "ResetSearch mr10",
                                          attrs: { size: "small" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.reset("userFrom")
                                            },
                                          },
                                        },
                                        [_vm._v("重置")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.loginType != "detailAccount"
                ? _c(
                    "div",
                    { staticClass: "btn_bt" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "mr10",
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.setBatch("label")
                            },
                          },
                        },
                        [_vm._v("批量设置标签")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "mr10",
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.importFile()
                            },
                          },
                        },
                        [_vm._v("导入注册用户")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["admin:user:frozen"],
                              expression: "['admin:user:frozen']",
                            },
                          ],
                          staticClass: "mr10",
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.setBatch("statusUser")
                            },
                          },
                        },
                        [_vm._v("批量冻结/解冻用户")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["admin:user:defaultInviter"],
                              expression: "['admin:user:defaultInviter']",
                            },
                          ],
                          staticClass: "mr10",
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.setDefaultExtension()
                            },
                          },
                        },
                        [_vm._v("默认上级推荐人")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "mr10",
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.setBatch("skl")
                            },
                          },
                        },
                        [_vm._v("批量赠送余额")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.loginType == "detailAccount"
                ? _c(
                    "div",
                    { staticClass: "btn_bt" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "default",
                            size: "small",
                            icon: "el-icon-upload2",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.accountFile()
                            },
                          },
                        },
                        [_vm._v("明细导出")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        title: "上传文件",
                        visible: _vm.uploadDialogVisible,
                        width: "950px",
                        "append-to-body": "",
                        "before-close": _vm.handleExcelDialogClose,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.uploadDialogVisible = $event
                        },
                      },
                    },
                    [
                      _vm.uploadDialogVisible
                        ? _c("upload-excel", { on: { flushlist: _vm.getList } })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.loginType != "detailAccount"
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData.data,
                    size: "mini",
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.onSelectTab },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "expand" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(
                                "el-form",
                                {
                                  staticClass: "demo-table-expand",
                                  attrs: {
                                    "label-position": "left",
                                    inline: "",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "身份：" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("filterIsPromoter")(
                                              props.row.isPromoter
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "首次访问：" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("filterEmpty")(
                                              props.row.createTime
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "近次访问：" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("filterEmpty")(
                                              props.row.lastLoginTime
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "手机号：" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("filterEmpty")(
                                              props.row.phone
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "标签：" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("filterEmpty")(
                                              props.row.tagName
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "地址：" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("filterEmpty")(
                                              props.row.addres
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        display: "flex",
                                        "margin-right": "10px",
                                      },
                                      attrs: { label: "备注：" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("filterEmpty")(
                                              props.row.mark
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      955571758
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _vm.checkedCities.includes("ID")
                    ? _c("el-table-column", {
                        attrs: { prop: "uid", label: "ID", "min-width": "80" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkedCities.includes("用户昵称")
                    ? _c("el-table-column", {
                        attrs: { label: "用户昵称", "min-width": "160" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("filterEmpty")(
                                          scope.row.realName
                                        )
                                      ) +
                                        " | " +
                                        _vm._s(
                                          _vm._f("sexFilter")(scope.row.sex)
                                        )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2752523071
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkedCities.includes("用户类型")
                    ? _c("el-table-column", {
                        attrs: { label: "用户类型", "min-width": "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("filterEmpty")(
                                          _vm._f("userBusTypeTypeFilter")(
                                            scope.row.busType
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2977749818
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkedCities.includes("余额")
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "integral",
                          label: "余额",
                          "min-width": "100",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkedCities.includes("用户等级")
                    ? _c("el-table-column", {
                        attrs: { label: "用户等级", "min-width": "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.busType == 0
                                    ? _c("span", [_vm._v("-")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.busType == 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("filterEmpty")(
                                              _vm._f(
                                                "userBusType1LevelTypeFilter"
                                              )(scope.row.level)
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.busType == 2
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("filterEmpty")(
                                              _vm._f(
                                                "userBusType2LevelTypeFilter"
                                              )(scope.row.level)
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          439466517
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkedCities.includes("状态")
                    ? _c("el-table-column", {
                        attrs: { label: "状态", "min-width": "80" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(scope.row.status ? "正常" : "禁止")
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3138032207
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkedCities.includes("设备状态")
                    ? _c("el-table-column", {
                        attrs: { label: "设备状态", "min-width": "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.eqStatus == 0
                                          ? "未激活"
                                          : scope.row.eqStatus == 1
                                          ? "激活中"
                                          : scope.row.eqStatus == 2
                                          ? "正常"
                                          : scope.row.eqStatus || "-"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          543495873
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkedCities.includes("推荐人")
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "spreadNickname",
                          label: "推荐人",
                          "min-width": "130",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkedCities.includes("手机号")
                    ? _c("el-table-column", {
                        attrs: { label: "手机号", "min-width": "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("filterEmpty")(scope.row.phone)
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3743808278
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkedCities.includes("认证地址")
                    ? _c("el-table-column", {
                        attrs: { label: "认证地址", "min-width": "130" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.addres)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          790234147
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "130",
                      fixed: "right",
                      align: "center",
                      "render-header": _vm.renderHeader,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["admin:user:infobycondition"],
                                      expression:
                                        "['admin:user:infobycondition']",
                                    },
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editUser(scope.row.uid)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown",
                                { attrs: { trigger: "click" } },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _vm._v("\n                更多"),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _vm.checkPermi(["admin:user:topdetail"])
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.onDetails(
                                                    scope.row.uid
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("账户详情")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.checkPermi([
                                        "admin:user:operate:founds",
                                      ])
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.editPoint(
                                                    scope.row.uid
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("余额")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.checkPermi(["admin:user:tag"])
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.setBatch(
                                                    "label",
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("设置标签")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.checkPermi([
                                        "admin:user:update:phone",
                                      ])
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.setPhone(scope.row)
                                                },
                                              },
                                            },
                                            [_vm._v("修改手机号")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.checkPermi([
                                        "admin:user:change:status",
                                      ])
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.setStatus(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("修改状态")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.checkPermi(["admin:user:real"])
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.setRealAuth(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("认证/解除实名")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.checkPermi([
                                        "admin:user:update:spread",
                                      ])
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.setExtension(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("修改上级推广人")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.spreadUid &&
                                      scope.row.spreadUid > 0 &&
                                      _vm.checkPermi([
                                        "admin:retail:spread:clean",
                                      ])
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.clearSpread(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("清除上级推广人")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.checkPermi(["admin:user:register"])
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.addRegisterUsers(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("手动注册用户")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      794985166
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.loginType != "detailAccount"
            ? _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [50, 100, 200, 500, 1000],
                      "page-size": _vm.userFrom.limit,
                      "current-page": _vm.userFrom.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.card_select_show,
              expression: "card_select_show",
            },
          ],
          staticClass: "card_abs",
          style: { top: _vm.collapse ? 570 + "px" : 270 + "px" },
        },
        [
          [
            _c(
              "div",
              { staticClass: "cell_ht" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [_vm._v("全选")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.checkSave()
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                on: { change: _vm.handleCheckedCitiesChange },
                model: {
                  value: _vm.checkedCities,
                  callback: function ($$v) {
                    _vm.checkedCities = $$v
                  },
                  expression: "checkedCities",
                },
              },
              _vm._l(_vm.columnData, function (item) {
                return _c(
                  "el-checkbox",
                  {
                    key: item,
                    staticClass: "check_cell",
                    attrs: { label: item },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改推广人",
            visible: _vm.extensionVisible,
            width: "500px",
            "before-close": _vm.handleCloseExtension,
          },
          on: {
            "update:visible": function ($event) {
              _vm.extensionVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "formExtension",
              staticClass: "formExtension mt20",
              attrs: {
                model: _vm.formExtension,
                rules: _vm.ruleInline,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户头像：", prop: "image" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: { click: _vm.modalPicTap },
                    },
                    [
                      _vm.formExtension.image
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: { src: _vm.formExtension.image },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubExtension("formExtension")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户列表",
            visible: _vm.userVisible,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible = $event
            },
          },
        },
        [
          _vm.userVisible
            ? _c("user-list", { on: { getTemplateRow: _vm.getTemplateRow } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置",
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "dynamicValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.dynamicValidateForm, "label-width": "100px" },
            },
            [
              _vm.batchName === "group"
                ? _c(
                    "el-form-item",
                    {
                      key: "1",
                      attrs: {
                        prop: "groupId",
                        label: "用户分组",
                        rules: [
                          {
                            required: true,
                            message: "请选择用户分组",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80%" },
                          attrs: { placeholder: "请选择分组", filterable: "" },
                          model: {
                            value: _vm.dynamicValidateForm.groupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dynamicValidateForm, "groupId", $$v)
                            },
                            expression: "dynamicValidateForm.groupId",
                          },
                        },
                        _vm._l(_vm.groupList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.id, label: item.groupName },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.batchName === "label"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "groupId",
                        label: "用户标签",
                        rules: [
                          {
                            required: true,
                            message: "请选择用户标签",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80%" },
                          attrs: { placeholder: "请选择标签", filterable: "" },
                          model: {
                            value: _vm.dynamicValidateForm.groupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dynamicValidateForm, "groupId", $$v)
                            },
                            expression: "dynamicValidateForm.groupId",
                          },
                        },
                        _vm._l(_vm.labelLists, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.id, label: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.batchName === "statusUser"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "groupId",
                        label: "用户状态",
                        rules: [
                          {
                            required: true,
                            message: "请选择用户状态",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.dynamicValidateForm.groupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dynamicValidateForm, "groupId", $$v)
                            },
                            expression: "dynamicValidateForm.groupId",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("解冻"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "0" } }, [
                            _vm._v("冻结"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.batchName === "statusUser" &&
              _vm.dynamicValidateForm.groupId == 0
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "frozenDays",
                        label: "冻结有效期",
                        rules: [
                          {
                            required: true,
                            message: "请输入选择冻结有效期",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          format: "yyyy-MM-dd",
                          type: "date",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.dynamicValidateForm.frozenDays,
                          callback: function ($$v) {
                            _vm.$set(_vm.dynamicValidateForm, "frozenDays", $$v)
                          },
                          expression: "dynamicValidateForm.frozenDays",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.batchName === "skl"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "integralValue",
                        label: "赠送余额",
                        rules: [
                          {
                            required: true,
                            message: "请输入",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          type: "text",
                          "step-strictly": "",
                          precision: 2,
                          step: 0.01,
                          min: 0.01,
                          max: 99999,
                        },
                        model: {
                          value: _vm.dynamicValidateForm.integralValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dynamicValidateForm,
                              "integralValue",
                              $$v
                            )
                          },
                          expression: "dynamicValidateForm.integralValue",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.batchName === "skl"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "smsCode",
                        label: "短信验证码",
                        rules: [
                          {
                            required: true,
                            message: "请输入",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "acea-row",
                          staticStyle: { "flex-wrap": "nowrap" },
                        },
                        [
                          _c("el-input", {
                            ref: "smsCode",
                            staticStyle: { width: "auto" },
                            attrs: {
                              placeholder: "验证码",
                              name: "smsCode",
                              type: "text",
                              tabindex: "1",
                              autocomplete: "off",
                              "prefix-icon": "el-icon-message",
                              clearable: "",
                              maxlength: "10",
                            },
                            model: {
                              value: _vm.dynamicValidateForm.smsCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dynamicValidateForm,
                                  "smsCode",
                                  $$v
                                )
                              },
                              expression: "dynamicValidateForm.smsCode",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", disabled: !_vm.canClick },
                              on: { click: _vm.cutDown },
                            },
                            [_vm._v(_vm._s(_vm.cutNUm))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("dynamicValidateForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.visible, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _vm.visible
            ? _c("edit-from", {
                attrs: { uid: _vm.uid },
                on: { resetForm: _vm.resetForm },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "余额",
            visible: _vm.VisiblePoint,
            width: "500px",
            "close-on-click-modal": false,
            "before-close": _vm.handlePointClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.VisiblePoint = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingPoint,
                  expression: "loadingPoint",
                },
              ],
              ref: "PointValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.PointValidateForm, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "修改余额", required: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.PointValidateForm.integralType,
                        callback: function ($$v) {
                          _vm.$set(_vm.PointValidateForm, "integralType", $$v)
                        },
                        expression: "PointValidateForm.integralType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("增加")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("减少")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "余额", required: "" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      type: "text",
                      "step-strictly": "",
                      precision: 2,
                      step: 0.01,
                      min: 0,
                      max: 999999,
                    },
                    model: {
                      value: _vm.PointValidateForm.integralValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.PointValidateForm, "integralValue", $$v)
                      },
                      expression: "PointValidateForm.integralValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "smsCode",
                    label: "短信验证码",
                    rules: [
                      { required: true, message: "请输入", trigger: "change" },
                    ],
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "acea-row",
                      staticStyle: { "flex-wrap": "nowrap" },
                    },
                    [
                      _c("el-input", {
                        ref: "smsCode",
                        staticStyle: { width: "auto" },
                        attrs: {
                          placeholder: "验证码",
                          name: "smsCode",
                          type: "text",
                          tabindex: "1",
                          autocomplete: "off",
                          "prefix-icon": "el-icon-message",
                          clearable: "",
                          maxlength: "10",
                        },
                        model: {
                          value: _vm.PointValidateForm.smsCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.PointValidateForm, "smsCode", $$v)
                          },
                          expression: "PointValidateForm.smsCode",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", disabled: !_vm.canClick },
                          on: { click: _vm.cutDown },
                        },
                        [_vm._v(_vm._s(_vm.cutNUm))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handlePointClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingBtn },
                  on: {
                    click: function ($event) {
                      return _vm.submitPointForm("PointValidateForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.uid
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "用户详情",
                visible: _vm.Visible,
                width: "1100px",
                "before-close": _vm.Close,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.Visible = $event
                },
              },
            },
            [
              _vm.Visible
                ? _c("user-details", {
                    ref: "userDetails",
                    attrs: { uid: _vm.uid },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户星级",
            visible: _vm.levelVisible,
            width: "600px",
            "before-close": _vm.Close,
          },
          on: {
            "update:visible": function ($event) {
              _vm.levelVisible = $event
            },
          },
        },
        [
          _c("level-edit", {
            attrs: { levelInfo: _vm.levelInfo, levelList: _vm.levelList },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "系统顾客数",
            visible: _vm.leveSysCountlInfoVisible,
            width: "600px",
            "before-close": _vm.Close,
          },
          on: {
            "update:visible": function ($event) {
              _vm.leveSysCountlInfoVisible = $event
            },
          },
        },
        [
          _c("level-sysnum-edit", {
            attrs: { levelInfo: _vm.levelInfo, levelList: _vm.levelList },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "智能风控",
            visible: _vm.riskListUsersVisible,
            width: "800px",
            "before-close": _vm.Close,
          },
          on: {
            "update:visible": function ($event) {
              _vm.riskListUsersVisible = $event
            },
          },
        },
        [
          _vm.riskListUsersVisible
            ? _c("net-risk", { attrs: { datas: _vm.riskListUsersForm } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "贡献值",
            visible: _vm.userContributionVisible,
            width: "800px",
            "before-close": _vm.Close,
          },
          on: {
            "update:visible": function ($event) {
              _vm.userContributionVisible = $event
            },
          },
        },
        [
          _vm.userContributionVisible
            ? _c("user-contribution", {
                attrs: { datas: _vm.userContributionForm },
                on: { close: _vm.Close },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "赠送日产积分",
            visible: _vm.addDailySklVisible,
            width: "500px",
            "close-on-click-modal": false,
            "before-close": _vm.handleCloseAddDailySkl,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addDailySklVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "addDailySklForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.addDailySklForm, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "赠送类型", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "赠送类型" },
                      model: {
                        value: _vm.addDailySklForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.addDailySklForm, "type", $$v)
                        },
                        expression: "addDailySklForm.type",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "余额", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "点卡", value: 2 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "总积分", required: "" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      type: "text",
                      precision: 2,
                      step: 0.01,
                      min: 0,
                      max: 99999,
                    },
                    model: {
                      value: _vm.addDailySklForm.totalSkl,
                      callback: function ($$v) {
                        _vm.$set(_vm.addDailySklForm, "totalSkl", $$v)
                      },
                      expression: "addDailySklForm.totalSkl",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "总收益次数", required: "" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      type: "text",
                      "step-strictly": "",
                      min: 0,
                      precision: 0,
                      max: 99999,
                    },
                    model: {
                      value: _vm.addDailySklForm.totalEarnCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.addDailySklForm, "totalEarnCount", $$v)
                      },
                      expression: "addDailySklForm.totalEarnCount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCloseAddDailySkl } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitAddDailySklForm("addDailySklForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "虚拟用户",
            visible: _vm.virtualVisible,
            width: "600px",
            "before-close": _vm.handleCloseVirtual,
          },
          on: {
            "update:visible": function ($event) {
              _vm.virtualVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "virtualUserForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.virtualUserForm, "label-width": "100px" },
            },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                model: {
                  value: _vm.virtualUserForm.uid,
                  callback: function ($$v) {
                    _vm.$set(_vm.virtualUserForm, "uid", $$v)
                  },
                  expression: "virtualUserForm.uid",
                },
              }),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "会员昵称" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm._f("filterEmpty")(_vm.virtualUserForm.nickname)
                    ) +
                    " | " +
                    _vm._s(_vm._f("sexFilter")(_vm.virtualUserForm.sex)) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "会员手机" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("filterEmpty")(_vm.virtualUserForm.phone)) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "注册区域",
                    prop: "addressId",
                    rules: [
                      {
                        required: true,
                        message: "请选择注册区域",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("el-cascader", {
                    staticClass: "dialogWidth",
                    staticStyle: { width: "250px" },
                    attrs: {
                      filterable: "",
                      clearable: "",
                      options: _vm.addresData,
                      props: {
                        value: "cityId",
                        label: "name",
                        children: "child",
                      },
                    },
                    on: { change: _vm.handleChangeVirtualUserForm },
                    model: {
                      value: _vm.virtualUserForm.addressId,
                      callback: function ($$v) {
                        _vm.$set(_vm.virtualUserForm, "addressId", $$v)
                      },
                      expression: "virtualUserForm.addressId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "registType",
                    label: "推荐方式",
                    rules: [
                      {
                        required: true,
                        message: "请选择推荐注册方式",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: {
                        placeholder: "请选择推荐注册方式",
                        filterable: "",
                      },
                      model: {
                        value: _vm.virtualUserForm.registType,
                        callback: function ($$v) {
                          _vm.$set(_vm.virtualUserForm, "registType", $$v)
                        },
                        expression: "virtualUserForm.registType",
                      },
                    },
                    _vm._l(_vm.registTypes, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { value: item.value, label: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否实名",
                    rules: [
                      {
                        required: true,
                        message: "请选择是否实名注册",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.virtualUserForm.realFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.virtualUserForm, "realFlag", $$v)
                        },
                        expression: "virtualUserForm.realFlag",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("仅注册"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("实名注册"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "注册人数",
                    rules: [
                      {
                        required: true,
                        message: "请输入注册人数",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: { placeholder: "请输入注册人数", clearable: "" },
                    model: {
                      value: _vm.virtualUserForm.registCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.virtualUserForm, "registCount", $$v)
                      },
                      expression: "virtualUserForm.registCount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCloseVirtual } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitVirtualUserForm("virtualUserForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改状态",
            visible: _vm.statusVisible,
            width: "600px",
            "before-close": _vm.handleCloseStatus,
          },
          on: {
            "update:visible": function ($event) {
              _vm.statusVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "statusUserForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.statusUserForm, "label-width": "150px" },
            },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                model: {
                  value: _vm.statusUserForm.uid,
                  callback: function ($$v) {
                    _vm.$set(_vm.statusUserForm, "uid", $$v)
                  },
                  expression: "statusUserForm.uid",
                },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "status",
                    label: "用户状态",
                    rules: [
                      {
                        required: true,
                        message: "请选择用户状态",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.statusUserForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.statusUserForm, "status", $$v)
                        },
                        expression: "statusUserForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("冻结"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("解冻"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.statusUserForm.status == "0"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "frozenDays",
                        label: "冻结有效期",
                        rules: [
                          {
                            required: true,
                            message: "请输入冻结有效期",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          format: "yyyy-MM-dd",
                          type: "date",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.statusUserForm.frozenDays,
                          callback: function ($$v) {
                            _vm.$set(_vm.statusUserForm, "frozenDays", $$v)
                          },
                          expression: "statusUserForm.frozenDays",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "禁止用户兑换任务包",
                    rules: [
                      {
                        required: true,
                        message: "请选择是否禁止用户兑换任务包",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.statusUserForm.statusConvert,
                        callback: function ($$v) {
                          _vm.$set(_vm.statusUserForm, "statusConvert", $$v)
                        },
                        expression: "statusUserForm.statusConvert",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("禁用"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("可用"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "禁止用户划转",
                    rules: [
                      {
                        required: true,
                        message: "请选择是否禁止用户划转",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.statusUserForm.statusTransfer,
                        callback: function ($$v) {
                          _vm.$set(_vm.statusUserForm, "statusTransfer", $$v)
                        },
                        expression: "statusUserForm.statusTransfer",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("禁用"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("可用"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "禁止用户转赠",
                    rules: [
                      {
                        required: true,
                        message: "请选择是否禁止用户转赠",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.statusUserForm.statusGift,
                        callback: function ($$v) {
                          _vm.$set(_vm.statusUserForm, "statusGift", $$v)
                        },
                        expression: "statusUserForm.statusGift",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("禁用"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("可用"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCloseStatus } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitStatusUserForm("statusUserForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "认证/解除实名",
            visible: _vm.realAuthVisible,
            width: "600px",
            "before-close": _vm.handleCloseRealAuth,
          },
          on: {
            "update:visible": function ($event) {
              _vm.realAuthVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "realAuthForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.realAuthForm, "label-width": "100px" },
            },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                model: {
                  value: _vm.realAuthForm.uid,
                  callback: function ($$v) {
                    _vm.$set(_vm.realAuthForm, "uid", $$v)
                  },
                  expression: "realAuthForm.uid",
                },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "认证状态",
                    rules: [
                      {
                        required: true,
                        message: "请选择实名认证状态",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.realAuthForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.realAuthForm, "status", $$v)
                        },
                        expression: "realAuthForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("未实名"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("已实名"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "姓名",
                    prop: "realName",
                    rules: [
                      {
                        required: _vm.realAuthForm.status == 1,
                        message: "请输入真实姓名",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { clearable: "", maxlength: "10" },
                    model: {
                      value: _vm.realAuthForm.realName,
                      callback: function ($$v) {
                        _vm.$set(_vm.realAuthForm, "realName", $$v)
                      },
                      expression: "realAuthForm.realName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "身份证号",
                    prop: "cardId",
                    rules: [
                      {
                        required: _vm.realAuthForm.status == 1,
                        message: "请输入身份证号",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { clearable: "", maxlength: "20" },
                    model: {
                      value: _vm.realAuthForm.cardId,
                      callback: function ($$v) {
                        _vm.$set(_vm.realAuthForm, "cardId", $$v)
                      },
                      expression: "realAuthForm.cardId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "注册区域",
                    prop: "addressId",
                    rules: [
                      {
                        required: _vm.realAuthForm.status == 1,
                        message: "请选择注册区域",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("el-cascader", {
                    ref: "realAuthCascader",
                    staticClass: "dialogWidth",
                    staticStyle: { width: "250px" },
                    attrs: {
                      filterable: "",
                      clearable: "",
                      options: _vm.addresData,
                      props: {
                        value: "cityId",
                        label: "name",
                        children: "child",
                      },
                    },
                    on: { change: _vm.handleChangeRealAuthForm },
                    model: {
                      value: _vm.realAuthForm.addressId,
                      callback: function ($$v) {
                        _vm.$set(_vm.realAuthForm, "addressId", $$v)
                      },
                      expression: "realAuthForm.addressId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCloseRealAuth } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitRealAuthForm("realAuthForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改用户类型",
            visible: _vm.userBusTypeVisible,
            width: "700px",
            "before-close": _vm.handleCloseUserBusType,
          },
          on: {
            "update:visible": function ($event) {
              _vm.userBusTypeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "userBusTypeForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.userBusTypeForm, "label-width": "100px" },
            },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                model: {
                  value: _vm.userBusTypeForm.uid,
                  callback: function ($$v) {
                    _vm.$set(_vm.userBusTypeForm, "uid", $$v)
                  },
                  expression: "userBusTypeForm.uid",
                },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "用户星级" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.userBusTypeForm.level,
                        callback: function ($$v) {
                          _vm.$set(_vm.userBusTypeForm, "level", $$v)
                        },
                        expression: "userBusTypeForm.level",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: _vm.userBusTypeForm.level } },
                        [_vm._v(_vm._s(_vm.userBusTypeForm.levelName))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "用户类型",
                    rules: [
                      {
                        required: true,
                        message: "请选择用户类型",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.userBusTypeForm.partnerType,
                        callback: function ($$v) {
                          _vm.$set(_vm.userBusTypeForm, "partnerType", $$v)
                        },
                        expression: "userBusTypeForm.partnerType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "" } }, [
                        _vm._v("星级"),
                      ]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.commonEnums.userBusTypeEnums2,
                        function (item, index) {
                          return _c(
                            "el-radio",
                            { key: index, attrs: { label: item.value } },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.userBusTypeForm.partnerType == 21 ||
              _vm.userBusTypeForm.partnerType == 22
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "负责区域",
                        prop: "cityId",
                        rules: [
                          {
                            required: true,
                            message: "请选择负责区域",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-cascader", {
                        ref: "userBusTypeCascader",
                        staticClass: "dialogWidth",
                        staticStyle: { width: "250px" },
                        attrs: {
                          filterable: "",
                          clearable: "",
                          options: _vm.addresData,
                          props: {
                            value: "cityId",
                            label: "name",
                            children: "child",
                            expandTrigger: "hover",
                            checkStrictly: true,
                          },
                        },
                        on: { change: _vm.handleChangeUserBusTypeForm },
                        model: {
                          value: _vm.userBusTypeForm.cityId,
                          callback: function ($$v) {
                            _vm.$set(_vm.userBusTypeForm, "cityId", $$v)
                          },
                          expression: "userBusTypeForm.cityId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "代理人",
                    rules: [
                      {
                        required: true,
                        message: "请选择代理人",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.userBusTypeForm.proxyType,
                        callback: function ($$v) {
                          _vm.$set(_vm.userBusTypeForm, "proxyType", $$v)
                        },
                        expression: "userBusTypeForm.proxyType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "" } }, [_vm._v("无")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("一级代理"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("二级代理"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCloseUserBusType } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitUserBusTypeForm("userBusTypeForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "手动注册用户",
            visible: _vm.registerUserVisible,
            width: "600px",
            "before-close": _vm.handleCloseRegister,
          },
          on: {
            "update:visible": function ($event) {
              _vm.registerUserVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "registerUserForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.registerUserForm, "label-width": "100px" },
            },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                model: {
                  value: _vm.registerUserForm.uid,
                  callback: function ($$v) {
                    _vm.$set(_vm.registerUserForm, "uid", $$v)
                  },
                  expression: "registerUserForm.uid",
                },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "会员手机" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.registerUserForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.registerUserForm, "phone", $$v)
                      },
                      expression: "registerUserForm.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "邀请码" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.registerUserForm.inviteCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.registerUserForm, "inviteCode", $$v)
                      },
                      expression: "registerUserForm.inviteCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCloseRegister } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitRegisterUserForm("registerUserForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "赠送任务包",
            visible: _vm.taskPackageUserVisible,
            width: "600px",
            "before-close": _vm.handleCloseTaskPackage,
          },
          on: {
            "update:visible": function ($event) {
              _vm.taskPackageUserVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "taskPackageUserForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.taskPackageUserForm, "label-width": "100px" },
            },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                model: {
                  value: _vm.taskPackageUserForm.uid,
                  callback: function ($$v) {
                    _vm.$set(_vm.taskPackageUserForm, "uid", $$v)
                  },
                  expression: "taskPackageUserForm.uid",
                },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "赠送数量",
                    rules: [
                      {
                        required: true,
                        message: "请输入赠送数量",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: { placeholder: "请输入赠送数量", clearable: "" },
                    model: {
                      value: _vm.taskPackageUserForm.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.taskPackageUserForm, "number", $$v)
                      },
                      expression: "taskPackageUserForm.number",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "packageId",
                    label: "任务包",
                    rules: [
                      {
                        required: true,
                        message: "请选择任务包",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { placeholder: "请选择任务包", filterable: "" },
                      model: {
                        value: _vm.taskPackageUserForm.packageId,
                        callback: function ($$v) {
                          _vm.$set(_vm.taskPackageUserForm, "packageId", $$v)
                        },
                        expression: "taskPackageUserForm.packageId",
                      },
                    },
                    _vm._l(_vm.taskPackageList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { value: item.id, label: item.title },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCloseTaskPackage } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitTaskPackageUserForm(
                        "taskPackageUserForm"
                      )
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "删除虚拟用户",
            visible: _vm.delVirtualVisible,
            width: "600px",
            "before-close": _vm.handleCloseDelVirtual,
          },
          on: {
            "update:visible": function ($event) {
              _vm.delVirtualVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "virtualDelUserForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.virtualDelUserForm, "label-width": "100px" },
            },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                model: {
                  value: _vm.virtualDelUserForm.uid,
                  callback: function ($$v) {
                    _vm.$set(_vm.virtualDelUserForm, "uid", $$v)
                  },
                  expression: "virtualDelUserForm.uid",
                },
              }),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "会员昵称" } }, [
                _vm._v(
                  "\n              " +
                    _vm._s(
                      _vm._f("filterEmpty")(_vm.virtualDelUserForm.nickname)
                    ) +
                    " | " +
                    _vm._s(_vm._f("sexFilter")(_vm.virtualDelUserForm.sex)) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "会员手机" } }, [
                _vm._v(
                  "\n              " +
                    _vm._s(
                      _vm._f("filterEmpty")(_vm.virtualDelUserForm.phone)
                    ) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否实名",
                    rules: [
                      {
                        required: true,
                        message: "请选择是否实名注册",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.virtualDelUserForm.realFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.virtualDelUserForm, "realFlag", $$v)
                        },
                        expression: "virtualDelUserForm.realFlag",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("仅注册"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("实名注册"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "删除人数",
                    rules: [
                      {
                        required: true,
                        message: "请输入删除人数",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: { placeholder: "请输入删除人数", clearable: "" },
                    model: {
                      value: _vm.virtualDelUserForm.delCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.virtualDelUserForm, "delCount", $$v)
                      },
                      expression: "virtualDelUserForm.delCount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCloseDelVirtual } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitVirtualUserDelForm("virtualDelUserForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "默认上级推广人",
            visible: _vm.defaultExtensionVisible,
            width: "500px",
            "before-close": _vm.handleCloseDefaultExtension,
          },
          on: {
            "update:visible": function ($event) {
              _vm.defaultExtensionVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "formDefaultExtension",
              staticClass: "formDefaultExtension mt20",
              attrs: {
                model: _vm.formDefaultExtension,
                rules: _vm.ruleInline,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户头像：", prop: "image" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: { click: _vm.modalPicTapDefault },
                    },
                    [
                      _vm.formDefaultExtension.image
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: { src: _vm.formDefaultExtension.image },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "用户昵称：",
                    prop: "nickname",
                    rules: [
                      {
                        required: true,
                        message: "请点击头像选择用户",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.formDefaultExtension.nickname || "") +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "用户I D：",
                    prop: "spreadUid",
                    rules: [
                      {
                        required: true,
                        message: "请点击头像选择用户",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.formDefaultExtension.spreadUid || "") +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubDefaultExtension("formDefaultExtension")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户列表",
            visible: _vm.defaultUserVisible,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.defaultUserVisible = $event
            },
          },
        },
        [
          _vm.defaultUserVisible
            ? _c("user-list", {
                on: { getTemplateRow: _vm.getDefaulltTemplateRow },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.defaultUserVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.defaultUserVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }