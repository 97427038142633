var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "back" }, [
      _c("div", { staticClass: "back-style" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            color: "#35cab6",
            "font-size": "20px",
            "text-align": "center",
            "padding-top": "10px",
          },
        },
        [_vm._v("充值成功")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }