"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  computed: {},
  data: function data() {
    return {
      list: [{
        name: '1',
        value: "抖音",
        content: "52.7亿"
      }, {
        name: '2',
        value: "快手",
        content: "39.8亿"
      }, {
        name: '3',
        value: "哔哩",
        content: "20.1亿"
      }, {
        name: '4',
        value: "小红书",
        content: "15.6亿"
      }, {
        name: '5',
        value: "视频号",
        content: "12.3亿"
      }]
    };
  }
};