"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _task = require("@/api/task");
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var obj = {
  title: '',
  price: 1,
  dayYield: 1,
  activeDays: 1,
  totalYield: 1,
  activeness: 1,
  maxCount: 1,
  taskQuantity: 1,
  status: 1,
  yieldQuantity: 1,
  incrementStep: '',
  incrementCount: '',
  image: '',
  // bgImage: '',
  manageStock: false,
  stock: 0
};
var _default = exports.default = {
  name: "CreateTask",
  props: {
    taskPackage: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      formValidate: Object.assign({}, obj),
      loading: false,
      rules: {
        title: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          message: '请输入出售金额',
          trigger: 'blur'
        }
        // { type: 'number', message: '出售金额须为数字值'}
        ],
        dayYield: [{
          required: true,
          message: '请输入日产量',
          trigger: 'blur'
        }
        // { type: 'number', message: '日产量须为数字值'}
        ],
        activeDays: [{
          required: true,
          message: '请输入有效周期',
          trigger: 'blur'
        }
        // { type: 'number', message: '有效周期须为数字值'}
        ],
        totalYield: [{
          required: true,
          message: '请输入总收益',
          trigger: 'blur'
        }
        // { type: 'number', message: '总收益须为数字值'}
        ],
        activeness: [{
          required: true,
          message: '请输活跃度',
          trigger: 'blur'
        }
        // { type: 'number', message: '活跃度须为数字值'}
        ],
        taskQuantity: [{
          required: true,
          message: '请输入完成任务数量',
          trigger: 'blur'
        }
        // { type: 'number', message: '完成任务数量须为数字值'}
        ],
        yieldQuantity: [{
          required: true,
          message: '请输入收益次数',
          trigger: 'blur'
        }
        // { type: 'number', message: '收益次数须为数字值'}
        ],
        image: [{
          required: true,
          message: '请上传封面图',
          trigger: 'blur'
        }],
        stock: [{
          required: true,
          message: '请输入库存数量',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    resetForm: function resetForm(formName) {
      this.dialogVisible = false;
      // this[formName] = {};
    },
    submitForm: (0, _validate.Debounce)(function (formName) {
      var _this2 = this;
      this.$refs.taskPackage.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          var data = {
            title: _this2.taskPackage.title,
            price: _this2.taskPackage.price,
            dayYield: _this2.taskPackage.dayYield,
            activeDays: _this2.taskPackage.activeDays,
            totalYield: _this2.taskPackage.totalYield,
            activeness: _this2.taskPackage.activeness,
            maxCount: _this2.taskPackage.maxCount,
            taskQuantity: _this2.taskPackage.taskQuantity,
            status: _this2.taskPackage.status,
            incrementStep: _this2.taskPackage.incrementStep,
            incrementCount: _this2.taskPackage.incrementCount,
            image: _this2.taskPackage.image,
            yieldQuantity: _this2.taskPackage.yieldQuantity,
            manageStock: _this2.taskPackage.manageStock || false,
            stock: _this2.taskPackage.stock || 0
          };
          _this2.taskPackage.id ? (0, _task.taskPackageManagerUpdateApi)(_this2.taskPackage.id, data).then(function (res) {
            _this2.$message.success('编辑成功');
            _this2.loading = false;
            _this2.handleClose();
            _this2.formValidate = Object.assign({}, obj);
            _this2.$parent.getList();
          }).catch(function () {
            _this2.loading = false;
          }) : (0, _task.taskPackageManagerSaveApi)(_this2.taskPackage).then(function (res) {
            _this2.$message.success('添加成功');
            _this2.loading = false;
            _this2.handleClose();
            _this2.formValidate = Object.assign({}, obj);
            _this2.$parent.getList();
          }).catch(function () {
            _this2.loading = false;
            _this2.formValidate = Object.assign({}, obj);
          });
        } else {
          return false;
        }
      });
    }),
    //上传图片
    modalPicTap: function modalPicTap(tit, type) {
      var _this = this;
      this.$modalUpload(function (img) {
        var info = img[0];
        _this.taskPackage['image'] = info.sattDir;
        // _this.resetFormValidate('taskPackage');
        // if (type == "bgImage") {
        //    _this.taskPackage.bgImage = info.sattDir;
        //    _this.resetFormValidate('taskPackage');
        // }
      }, tit, "content");
    },
    resetFormValidate: function resetFormValidate(formName) {
      this.$refs[formName].clearValidate();
    }
  }
};