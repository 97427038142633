"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemSetting = require("@/api/systemSetting");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UploadFile',
  props: ['value', 'accept'],
  data: function data() {
    return {
      myHeaders: {
        'X-Token': (0, _auth.getToken)()
      },
      url: ''
    };
  },
  mounted: function mounted() {
    if (this.value) {
      this.url = this.value;
    }
  },
  watch: {
    value: function value(val) {
      this.url = this.value;
    }
  },
  methods: {
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this = this;
      if (this.accept && param.file && param.file.type && param.file.type.indexOf(this.accept) == -1) {
        return this.$message.error('请上传pdf文件！');
      }
      var formData = new FormData();
      var data = {
        model: this.$route.path.split('/')[1],
        pid: 10
      };
      formData.append('multipart', param.file);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _systemSetting.fileFileApi)(formData, data).then(function (res) {
        loading.close();
        _this.url = res.url;
        _this.$emit('input', {
          url: _this.url,
          name: res.fileName
        });
        _this.$message.success('上传成功');
      }).catch(function (res) {
        loading.close();
      });
    }
  }
};