"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var articleApi = _interopRequireWildcard(require("@/api/article.js"));
var _validate = require("@/utils/validate");
var _categoryApi = require("@/api/categoryApi");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "frontEdit",
  props: {},
  data: function data() {
    return {
      typeList: [{
        key: 'zfb',
        value: '支付宝'
      }, {
        key: 'skl',
        value: '余额'
      }],
      datas: {},
      activities: [],
      tabType: 'N',
      modal_loading: false
    };
  },
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
  },
  mounted: function mounted() {
    if (this.$route.params.id) {
      this.getInfo();
      this.setTagsViewTitle();
    }
  },
  methods: {
    getInfo: function getInfo() {
      var _this2 = this;
      (0, _categoryApi.articleInfoApi)({
        id: this.$route.params.id
      }).then(function (data) {
        _this2.datas = data;
        _this2.activities = [{
          content: '用户已预约，预约时间2023-04-21',
          timestamp: '2021-12-01  15:39:20',
          size: 'large',
          type: 'primary',
          icon: 'el-icon-more'
        }, {
          content: '用户核销成功',
          timestamp: '2021-12-01  14:39:20',
          size: 'large',
          type: 'primary',
          color: '#0bbd87'
        }, {
          content: '用户申请退款，退款中',
          timestamp: '2021-11-30  15:39:20',
          size: 'large',
          type: 'primary',
          color: '#0bbd87'
        }, {
          content: '用户支付成功，待核销',
          timestamp: '2021-11-29  15:39:20',
          size: 'large',
          type: 'primary',
          color: '#0bbd87'
        }, {
          content: '用户提交了订单，待支付',
          timestamp: '2021-11-29  15:30:20',
          size: 'large',
          type: 'primary',
          color: '#0bbd87'
        }];
      });
    },
    onClickTab: function onClickTab() {
      // this.getInfo();
    },
    onChangeSpec: function onChangeSpec(v) {},
    setTagsViewTitle: function setTagsViewTitle() {
      var title = '联联订单详情';
      var route = Object.assign({}, this.tempRoute, {
        title: "".concat(title, "-").concat(this.$route.params.id)
      });
      this.$store.dispatch('tagsView/updateVisitedView', route);
    },
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.formValidate.imageInput = img[0].sattDir;
      }, tit, 'content');
    }
  }
};