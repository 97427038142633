var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-style" }, [
    _c("div", { staticClass: "center-bg-style" }, [
      _c("div", { staticClass: "title-style" }, [
        _vm._v("\n      基本情况\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: { width: "100%", height: "80%", "text-align": "center" },
        },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "div",
            {
              staticStyle: {
                width: "134px",
                height: "100%",
                float: "left",
                "text-align": "center",
                "margin-left": "36px",
              },
            },
            [
              _c("div", { staticClass: "center-bottom-bg" }),
              _vm._v(" "),
              _c("div", { staticClass: "forward" }, [
                _vm._v(_vm._s(item.value)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "forward", staticStyle: { color: "#fff" } },
                [_vm._v(_vm._s(item.content))]
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }