"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var imApi = _interopRequireWildcard(require("@/api/im"));
var _validate = require("@/utils/validate");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "imMember",
  props: {
    datas: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableForm: {
        page: 1,
        limit: 20,
        tid: ''
      },
      modal_loading: false
    };
  },
  mounted: function mounted() {
    this.init();
    this.getList();
  },
  methods: {
    init: function init() {
      this.tableForm.page = 1;
      this.tableForm.tid = this.datas.tid;
    },
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      imApi['imPageGroupUserDetailApi'](this.tableForm).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        _this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    },
    setIM: function setIM(row, type, tips) {
      var _this2 = this;
      var apiUrl = '';
      if (type == 'cancel') {
        apiUrl = 'imPageGroupManageCancelApi';
      }
      if (type == 'setManage') {
        apiUrl = 'imPageGroupManageAddApi';
      }
      if (type == 'remove') {
        apiUrl = 'imPageGroupKikApi';
      }
      if (apiUrl) {
        this.$confirm("".concat(tips, "?"), "提示").then(function () {
          imApi[apiUrl]({
            tid: _this2.tableForm.tid,
            accid: row.phone
          }).then(function (res) {
            _this2.$message.success("操作成功");
            _this2.getList();
            if (type == 'remove') _this2.$emit('handleOk', true);
          }).catch(function (res) {
            _this2.$message.error(res.message);
          });
        });
      }
    }
  }
};