"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
var _marketing = require("@/api/marketing");
var _article = require("@/api/article");
var _list = _interopRequireDefault(require("./list.json"));
var _linkData = _interopRequireDefault(require("./linkData.json"));
var _marketing2 = _interopRequireDefault(require("./marketing.json"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'linkaddress',
  data: function data() {
    return {
      constants: this.$constants,
      dialogVisible: false,
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      basicsList: [],
      userList: [],
      distributionList: [],
      coupon: [],
      luckDraw: [],
      integral: [],
      lodingList: false,
      categoryData: _list.default.data,
      currenType: 'link',
      currenId: '',
      currenUrl: '',
      formValidate: {
        keywords: ''
      },
      customDate: {
        url: ''
      },
      radioData: '',
      linkId: 0,
      tableList: [],
      params: {
        page: 1,
        limit: 10,
        keywords: '',
        type: 1
      },
      columns: [{
        title: "ID",
        key: "id",
        width: 60
      }, {
        title: "页面名称",
        key: "name",
        width: 150
      }, {
        title: "页面链接",
        key: "url"
      }],
      columns7: [{
        title: "ID",
        key: "id",
        width: 60
      }, {
        title: "分类名称",
        key: "cate_name",
        tree: true
      }, {
        title: "分类图标",
        slot: "pic"
      }],
      columns8: [{
        title: "ID",
        key: "id",
        width: 60
      }, {
        title: "商品图片",
        slot: "image",
        width: 90
      }, {
        title: "商品名称",
        key: "store_name"
      }],
      bargain: [{
        title: "ID",
        key: "id",
        width: 60
      }, {
        title: "商品图片",
        slot: "image",
        width: 90
      }, {
        title: "商品名称",
        key: "title"
      }],
      news: [{
        title: "ID",
        key: "id",
        width: 60
      }, {
        title: "文章图片",
        slot: "image_input",
        width: 90
      }, {
        title: "文章名称",
        key: "title"
      }]
    };
  },
  mounted: function mounted() {
    this.mockData('link');
  },
  methods: {
    handleNodeClick: function handleNodeClick(data) {
      this.$set(this, 'currenType', data.type);
      this.mockData(data.type);
    },
    mockData: function mockData(type) {
      var _this = this;
      var data = [];
      if (type == 'marketing_link') {
        data = _marketing2.default.data.list;
      } else if (type == 'link') {
        data = _linkData.default.data.list;
      } else if (type == 'product_category') {
        this.lodingList = true;
        (0, _store.categoryApi)({
          type: 1,
          status: -1
        }).then(function (res) {
          _this.tableList.list = res;
          _this.lodingList = false;
        });
      } else if (type == 'product') {
        this.lodingList = true;
        (0, _store.productLstApi)(this.params).then(function (res) {
          _this.tableList = res;
          _this.lodingList = false;
        });
      } else if (type == 'seckill') {
        this.lodingList = true;
        (0, _marketing.seckillStoreListApi)(this.params).then(function (res) {
          _this.tableList = res;
          _this.lodingList = false;
        });
      } else if (type == 'bargain') {
        this.lodingList = true;
        (0, _marketing.bargainListApi)(this.params).then(function (res) {
          _this.tableList = res;
          _this.lodingList = false;
        });
      } else if (type == 'combination') {
        this.lodingList = true;
        (0, _marketing.combinationListApi)(this.params).then(function (res) {
          _this.tableList = res;
          _this.lodingList = false;
        });
      } else if (type == 'news') {
        this.lodingList = true;
        (0, _article.ListArticle)(this.params).then(function (res) {
          _this.tableList = res;
          _this.lodingList = false;
        });
      }
      var basicsList = [];
      var distributionList = [];
      var userList = [];
      var integral = [];
      var luckDraw = [];
      var coupon = [];
      data.forEach(function (e) {
        if (e.type == 1) {
          basicsList.push(e);
        } else if (e.type == 2) {
          distributionList.push(e);
        } else if (e.type == 3) {
          userList.push(e);
        } else if (e.type == 4) {
          integral.push(e);
        } else if (e.type == 5) {
          luckDraw.push(e);
        } else {
          coupon.push(e);
        }
      });
      this.basicsList = basicsList;
      this.distributionList = distributionList;
      this.userList = userList;
      this.coupon = coupon;
      this.luckDraw = luckDraw;
      this.integral = integral;
    },
    getUrl: function getUrl(item) {
      this.currenId = item.id;
      this.currenUrl = item.url;
    },
    singleElection: function singleElection(row) {
      this.linkId = row.id;
    },
    handleSubmit: function handleSubmit(name) {
      switch (this.currenType) {
        case 'product':
          this.$emit("linkUrl", '/pages/goods_details/index?id=' + this.linkId);
          break;
        case 'seckill':
          this.$emit("linkUrl", '/pages/activity/goods_seckill_details/index?id=' + this.linkId);
          break;
        case 'bargain':
          this.$emit("linkUrl", '/pages/activity/goods_bargain_details/index?id=' + this.linkId);
          break;
        case 'combination':
          this.$emit("linkUrl", '/pages/activity/goods_combination_details/index?id=' + this.linkId);
          break;
        case 'news':
          this.$emit("linkUrl", '/pages/news_details/index?id=' + this.linkId);
          break;
        case 'product_category':
          this.$emit("linkUrl", '/pages/goods_cate/goods_cate');
          break;
        case 'custom':
          this.$emit("linkUrl", this.customDate.url);
          break;
        default:
          this.$emit("linkUrl", this.currenUrl);
          break;
      }
      this.dialogVisible = false;
    },
    handleSizeChange: function handleSizeChange(val) {
      this.params.limit = val;
      this.mockData(this.currenType);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.params.page = val;
      this.mockData(this.currenType);
    }
  }
};