var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _vm.checkPermi(["admin:statistics:user:area"])
            ? _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 18 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "ivu-mt",
                      attrs: { bordered: false, "dis-hover": "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "acea-row row-between-wrapper" },
                        [
                          _c("div", { staticClass: "header_title mb20" }, [
                            _vm._v("用户地域分布"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 24, lg: 10 } },
                            [
                              _c("div", { staticClass: "echarts" }, [
                                _c("div", {
                                  ref: "myEchart",
                                  style: { height: "400px", width: "100%" },
                                }),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 24, lg: 14 } },
                            [
                              _c(
                                "div",
                                { staticClass: "tables" },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        data: _vm.resdataList,
                                        "default-sort": {
                                          prop: "area",
                                          order: "descending",
                                        },
                                        "max-height": "400",
                                      },
                                    },
                                    _vm._l(_vm.columns1, function (item, key) {
                                      return _c("el-table-column", {
                                        key: key,
                                        attrs: {
                                          label: item.title,
                                          prop: item.key,
                                          sortable: "",
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.checkPermi(["admin:statistics:user:sex"])
            ? _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 6 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "ivu-mt",
                      attrs: { bordered: false, "dis-hover": "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "acea-row row-between-wrapper" },
                        [
                          _c("div", { staticClass: "header_title mb20" }, [
                            _vm._v("用户性别比例"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.optionData
                        ? _c("echarts-new", {
                            attrs: {
                              "option-data": _vm.optionSex,
                              styles: _vm.style1,
                              height: "100%",
                              width: "100%",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }