"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
var _auth = require("@/utils/auth");
var _taoBao = _interopRequireDefault(require("./taoBao"));
var _storeMust = _interopRequireDefault(require("./storeMust"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'ProductList',
  components: {
    taoBao: _taoBao.default,
    StoreMust: _storeMust.default
  },
  data: function data() {
    return {
      props: {
        children: 'child',
        label: 'name',
        value: 'id',
        emitPath: false
      },
      // roterPre: roterPre,
      headeNum: [],
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableForm: {
        page: 1,
        limit: 20,
        cateId: '',
        keywords: '',
        type: '1'
      },
      categoryList: [],
      merCateList: [],
      // objectUrl: process.env.VUE_APP_BASE_API,
      dialogVisible: false,
      dialogMustBuyVisible: false,
      dialogMustBuyVisibleData: null,
      card_select_show: false,
      checkAll: false,
      checkedCities: ['ID', '商品图', '商品名称', '商品售价', '销量', '库存', '排序', '状态', '操作时间'],
      columnData: ['ID', '商品图', '商品名称', '商品售价', '销量', '库存', '排序', '状态', '操作时间'],
      isIndeterminate: true,
      loading: false,
      dialogCostIntegralVisible: false,
      costIntegralForm: {
        rate: ""
      }
    };
  },
  mounted: function mounted() {
    this.goodHeade();
    this.getList();
    this.getCategorySelect();
    this.checkedCities = this.$cache.local.has('goods_stroge') ? this.$cache.local.getJSON('goods_stroge') : this.checkedCities;
  },
  methods: {
    checkPermi: _permission.checkPermi,
    handleRestore: function handleRestore(id) {
      var _this = this;
      this.$modalSure("恢复商品").then(function () {
        (0, _store.restoreApi)(id).then(function (res) {
          _this.$message.success('操作成功');
          _this.goodHeade();
          _this.getList();
        });
      });
    },
    seachList: function seachList() {
      this.tableForm.page = 1;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    handleCloseMod: function handleCloseMod(item) {
      this.dialogVisible = item;
      this.goodHeade();
      this.getList();
    },
    // 复制
    onCopy: function onCopy() {
      this.dialogVisible = true;
    },
    handleMustBuyClose: function handleMustBuyClose() {
      this.dialogMustBuyVisible = false;
    },
    handleMustBuyCloseMod: function handleMustBuyCloseMod(item) {
      this.dialogMustBuyVisible = item;
      this.goodHeade();
      this.getList();
    },
    // 必购商品
    onSetMust: function onSetMust() {
      var _this2 = this;
      (0, _store.getMustBuyApi)().then(function (res) {
        _this2.dialogMustBuyVisibleData = res || null;
        _this2.dialogMustBuyVisible = true;
      });
    },
    // 余额比例配置
    onSetCostIntegral: function onSetCostIntegral() {
      var _this3 = this;
      (0, _store.getIntegralRateApi)().then(function (res) {
        _this3.dialogCostIntegralVisible = true;
        _this3.costIntegralForm.rate = res;
      });
    },
    handleCloseCostIntegral: function handleCloseCostIntegral() {
      this.dialogCostIntegralVisible = false;
    },
    submitCostIntegralForm: function submitCostIntegralForm(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _store.setIntegralRateApi)(_this4.costIntegralForm).then(function (res) {
            _this4.$message.success("修改成功");
            _this4.loading = false;
            _this4.handleCloseCostIntegral();
            _this4.getList();
          }).catch(function () {
            _this4.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    // 导出
    exports: function exports() {
      (0, _store.productExcelApi)({
        cateId: this.tableForm.cateId,
        keywords: this.tableForm.keywords,
        type: this.tableForm.type
      }).then(function (res) {
        window.location.href = res.fileName;
      });
    },
    // 获取商品表单头数量
    goodHeade: function goodHeade() {
      var _this5 = this;
      (0, _store.productHeadersApi)().then(function (res) {
        _this5.headeNum = res;
      }).catch(function (res) {
        _this5.$message.error(res.message);
      });
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this6 = this;
      (0, _store.categoryApi)({
        status: -1,
        type: 1
      }).then(function (res) {
        _this6.merCateList = res;
      }).catch(function (res) {
        _this6.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList() {
      var _this7 = this;
      this.listLoading = true;
      (0, _store.productLstApi)(this.tableForm).then(function (res) {
        _this7.tableData.data = res.list;
        _this7.tableData.total = res.total;
        _this7.listLoading = false;
      }).catch(function (res) {
        _this7.listLoading = false;
        _this7.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, type) {
      var _this8 = this;
      this.$modalSure("\u5220\u9664 id \u4E3A ".concat(id, " \u7684\u5546\u54C1")).then(function () {
        var deleteFlag = type == 5 ? 'delete' : 'recycle';
        (0, _store.productDeleteApi)(id, deleteFlag).then(function () {
          _this8.$message.success('删除成功');
          _this8.getList();
          _this8.goodHeade();
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this9 = this;
      row.isShow ? (0, _store.putOnShellApi)(row.id).then(function () {
        _this9.$message.success('上架成功');
        _this9.getList();
        _this9.goodHeade();
      }).catch(function () {
        row.isShow = !row.isShow;
      }) : (0, _store.offShellApi)(row.id).then(function () {
        _this9.$message.success('下架成功');
        _this9.getList();
        _this9.goodHeade();
      }).catch(function () {
        row.isShow = !row.isShow;
      });
    },
    renderHeader: function renderHeader(h) {
      var _this10 = this;
      return h("p", [h("span", {
        "style": "padding-right:5px;"
      }, ["\u64CD\u4F5C"]), h("i", {
        "class": "el-icon-setting",
        "on": {
          "click": function click() {
            return _this10.handleAddItem();
          }
        }
      })]);
    },
    handleAddItem: function handleAddItem() {
      if (this.card_select_show) {
        this.$set(this, 'card_select_show', false);
      } else if (!this.card_select_show) {
        this.$set(this, 'card_select_show', true);
      }
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.checkedCities = val ? this.columnData : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.columnData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.columnData.length;
    },
    checkSave: function checkSave() {
      this.$modal.loading("正在保存到本地，请稍候...");
      this.$cache.local.setJSON('goods_stroge', this.checkedCities);
      setTimeout(this.$modal.closeLoading(), 1000);
    }
  }
};