var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ScaleScreen",
        {
          staticClass: "scale-wrap",
          attrs: { width: 1920, height: 1080, fullScreen: true },
        },
        [
          _c("div", { staticClass: "bg" }, [
            _c("div", { staticClass: "d-flex jc-center title_wrap" }, [
              _c("div", [
                _c("div", { staticClass: "title" }, [
                  _c("span", { staticClass: "title-text" }, [
                    _vm._v("流量分发数据展示"),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "contents" }, [
              _c("div", { staticClass: "contetn_left" }, [
                _c(
                  "div",
                  [
                    _c("LeftTop"),
                    _vm._v(" "),
                    _c("LeftCenter"),
                    _vm._v(" "),
                    _c("LeftBottom"),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "contetn_center" }, [
                _c(
                  "div",
                  [_c("CenterTop"), _vm._v(" "), _c("CenterBottom")],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "contetn_right" }, [
                _c(
                  "div",
                  [
                    _c("RightTop"),
                    _vm._v(" "),
                    _c("RightCenter"),
                    _vm._v(" "),
                    _c("RightBottom"),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }