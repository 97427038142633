var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: "", size: "small" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "昵称/手机号：" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "请输入昵称,手机号",
                              maxlength: "50",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.tableForm.keywords,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableForm, "keywords", $$v)
                              },
                              expression: "tableForm.keywords",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品名称：" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "请输入商品名称",
                              maxlength: "50",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.tableForm.productName,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableForm, "productName", $$v)
                              },
                              expression: "tableForm.productName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "礼包状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "礼包状态", clearable: "" },
                              model: {
                                value: _vm.tableForm.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "status", $$v)
                                },
                                expression: "tableForm.status",
                              },
                            },
                            _vm._l(
                              _vm.commonEnums.giftStatusEnums,
                              function (item, index) {
                                return _c("el-option", {
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.getList },
                            },
                            [_vm._v("查询")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.reset()
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "default",
                                size: "small",
                                icon: "el-icon-upload2",
                              },
                              on: { click: _vm.exports },
                            },
                            [_vm._v("导出")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "container" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "订单付款时间：" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  "value-format": "yyyy-MM-dd",
                                  format: "yyyy-MM-dd",
                                  size: "small",
                                  type: "daterange",
                                  placement: "bottom-end",
                                  placeholder: "订单付款时间",
                                },
                                on: { change: _vm.onchangeTime },
                                model: {
                                  value: _vm.timeVal,
                                  callback: function ($$v) {
                                    _vm.timeVal = $$v
                                  },
                                  expression: "timeVal",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.totalOrderAmt !== null
            ? _c(
                "el-form",
                { attrs: { inline: "", size: "small" } },
                [
                  _c("el-form-item", { attrs: { label: "订单统计：" } }, [
                    _c("div", { staticClass: "total-count" }, [
                      _vm._v("\n          金额："),
                      _c("div", { staticClass: "total-count-num" }, [
                        _vm._v(_vm._s(_vm.totalOrderAmt || 0)),
                      ]),
                      _vm._v(" 元\n        "),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "nickname",
                  label: "用户昵称",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "productName",
                  label: "商品名称",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "payTypeStr",
                  label: "支付方式",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "price", label: "支付金额", "min-width": "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "payTime",
                  label: "付款时间",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "statusStr",
                  label: "礼包状态",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "separateTime",
                  label: "拆开时间",
                  "min-width": "120",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableForm.limit,
                  "current-page": _vm.tableForm.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }