"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _qrcode = _interopRequireDefault(require("qrcode"));
var _loginIcon = _interopRequireDefault(require("../../../assets/imgs/login-icon.png"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    //二维码存储内容
    qrUrl: {
      type: String,
      default: ""
    },
    //二维码尺寸（正方形 长宽相同）
    qrSize: {
      type: Number,
      default: 300
    },
    //二维码底部文字
    qrText: {
      default: ""
    },
    //二维码中部logo 如果写default: ""则不生成
    qrLogo: {
      type: String,
      default: _loginIcon.default
    },
    //logo尺寸（正方形 长宽相同）
    qrLogoSize: {
      type: Number,
      default: 40
    },
    //底部说明文字字号
    qrTextSize: {
      type: Number,
      default: 14
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    /***    
     二维码内容* @argument qrUrl
     二维码大小* @argument qrSize
     二维码中间显示文字* @argument  qrText
     二维码中间显示文字大小(默认16px)* @argument qrTextSize
     二维码中间显示图片* @argument  qrLogo
     二维码中间显示图片大小(默认为80) @argument qrLogoSize
    */
    handleQrcodeToDataUrl: function handleQrcodeToDataUrl() {
      var qrcode_canvas = this.$refs.qrcode_canvas;
      var qrcode_logo = this.$refs.qrcode_logo;
      var canvas = this.$refs.canvas;
      console.log(qrcode_logo, '???canvas');
      var that = this;
      _qrcode.default.toDataURL(this.qrUrl, {
        errorCorrectionLevel: "H"
      }, function (err, url) {
        qrcode_canvas.src = url;
        qrcode_canvas.setAttribute("crossOrigin", 'Anonymous');

        // 画二维码里的logo// 在canvas里进行拼接
        var ctx = canvas.getContext("2d");
        setTimeout(function () {
          //获取图片
          ctx.drawImage(qrcode_canvas, 0, 0, that.qrSize, that.qrSize);
          if (that.qrLogo) {
            //设置logo大小//设置获取的logo将其变为圆角以及添加白色背景
            ctx.fillStyle = "#fff";
            ctx.beginPath();
            var logoPosition = (that.qrSize - that.qrLogoSize) / 2;
            //logo相对于canvas居中定位
            var h = that.qrLogoSize + 10;
            //圆角高 10为基数(logo四周白色背景为10/2)
            var w = that.qrLogoSize + 10;
            //圆角宽
            var x = logoPosition - 5;
            var y = logoPosition - 5;
            var r = 5;
            //圆角半径
            ctx.moveTo(x + r, y);
            ctx.arcTo(x + w, y, x + w, y + h, r);
            ctx.arcTo(x + w, y + h, x, y + h, r);
            ctx.arcTo(x, y + h, x, y, r);
            ctx.arcTo(x, y, x + w, y, r);
            ctx.closePath();
            ctx.fill();
            console.log(111);
            qrcode_logo.onload = function () {
              console.log(222);
              ctx.drawImage(qrcode_logo, logoPosition, logoPosition, that.qrLogoSize, that.qrLogoSize);
            };
          }
          if (that.qrText) {
            //设置字体
            var fpadd = 10;
            //规定内间距
            ctx.font = "bold " + that.qrTextSize + "px Arial";
            var tw = ctx.measureText(that.qrText).width;
            //文字真实宽度
            var ftop = that.qrSize - that.qrTextSize;
            //根据字体大小计算文字 toplet
            var fleft = (that.qrSize - tw) / 2;
            //根据字体大小计算文字 leftlet
            var tp = that.qrTextSize / 2;
            //字体边距为字体大小的一半可以自己设置
            ctx.fillStyle = "#fff";
            ctx.fillRect(fleft - tp / 2, ftop - tp / 2, tw + tp, that.qrTextSize + tp);
            ctx.textBaseline = "top"; //设置绘制文本时的文本基线。
            ctx.fillStyle = "#333";
            ctx.fillText(that.qrText, fleft, ftop);
          }
          qrcode_canvas.src = canvas.toDataURL();
        }, 0);
      });
    },
    //保存图片
    savePic: function savePic() {
      var a = document.createElement("a");
      //将二维码面板处理为图片
      a.href = this.$refs.canvas.toDataURL("image/png", 0.5);
      a.download = '众筹' + new Date().getTime() + ".png";
      a.click();
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.handleQrcodeToDataUrl();
    });
  },
  updated: function updated() {
    var _this2 = this;
    this.$nextTick(function () {
      _this2.handleQrcodeToDataUrl();
    });
  }
};