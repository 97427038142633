"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _logistics = require("@/api/logistics");
var _validate = require("@/utils/validate");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      commonEnums: this.$commonEnums,
      tableForm: {
        partnerType: '',
        //业务类型(用户类型)：1-星级用户、21-市级合伙人、22-县级合伙人、3-商家
        cityIds: '',
        createAtStart: '',
        createAtEnd: '',
        param: '',
        page: 1,
        limit: 20
      },
      timeVal: [],
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      collapse: false,
      addresData: []
    };
  },
  mounted: function mounted() {
    this.getList();

    // 获取省市区数据
    var cityList = JSON.parse(sessionStorage.getItem('cityList'));
    this.addresData = cityList;
    this.getCityList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    getCityList: function getCityList() {
      var _this = this;
      (0, _logistics.cityListTree)().then(function (res) {
        sessionStorage.setItem('cityList', JSON.stringify(res));
        var cityList = JSON.parse(sessionStorage.getItem('cityList'));
        _this.addresData = cityList;
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    },
    //确认省市区
    handleChange: function handleChange(e) {
      console.log(e, 'city');
      var province = e[0];
      var city = e[1];
      var area = e[2];
      if (e.length == 1) {
        var allArr = this.$refs.partnerCascader.getCheckedNodes()[0].children || [];
        province = '';
        allArr.forEach(function (element, index) {
          province += element.value + (index < allArr.length - 1 ? ',' : '');
        });
        this.tableForm.cityIds = province;
      } else if (e.length === 2) {
        this.tableForm.cityIds = city;
      } else if (e.length === 3) {
        this.tableForm.cityIds = area;
      }
    },
    reset: function reset() {
      this.tableForm.createAtStart = '';
      this.tableForm.createAtEnd = '';
      this.timeVal = [];
      this.tableForm.param = '';
      this.tableForm.cityIds = '';
      this.tableForm.partnerType = '';
      this.tableForm.page = 1;
      this.getList();
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _user.partnerListApi)(this.tableForm).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
      });
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableForm.createAtStart = e ? this.timeVal[0] : '';
      this.tableForm.createAtEnd = e ? this.timeVal[1] : '';
      this.tableForm.page = 1;
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    }
  }
};