"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.walletTypeEnums = exports.userBusTypeEnums2 = exports.userBusTypeEnums = exports.userBusType2LevelEnums = exports.userBusType1LevelEnums = exports.ticketTypeEnums = exports.storeInSourceEnums = exports.regiftedTypeEnums = exports.giftStatusEnums = exports.devicePayTypeEnums = void 0;
// 用户类型
var userBusTypeEnums = exports.userBusTypeEnums = [{
  label: "普通用户",
  value: 0
}, {
  label: "代理",
  value: 1
}, {
  label: "服务商",
  value: 2
}
// {
//   label: "商家",
//   value: 3
// },
// {
//   label: "承兑商",
//   value: 4
// },
];
var userBusTypeEnums2 = exports.userBusTypeEnums2 = [{
  label: "代理",
  value: 1
}, {
  label: "服务商",
  value: 2
}];
// 用户类型-等级
var userBusType1LevelEnums = exports.userBusType1LevelEnums = [{
  label: "一星",
  value: 1
}, {
  label: "二星",
  value: 2
}, {
  label: "三星",
  value: 3
}, {
  label: "四星",
  value: 4
}, {
  label: "五星",
  value: 5
}];
var userBusType2LevelEnums = exports.userBusType2LevelEnums = [{
  label: "县级",
  value: 1
}, {
  label: "市级",
  value: 2
}, {
  label: "省级",
  value: 3
}, {
  label: "大区总代",
  value: 4
}];
// 商家明细-来源
var storeInSourceEnums = exports.storeInSourceEnums = [{
  label: "商家收款",
  value: 'business'
}, {
  label: "商家划转",
  value: 'transfer'
}];

// 助力礼包-状态
var giftStatusEnums = exports.giftStatusEnums = [{
  label: "已拆",
  value: 2
}, {
  label: "未拆",
  value: 1
}];

// 助力钱包-类型
var walletTypeEnums = exports.walletTypeEnums = [
// {
//   label: "订单返佣",
//   value: 1
// },
{
  label: "申请提现",
  value: 2
}, {
  label: "提现失败",
  value: 3
}, {
  label: "提现成功",
  value: 4
},
// {
//   label: "佣金转余额",
//   value: 5
// },
{
  label: "礼包收入",
  value: 6
}];

// 助力门票-类型 1=助力商品消耗 2=获得转赠 3 = 每日直播任务收入 4 =转赠他人 5= 余额兑换
var ticketTypeEnums = exports.ticketTypeEnums = [{
  label: "助力商品消耗",
  value: 1
}, {
  label: "获得转赠",
  value: 2
}, {
  label: "每日直播任务收入",
  value: 3
}, {
  label: "转赠他人",
  value: 4
}, {
  label: "余额兑换",
  value: 5
}, {
  label: "助力订单取消",
  value: 6
}];

// 转赠-类型
var regiftedTypeEnums = exports.regiftedTypeEnums = [{
  label: "余额",
  value: 1
}
// {
//   label: "门票",
//   value: 2
// },
];

// 付款方式 alipay-支付宝;integral-余额支付
var devicePayTypeEnums = exports.devicePayTypeEnums = [{
  label: "支付宝",
  value: "alipay"
}, {
  label: "余额支付",
  value: "integral"
}];