var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.item && _vm.item.uid
        ? _c("el-card", { staticClass: "box-card" }, [
            _c(
              "div",
              { staticClass: "container mt-1 text-center" },
              [
                _c(
                  "el-form",
                  {
                    ref: "userBusTypeForm",
                    staticClass: "demo-dynamic",
                    attrs: {
                      model: _vm.userBusTypeForm,
                      "label-width": "120px",
                    },
                  },
                  [
                    _c("el-input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false",
                        },
                      ],
                      model: {
                        value: _vm.userBusTypeForm.uid,
                        callback: function ($$v) {
                          _vm.$set(_vm.userBusTypeForm, "uid", $$v)
                        },
                        expression: "userBusTypeForm.uid",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "busType",
                          label: "用户类型",
                          rules: [
                            {
                              required: true,
                              message: "请选择用户类型",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.userBusTypeForm.busType,
                              callback: function ($$v) {
                                _vm.$set(_vm.userBusTypeForm, "busType", $$v)
                              },
                              expression: "userBusTypeForm.busType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("普通用户"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("服务商"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.userBusTypeForm.busType == 2
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "level",
                              label: "服务商级别",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择服务商级别",
                                  trigger: ["blur", "change"],
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "selWidth",
                                attrs: {
                                  placeholder: "服务商级别",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.userBusTypeForm.level,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.userBusTypeForm, "level", $$v)
                                  },
                                  expression: "userBusTypeForm.level",
                                },
                              },
                              _vm._l(
                                _vm.commonEnums.userBusType2LevelEnums,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "sign-btn" },
                  [
                    _vm.checkPermi(["admin:user:changeBusType"])
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["admin:user:changeBusType"],
                                expression: "['admin:user:changeBusType']",
                              },
                            ],
                            staticClass: "mr10",
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.hander("save")
                              },
                            },
                          },
                          [_vm._v("保存")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.hander("close")
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }