"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeExpireDateApi = changeExpireDateApi;
exports.configListApi = configListApi;
exports.handselUserPackageApi = handselUserPackageApi;
exports.invalidVipListApi = invalidVipListApi;
exports.openDetailsListApi = openDetailsListApi;
exports.saveConfigApi = saveConfigApi;
exports.taskPackageManagerDeleteApi = taskPackageManagerDeleteApi;
exports.taskPackageManagerSaveApi = taskPackageManagerSaveApi;
exports.taskPackageManagerUpdateApi = taskPackageManagerUpdateApi;
exports.taskPakageListApi = taskPakageListApi;
exports.usertaskDetailDeleteApi = usertaskDetailDeleteApi;
exports.usertaskDetailListApi = usertaskDetailListApi;
exports.usertaskDetailUpdateApi = usertaskDetailUpdateApi;
exports.usertaskLogListApi = usertaskLogListApi;
exports.usertaskPackageDeleteApi = usertaskPackageDeleteApi;
exports.usertaskPackageUpdateApi = usertaskPackageUpdateApi;
exports.usertaskPakageListApi = usertaskPakageListApi;
exports.validVipListApi = validVipListApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 任务包 列表
 * @param pram
 */
function taskPakageListApi(params) {
  var data = {
    keywords: params.keywords,
    page: params.page,
    limit: params.limit
  };
  return (0, _request.default)({
    url: "/admin/task/pakageManager/list",
    method: 'get',
    params: data
  });
}

/**
 * 任务包 编辑
 *  @param pram
 */
function taskPackageManagerUpdateApi(params, data) {
  console.log("执行update操作---------------");
  console.log("params---------------" + params);
  return (0, _request.default)({
    url: "/admin/task/pakageManager/update/".concat(params),
    method: 'post',
    // params,
    data: data
  });
}

/**
 * 任务包 新增
 * @param pram
 */
function taskPackageManagerSaveApi(data) {
  console.log("执行insert操作---------------");
  return (0, _request.default)({
    url: "/admin/task/pakageManager/save",
    method: 'post',
    data: data
  });
}

/**
 * 任务包 删除
 * @param pram
 */
function taskPackageManagerDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/task/pakageManager/delete/".concat(id),
    method: 'post'
  });
}
function usertaskPakageListApi(params) {
  var data = {
    keywords: params.keywords,
    page: params.page,
    limit: params.limit
  };
  return (0, _request.default)({
    url: "/admin/task/userpakageManager/list",
    method: 'get',
    params: data
  });
}
function usertaskPackageDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/task/userpakageManager/delete/".concat(id),
    method: 'post'
  });
}
function usertaskPackageUpdateApi(params, data) {
  console.log("执行update操作---------------");
  console.log("params---------------" + params);
  return (0, _request.default)({
    url: "/admin/task/userpakageManager/update/".concat(params),
    method: 'post',
    // params,
    data: data
  });
}
function usertaskDetailListApi(params) {
  var data = {
    keywords: params.keywords,
    inviterPhone: params.inviterPhone,
    page: params.page,
    limit: params.limit
  };
  return (0, _request.default)({
    url: "/admin/task/usertaskDetail/list",
    method: 'get',
    params: data
  });
}
function usertaskDetailDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/task/usertaskDetail/delete/".concat(id),
    method: 'post'
  });
}
function usertaskDetailUpdateApi(params, data) {
  console.log("执行update操作---------------");
  console.log("params---------------" + params);
  return (0, _request.default)({
    url: "/admin/task/usertaskDetail/update/".concat(params),
    method: 'post',
    // params,
    data: data
  });
}
function usertaskLogListApi(params) {
  return (0, _request.default)({
    url: "/admin/task/usertaskLog/list",
    method: 'get',
    params: params
  });

  // const data = {
  //   keywords: params.keywords,
  //   page: params.page,
  //   limit: params.limit
  // }
  // return request({
  //   url: `/admin/task/usertaskLog/list`,
  //   method: 'get',
  //   params: data
  // })
}

/**
 * 赠送任务包
 * @param pram
 */
function handselUserPackageApi(data) {
  return (0, _request.default)({
    url: "/admin/task/pakageManager/user/presenter",
    method: 'POST',
    data: data
  });
}

/**
 * vip用户
 * @param params
 * admin:task:vipConfig:validVip
 */
function validVipListApi(params) {
  return (0, _request.default)({
    url: "/admin/task/vipConfig/validVip",
    method: 'get',
    params: params
  });
}
/**
 * 过期vip用户
 * @param params
 * 同权限
 * admin:task:vipConfig:validVip
 */
function invalidVipListApi(params) {
  return (0, _request.default)({
    url: "/admin/task/vipConfig/invalidVip",
    method: 'get',
    params: params
  });
}
/**
 * 修改到期日期
 * @param data
 * admin:task:vipConfig:changeExpireDate
 */
function changeExpireDateApi(data) {
  return (0, _request.default)({
    url: "/admin/task/vipConfig/changeExpireDate",
    method: 'post',
    data: data
  });
}
/**
 * 开通记录
 * @param params
 * admin:task:vipConfig:openDetails
 */
function openDetailsListApi(params) {
  return (0, _request.default)({
    url: "/admin/task/vipConfig/openDetails",
    method: 'get',
    params: params
  });
}
/**
 * vip配置--列表
 * @param params
 * 权限码：admin:task:vipConfig:list
 */
function configListApi(params) {
  return (0, _request.default)({
    url: "/admin/task/vipConfig/configList",
    method: 'get',
    params: params
  });
}
/**
 * vip配置--保存
 * @param data
 * 权限码：admin:task:vipConfig:list
 */
function saveConfigApi(data) {
  return (0, _request.default)({
    url: "/admin/task/vipConfig/saveConfig",
    method: 'post',
    data: data
  });
}