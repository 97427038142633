var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { model: _vm.ruleForm, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        [
          _c("el-alert", {
            attrs: {
              title: "请勿频繁更改，以免计算产生混乱！",
              type: "warning",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "用户等级", "label-width": "100px" } },
        [
          _c(
            "el-select",
            {
              attrs: { clearable: "", placeholder: "请选择" },
              on: { change: _vm.currentSel },
              model: {
                value: _vm.ruleForm.levelId,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "levelId", $$v)
                },
                expression: "ruleForm.levelId",
              },
            },
            _vm._l(_vm.levelList, function (item) {
              return _c("el-option", {
                key: item.grade,
                attrs: { label: item.name, value: item.grade },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      (_vm.grade == "" ? false : _vm.grade < _vm.levelInfo.gradeLevel)
        ? _c(
            "el-form-item",
            { attrs: { label: "扣除经验", "label-width": "100px" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.ruleForm.isSub,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "isSub", $$v)
                  },
                  expression: "ruleForm.isSub",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "captcha", attrs: { prop: "captcha" } },
        [
          _c(
            "div",
            { staticClass: "acea-row", staticStyle: { "flex-wrap": "nowrap" } },
            [
              _c("el-input", {
                ref: "captcha",
                staticStyle: { width: "auto" },
                attrs: {
                  placeholder: "验证码",
                  name: "captcha",
                  type: "text",
                  tabindex: "1",
                  autocomplete: "off",
                  "prefix-icon": "el-icon-message",
                  clearable: "",
                  maxlength: "10",
                },
                model: {
                  value: _vm.ruleForm.captcha,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "captcha", $$v)
                  },
                  expression: "ruleForm.captcha",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", disabled: !this.canClick },
                  on: { click: _vm.cutDown },
                },
                [_vm._v(_vm._s(_vm.cutNUm))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resetForm("ruleForm")
                },
              },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }