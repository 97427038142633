var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "container mt-1 text-center" },
          [
            _c(
              "el-form",
              {
                ref: "userBusTypeForm",
                staticClass: "demo-dynamic",
                attrs: { model: _vm.userBusTypeForm, "label-width": "120px" },
              },
              [
                _c("el-input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false",
                    },
                  ],
                  model: {
                    value: _vm.userBusTypeForm.uid,
                    callback: function ($$v) {
                      _vm.$set(_vm.userBusTypeForm, "uid", $$v)
                    },
                    expression: "userBusTypeForm.uid",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "levelRate",
                      label: "收益比例",
                      rules: [
                        {
                          required: true,
                          message: "请填写收益比例",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input-number", {
                      staticClass: "priceBox",
                      attrs: { min: 0, max: 100, precision: 0, step: 1 },
                      model: {
                        value: _vm.userBusTypeForm.levelRate,
                        callback: function ($$v) {
                          _vm.$set(_vm.userBusTypeForm, "levelRate", $$v)
                        },
                        expression: "userBusTypeForm.levelRate",
                      },
                    }),
                    _vm._v("\n              %\n        "),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "sign-btn" },
              [
                _vm.checkPermi(["admin:user:proxy:list:changeEquRate"])
                  ? _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["admin:user:proxy:list:changeEquRate"],
                            expression:
                              "['admin:user:proxy:list:changeEquRate']",
                          },
                        ],
                        staticClass: "mr10",
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.hander("save")
                          },
                        },
                      },
                      [_vm._v("保存")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.hander("close")
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }