var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: "", size: "small" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单编号：" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "请输入订单编号",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.tableForm.orderId,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableForm, "orderId", $$v)
                              },
                              expression: "tableForm.orderId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "付款方式：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "付款方式", clearable: "" },
                              model: {
                                value: _vm.tableForm.payType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "payType", $$v)
                                },
                                expression: "tableForm.payType",
                              },
                            },
                            _vm._l(
                              _vm.commonEnums.devicePayTypeEnums,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "下单时间：" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "下单时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名/手机号：" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "请输入姓名/手机号",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.tableForm.keyWord,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableForm, "keyWord", $$v)
                              },
                              expression: "tableForm.keyWord",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.getList(true)
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.reset()
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                          _vm._v(" "),
                          _vm.checkPermi(["admin:device:order:down"])
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["admin:device:order:down"],
                                      expression: "['admin:device:order:down']",
                                    },
                                  ],
                                  attrs: {
                                    type: "default",
                                    size: "small",
                                    icon: "el-icon-upload2",
                                  },
                                  on: { click: _vm.exports },
                                },
                                [_vm._v("导出")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.totalData
            ? _c(
                "el-form",
                { attrs: { inline: "", size: "small" } },
                [
                  _c("el-form-item", { attrs: { label: "订单统计：" } }, [
                    _c("div", { staticClass: "total-count" }, [
                      _vm._v("\n          支付宝："),
                      _c("div", { staticClass: "total-count-num" }, [
                        _vm._v(_vm._s(_vm.totalData.alipayAmt)),
                      ]),
                      _vm._v("，\n          余额："),
                      _c("div", { staticClass: "total-count-num" }, [
                        _vm._v(_vm._s(_vm.totalData.integralAmt)),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "orderTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "header-cell-class-name": _vm.handleHeaderClass,
              },
              on: { "sort-change": _vm.sorTable },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  "min-width": "50",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "uid",
                  label: "用户ID",
                  "min-width": "80",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "realName",
                  label: "用户姓名",
                  "min-width": "80",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "phone",
                  label: "用户电话",
                  "min-width": "120",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "orderId",
                  label: "订单号",
                  "min-width": "140",
                  align: "left",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "title",
                  label: "标题",
                  "min-width": "120",
                  align: "left",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "price",
                  label: "售出价格",
                  "min-width": "90",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "qty",
                  label: "购买数量",
                  "min-width": "80",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "towCycle",
                  label: "托管周期",
                  "min-width": "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.towCycle) +
                              _vm._s(scope.row.cycleUint)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "enableTime",
                  label: "上机时间",
                  "min-width": "140",
                  align: "center",
                  sortable: "custom",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "incomeMin",
                  label: "每日收益下限",
                  "min-width": "100",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "incomeMax",
                  label: "每日收益上限",
                  "min-width": "100",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "payType",
                  label: "付款方式",
                  "min-width": "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.payType == "alipay"
                                ? "支付宝"
                                : scope.row.payType == "integral"
                                ? "余额支付"
                                : "-"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "payAmt",
                  label: "付款金额",
                  "min-width": "90",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "订单状态",
                  "min-width": "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("deviceOrderStatusFilter")(
                                scope.row.status
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "下单时间",
                  "min-width": "140",
                  align: "center",
                  sortable: "custom",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "100",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["admin:device:order:contract"])
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["admin:device:order:contract"],
                                    expression:
                                      "['admin:device:order:contract']",
                                  },
                                ],
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.hander(scope.row, "contract")
                                  },
                                },
                              },
                              [_vm._v("查看合同")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 0 &&
                        _vm.checkPermi(["admin:device:order:process"])
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["admin:device:order:process"],
                                    expression:
                                      "['admin:device:order:process']",
                                  },
                                ],
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.hander(scope.row, "process")
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 1 &&
                        scope.row.canUpLine &&
                        _vm.checkPermi(["admin:device:order:online"])
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["admin:device:order:online"],
                                    expression: "['admin:device:order:online']",
                                  },
                                ],
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.hander(scope.row, "online")
                                  },
                                },
                              },
                              [_vm._v("确认上线")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 2 &&
                        _vm.checkPermi(["admin:device:order:detail"])
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["admin:device:order:detail"],
                                    expression: "['admin:device:order:detail']",
                                  },
                                ],
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.hander(scope.row, "detail")
                                  },
                                },
                              },
                              [_vm._v("收益明细")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableForm.limit,
                  "current-page": _vm.tableForm.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "800px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible &&
          (_vm.dialogType == "contract" || _vm.dialogType == "process")
            ? _c("Contract", {
                attrs: {
                  viewType: _vm.dialogType == "process" ? "edit" : "view",
                  item: _vm.dialogItem,
                },
                on: { submit: _vm.handleOk, close: _vm.handleClose },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.dialogVisible && _vm.dialogType == "detail"
            ? _c("Detail", { attrs: { item: _vm.dialogItem } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }