var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.datas && _vm.datas.tid
        ? _c(
            "el-card",
            {
              staticClass: "box-card",
              attrs: {
                "body-style": { "max-height": "600px", "overflow-y": "auto" },
              },
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData.data,
                    size: "mini",
                    "highlight-current-row": true,
                    "header-cell-style": { fontWeight: "bold" },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "姓名",
                      prop: "nickname",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "手机号",
                      prop: "phone",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "用户类型", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("userBusTypeTypeFilter")(row.busType)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2407852340
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "levelName",
                      label: "用户等级",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "推荐人",
                      prop: "inviterName",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "群身份", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    row.type == 1
                                      ? "群主"
                                      : row.type == 2
                                      ? "管理员"
                                      : "普通"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3171747895
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", "min-width": "130" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.type == 2
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "mr10",
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setIM(
                                            scope.row,
                                            "cancel",
                                            "取消群管理员"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("取消群管理员")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.type == 0
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "mr10",
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setIM(
                                            scope.row,
                                            "setManage",
                                            "设为群管理员"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("设为群管理员")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.type != 1
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "mr10",
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setIM(
                                            scope.row,
                                            "remove",
                                            "移出群"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("移出群")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1468180302
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.tableForm.limit,
                      "current-page": _vm.tableForm.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }