"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _validate = require("@/utils/validate");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: "selfPrifitRatio",
  props: {
    item: {
      type: Object,
      default: {}
    }
  },
  components: {},
  data: function data() {
    return {
      commonEnums: this.$commonEnums,
      modal_loading: false,
      userBusTypeForm: {
        uid: null,
        levelRate: null
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getInfo();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    getInfo: function getInfo() {
      this.userBusTypeForm = {
        uid: this.item.uid,
        levelRate: (+this.item.equRate || 0) * 100 || null
      };
      console.log(this.userBusTypeForm);
    },
    hander: (0, _validate.Debounce)(function (type) {
      var _this = this;
      if (type == "close") {
        this.$emit("close", true);
      }
      if (type == "save") {
        this.$refs["userBusTypeForm"].validate(function (valid) {
          if (!valid) return;
          _this.$confirm("\u786E\u8BA4\u4FEE\u6539\u81EA\u88C5\u8BBE\u5907\u6536\u76CA\u6BD4\u4F8B?", "提示").then(function () {
            var queryParams = JSON.parse(JSON.stringify(_this.userBusTypeForm));
            queryParams.levelRate = _this.userBusTypeForm.levelRate / 100;
            (0, _user.proxyChangeEquRate)(queryParams).then(function (data) {
              _this.$message.success("操作成功");
              _this.$emit("submit", true);
            });
          });
        });
      }
    })
  }
};