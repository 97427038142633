"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _xlsx = _interopRequireDefault(require("xlsx"));
var _systemSetting = require("@/api/systemSetting");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "UploadExcel",
  props: {
    beforeUpload: Function,
    // eslint-disable-line
    onSuccess: Function // eslint-disable-line
  },
  data: function data() {
    return {
      loading: false,
      excelData: {
        header: null,
        results: null
      },
      fileName: '',
      errorShow: false,
      returnData: ''
    };
  },
  methods: {
    handleDrop: function handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.loading) return;
      var files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$message.error('仅支持单个文件的上传!');
        return;
      }
      var rawFile = files[0]; // only use files[0]
      this.fileName = rawFile.name;
      if (!this.isExcel(rawFile)) {
        this.$message.error('文件格式只支持 .xlsx, .xls 文件');
        return false;
      }
      this.upload(rawFile);
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragover: function handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    handleUpload: function handleUpload() {
      this.$refs['excel-upload-input'].click();
    },
    handleClick: function handleClick(e) {
      var files = e.target.files;
      var rawFile = files[0]; // only use files[0]
      this.fileName = rawFile.name;
      if (!rawFile) return;
      this.upload(rawFile);
    },
    upload: function upload(rawFile) {
      var _this = this;
      this.$refs['excel-upload-input'].value = null; // fix can't select the same excel
      this.errorShow = false;
      this.returnData = '';
      var formData = new FormData();
      formData.append('multipart', rawFile);
      var data = {};
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var url = "/admin/user/import";
      (0, _systemSetting.excelUploadApi)(url, formData, data).then(function (res) {
        loading.close();
        if (res.rtnFlag == true) {
          _this.$emit("flushlist");
          _this.$message.success("数据导入成功！");
        } else {
          _this.errorShow = true;
          _this.returnData = res.rtnMsg;
        }
      }).catch(function (res) {
        loading.close();
        _this.$message.success("服务出现错误，请联系管理员！");
      });
    },
    isExcel: function isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    }
  }
};