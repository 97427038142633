"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _authInformation = require("../../../api/authInformation.js");
var _permission = require("@/utils/permission");
var _artificialModal = _interopRequireDefault(require("./artificial-modal.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "AuthenticationArtificial",
  components: {
    ArtificialModal: _artificialModal.default
  },
  data: function data() {
    return {
      taskInfo: {},
      tableFrom: {
        keywords: '',
        // 人工认证状态： 1.认证中 2.认证成功 3.认证失败
        authStatus: '',
        page: 1,
        limit: 20
      },
      authStatusList: [{
        value: '',
        label: '全部'
      }, {
        value: '1',
        label: '认证中'
      }, {
        value: '2',
        label: '认证成功'
      }, {
        value: '3',
        label: '认证失败'
      }],
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  mounted: function mounted() {
    _permission.checkPermi, this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _authInformation.realnameList)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    selectChange: function selectChange(tab) {
      this.tableFrom.createDay = tab;
      this.tableFrom.page = 1;
      this.timeVal = [];
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    edit: function edit(item) {
      console.log(item);
      this.$refs.artificialModal.open(item);
    }
  }
};