"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _device = require("@/api/device");
var _validate = require("@/utils/validate");
var _permission = require("@/utils/permission");
var _downloadjs = _interopRequireDefault(require("downloadjs"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: "Contract",
  props: {
    viewType: {
      type: String,
      default: "view"
    },
    item: {
      type: Object,
      default: {}
    }
  },
  components: {},
  data: function data() {
    return {
      datas: {},
      modal_loading: false,
      imgError: 'this.src="' + require("../../../assets/imgs/default_avatar.png") + '"'
    };
  },
  created: function created() {},
  mounted: function mounted() {
    if (this.$route.params.id) {
      this.getInfo();
    }
  },
  methods: {
    checkPermi: _permission.checkPermi,
    getInfo: function getInfo() {
      this.datas = {};
      //   collegeApi.CollegeDetail(this.$route.params.id).then((data) => {
      //     this.datas = data;
      //   });
    },
    hander: (0, _validate.Debounce)(function (type) {
      var _this = this;
      var fileUrl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var fileName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      if (type == "close") {
        this.$emit("close", true);
      }
      if (type == "process" || type == "no-process") {
        this.$confirm("\u786E\u8BA4".concat(type == "process" ? "审核通过" : "审核不通过", "?"), "提示").then(function () {
          if (type == "process") {
            (0, _device.deviceOrderContractApplyApi)({
              orderId: _this.item.orderId
            }).then(function (data) {
              _this.$message.success("操作成功");
              _this.$emit("submit", true);
            });
          } else {
            (0, _device.deviceOrderContractUnPassApi)({
              orderId: _this.item.orderId
            }).then(function (data) {
              _this.$message.success("操作成功");
              _this.$emit("submit", true);
            });
          }
        });
      }
      if (type == "contractDown") {
        // window.open(fileUrl, "_blank");
        this.downFile(fileUrl, fileName);
      }
      if (type == "contractView") {
        if (!fileUrl) {
          return this.$message.warning('当前订单合同尚未拟定!');
        }
        window.open(fileUrl, "_blank");
      }
    }),
    downFile: function downFile(url, fileName) {
      var _this2 = this;
      // 请求进来，就可以加在loading
      // download(url, fileName);

      // 解决pdf无法直接下载
      (0, _device.downFileBlob)({
        fileName: fileName,
        inPath: url,
        isBlod: true
      }).then(function (res) {
        debugger;
        _this2.downloadFile(res, fileName);
      });
    },
    /**
     * 下载文件，支持pdf,word等格式
     * */
    downloadFile: function downloadFile(data, fileName) {
      if (!data) {
        return;
      }
      // 创建blob对象，解析流数据
      var blob = new Blob([data]);
      var a = document.createElement("a");
      // 兼容webkix浏览器，处理webkit浏览器中href自动添加blob前缀，默认在浏览器打开而不是下载
      var URL = window.URL || window.webkitURL;
      // 根据解析后的blob对象创建URL 对象
      var herf = URL.createObjectURL(blob);
      // 下载链接
      a.href = herf;
      // 下载文件名,如果后端没有返回，可以自己写a.download = '文件.pdf'
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // 在内存中移除URL 对象
      window.URL.revokeObjectURL(herf);
    }
  }
};