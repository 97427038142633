"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _task = require("@/api/task");
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var obj = {
  uid: '',
  taskType: '',
  taskStatus: '',
  taskId: '',
  taskTitle: '',
  number: '',
  balance: '',
  rewardType: ''
};
var _default = exports.default = {
  name: "CreateUsertaskdetail",
  props: {
    'userTaskDetail': Object
  },
  data: function data() {
    return {
      dialogVisible: false,
      formValidate: Object.assign({}, obj),
      loading: false,
      rules: {
        uid: [{
          required: true,
          message: '请输入用户id',
          trigger: 'blur'
        }],
        taskType: [{
          required: true,
          message: '请输入任务类型',
          trigger: 'blur'
        }
        // { type: 'number', message: '出售金额须为数字值'}
        ],
        taskStatus: [{
          required: true,
          message: '请输入任务完成进度',
          trigger: 'blur'
        }
        // { type: 'number', message: '日产量须为数字值'}
        ],
        taskId: [{
          required: true,
          message: '请输入对应任务的ID',
          trigger: 'blur'
        }
        // { type: 'number', message: '有效周期须为数字值'}
        ],
        taskTitle: [{
          required: true,
          message: '请输任务标题',
          trigger: 'blur'
        }
        // { type: 'number', message: '活跃度须为数字值'}
        ],
        number: [{
          required: true,
          message: '请输入奖励金额',
          trigger: 'blur'
        }
        // { type: 'number', message: '完成任务数量须为数字值'}
        ],
        balance: [{
          required: true,
          message: '请输入奖励金额',
          trigger: 'blur'
        }
        // { type: 'number', message: '完成任务数量须为数字值'}
        ],
        rewardType: [{
          required: true,
          message: '请输入奖励类型',
          trigger: 'blur'
        }
        // { type: 'number', message: '完成任务数量须为数字值'}
        ]
      }
    };
  },
  methods: {
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    resetForm: function resetForm(formName) {
      this.dialogVisible = false;
      this[formName] = {};
    },
    submitForm: (0, _validate.Debounce)(function (formName) {
      var _this = this;
      //userTaskPackage与'userTaskPackage':Object名称一样
      this.$refs.userTaskDetail.validate(function (valid) {
        if (valid) {
          _this.loading = true;
          var data = {
            uid: _this.userTaskDetail.uid,
            taskType: _this.userTaskDetail.taskType,
            taskStatus: _this.userTaskDetail.taskStatus,
            taskId: _this.userTaskDetail.taskId,
            taskTitle: _this.userTaskDetail.taskTitle,
            number: _this.userTaskDetail.number,
            balance: _this.userTaskDetail.balance,
            rewardType: _this.userTaskDetail.rewardType
          };
          (0, _task.usertaskDetailUpdateApi)(_this.userTaskDetail.id, data).then(function (res) {
            _this.$message.success('编辑成功');
            _this.loading = false;
            _this.handleClose();
            _this.formValidate = Object.assign({}, obj);
            _this.$parent.getList();
          }).catch(function () {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    })
  }
};