"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _authInformation = require("../../../api/authInformation.js");
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "AuthenticationArtificialModal",
  data: function data() {
    return {
      dialogVisible: false,
      loading: false,
      //
      form: {
        // 认证状态 2.认证成功 3.认证失败
        "authStatus": "2",
        "id": 0,
        "remark": ""
      }
    };
  },
  methods: {
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    open: function open(item) {
      this.form.id = item.id;
      this.dialogVisible = true;
    },
    resetForm: function resetForm() {
      this.dialogVisible = false;
      this.form.authStatus = '2';
      this.form.id = 0;
      this.form.remark = '';
    },
    submitForm: (0, _validate.Debounce)(function () {
      var _this = this;
      if (this.form.authStatus == '3' && !this.form.remark) {
        this.$message('请输入备注信息');
        return;
      }
      // console.log(this.form)
      // return
      this.loading = true;
      (0, _authInformation.realnameAuth)(this.form).then(function (res) {
        _this.$message.success('审核完成');
        _this.loading = false;
        _this.handleClose();
        _this.$parent.getList();
      }).catch(function () {
        _this.loading = false;
      });
    })
  }
};