"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _statistic = require("@/api/statistic");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'ToDay',
  data: function data() {
    return {
      style: {
        height: '250px'
      },
      grid: {
        xl: 6,
        lg: 6,
        md: 12,
        sm: 24,
        xs: 24
      },
      cardLists: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    getList: function getList() {
      var _this = this;
      (0, _statistic.HeaderStatisticsApi)().then(function (res) {
        _this.cardLists = [{
          name: "昨日平台新增收益",
          icon: "iconline-order",
          tag: '昨日',
          count: res.ydaIncrIntegral,
          percent: res.incrRate,
          percentTitle: '前一天'
        }, {
          name: "昨日代理人收益分红",
          icon: "iconxiaoshoue",
          count: res.ydaProxyIncome,
          tag: '昨日',
          percent: res.proxyRate,
          percentTitle: '前一天'
        }, {
          name: "昨日服务商收益分红",
          icon: "iconline-coin",
          tag: '昨日',
          count: res.ydaPartnerIncome,
          percent: res.partnerRate,
          percentTitle: '前一天'
        }, {
          name: "平台当前总收益",
          icon: "iconline-coin",
          tag: '',
          count: res.platIntegral,
          percent: res.platRate,
          percentTitle: '昨日'
        }];
      });
    }
  }
};