var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox", staticStyle: { "padding-top": "0" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _vm.checkPermi(["admin:statistics:user:overview"])
            ? _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 18 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card card_show" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap justify-between mb30" },
                        [
                          _c("div", { staticClass: "header_title" }, [
                            _vm._v("用户概览"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "mr20",
                                  attrs: { type: "button", size: "small" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.selectChange(_vm.dateLimit)
                                    },
                                  },
                                  model: {
                                    value: _vm.dateLimit,
                                    callback: function ($$v) {
                                      _vm.dateLimit = $$v
                                    },
                                    expression: "dateLimit",
                                  },
                                },
                                _vm._l(
                                  _vm.fromList.fromTxt,
                                  function (item, i) {
                                    return _c(
                                      "el-radio-button",
                                      { key: i, attrs: { label: item.val } },
                                      [_vm._v(_vm._s(item.text))]
                                    )
                                  }
                                ),
                                1
                              ),
                              _vm._v(" "),
                              _c("el-date-picker", {
                                staticStyle: {
                                  width: "220px",
                                  height: "34px",
                                  "margin-right": "20px",
                                },
                                attrs: {
                                  size: "small",
                                  type: "daterange",
                                  placement: "bottom-end",
                                  "picker-options": _vm.pickerOptions,
                                  "value-format": "yyyy-MM-dd",
                                  format: "yyyy-MM-dd",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  align: "right",
                                },
                                on: { change: _vm.onchangeTime },
                                model: {
                                  value: _vm.timeVal,
                                  callback: function ($$v) {
                                    _vm.timeVal = $$v
                                  },
                                  expression: "timeVal",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "mr15",
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.onSeach },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 18, lg: 18 } },
                            [
                              _c("div", { staticClass: "user_chart" }, [
                                _c("div", { staticClass: "acea-row" }, [
                                  _c("div", { staticClass: "user-visitUser" }, [
                                    _c(
                                      "div",
                                      { staticClass: "data_num acea-row" },
                                      [
                                        _c("div", { staticClass: "inone" }, [
                                          _c(
                                            "div",
                                            { staticClass: "user_reg_tit" },
                                            [
                                              _vm._v(
                                                "\n                        注册用户数量:" +
                                                  _vm._s(
                                                    _vm.userView.registerNum
                                                  ) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "reg_time" },
                                            [
                                              _vm._v(
                                                "\n                        环比增长率：" +
                                                  _vm._s(
                                                    _vm.userView
                                                      .registerNumRatio
                                                  ) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "user-visitUser-ti column_center",
                                    },
                                    [
                                      _c("span", { staticClass: "num_data" }, [
                                        _vm._v(_vm._s(_vm.userView.pageviews)),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("访客")]),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "orderUser" }, [
                                  _c(
                                    "div",
                                    { staticClass: "user-orderUser acea-row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "data_num acea-row" },
                                        [
                                          _c("div", { staticClass: "inone" }, [
                                            _c(
                                              "div",
                                              { staticClass: "user_reg_tit" },
                                              [
                                                _vm._v(
                                                  "\n                        活跃用户数量:" +
                                                    _vm._s(
                                                      _vm.userView.activeUserNum
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "reg_time" },
                                              [
                                                _vm._v(
                                                  "\n                        环比增长率：" +
                                                    _vm._s(
                                                      _vm.userView
                                                        .activeUserNumRatio
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "user-orderUser-ti column_center",
                                    },
                                    [
                                      _c("span", { staticClass: "num_data" }, [
                                        _vm._v(
                                          _vm._s(_vm.userView.orderUserNum)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("下单")]),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "acea-row payOrderUser" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "user-payOrderUser acea-row",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "data_num acea-row" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "inone" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "user_reg_tit",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        充值用户数量:" +
                                                        _vm._s(
                                                          _vm.userView
                                                            .rechargeUserNum
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "reg_time" },
                                                  [
                                                    _vm._v(
                                                      "\n                        环比增长率：" +
                                                        _vm._s(
                                                          _vm.userView
                                                            .rechargeUserNumRatio
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "inone" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "user_reg_tit",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        客单价:" +
                                                        _vm._s(
                                                          _vm.userView
                                                            .customerPrice
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "user-payOrderUser-ti column_center",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "num_data" },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.userView.orderPayUserNum
                                                )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("成交用户")]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.checkPermi(["admin:statistics:user:channel"])
            ? _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 6 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card" },
                    [
                      _c("div", { staticClass: "header_title" }, [
                        _vm._v("用户渠道"),
                      ]),
                      _vm._v(" "),
                      _vm.optionData
                        ? _c("echarts-new", {
                            attrs: {
                              "option-data": _vm.optionData,
                              styles: _vm.style,
                              height: "100%",
                              width: "100%",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }