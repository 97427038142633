"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _creatComment = _interopRequireDefault(require("./creatComment.vue"));
var _store = require("@/api/store");
var _index = require("@/utils/index");
var _user = require("@/api/user");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'StoreComment',
  filters: {
    formatDate: function formatDate(time) {
      if (time !== 0) {
        var date = new Date(time * 1000);
        return (0, _index.formatDates)(date, 'yyyy-MM-dd hh:mm');
      }
    }
  },
  components: {
    creatComment: _creatComment.default
  },
  data: function data() {
    return {
      merCateList: [],
      props: {
        children: 'child',
        label: 'name',
        value: 'id',
        emitPath: false
      },
      fromList: this.$constants.fromList,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        page: 1,
        limit: 20,
        isReply: '',
        dateLimit: '',
        // uid: '',
        nickname: '',
        productSearch: '',
        isDel: false
      },
      timeVal: [],
      loading: false,
      uids: [],
      options: [],
      dialogVisible: false,
      timer: ''
    };
  },
  watch: {
    $route: function $route(to, from) {
      this.getList();
      this.getCategorySelect();
    }
  },
  mounted: function mounted() {
    // this.getLstFilterApi()
    this.getList();
    this.getCategorySelect();
  },
  methods: {
    remoteMethod: function remoteMethod(query) {
      var _this = this;
      if (query !== '') {
        this.loading = true;
        setTimeout(function () {
          _this.loading = false;
          (0, _user.userListApi)({
            keywords: query,
            page: 1,
            limit: 10
          }).then(function (res) {
            _this.options = res.list;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    seachList: function seachList() {
      this.dialogVisible = false;
      this.tableFrom.page = 1;
      this.getList();
    },
    // 回复
    reply: function reply(id) {
      var _this2 = this;
      this.$prompt('回复内容', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '请输入回复内容',
        inputType: 'textarea',
        inputPlaceholder: '请输入回复内容',
        inputValidator: function inputValidator(value) {
          if (!value) {
            return '输入不能为空';
          }
        }
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _store.replyCommentApi)({
          ids: id,
          merchantReplyContent: value
        }).then(function (res) {
          _this2.$message({
            type: 'success',
            message: '回复成功'
          });
          _this2.getList();
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this3 = this;
      (0, _store.categoryApi)({
        status: -1,
        type: 1
      }).then(function (res) {
        _this3.merCateList = res;
      }).catch(function (res) {
        _this3.$message.error(res.message);
      });
    },
    add: function add() {
      this.dialogVisible = true;
      this.timer = new Date().getTime();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this4 = this;
      this.$modalSure().then(function () {
        (0, _store.replyDeleteApi)(id).then(function () {
          _this4.$message.success('删除成功');
          _this4.tableData.data.splice(idx, 1);
        });
      });
    },
    // 列表
    getList: function getList() {
      var _this5 = this;
      this.listLoading = true;
      this.tableFrom.uid = this.uids.join(',');
      (0, _store.replyListApi)(this.tableFrom).then(function (res) {
        _this5.tableData.data = res.list;
        _this5.tableData.total = res.total;
        _this5.listLoading = false;
      }).catch(function () {
        _this5.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};